import NavProfileMenu from './NavProfileMenu.vue';
import NavProfileMenuItem from './NavProfileMenuItem.vue';

const navProfileMenu = {
  NavProfileMenu,
  NavProfileMenuItem,
};

// @ts-ignore
for (const elem in navProfileMenu) {
  if (navProfileMenu.hasOwnProperty(elem)) {
    const element = (navProfileMenu as any)[elem];
    // @ts-ignore
    element.install = (Vue) => {
      Vue.component((element as any).options.name, element);
    };
  }
}

export default navProfileMenu;
