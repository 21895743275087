









import Vue from 'vue';


export default Vue.extend({
  name: 'modes-list',
  props: {
    modes: {
      type: Array,
      required: true,
    },
  },
});
