














import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'n-Links',
})
export default class Links extends Vue {
  /**
   * Boolean to make the links in uppercase
   */
  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  public uppercase!: boolean;

  /**
   * Boolean to make the links in uppercase
   */
  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  public small!: boolean;

  /**
   * Boolean to make the links in uppercase
   */
  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  public underline!: boolean;

  /**
   * Boolean to make the links in uppercase
   */
  @Prop({
    type: Object,
    required: false,
  })
  public to: any;

  get isUppercase(): string {
    return this.uppercase ? 'uppercase' : '';
  }

  get isSmall(): string {
    return this.small ? 'small' : '';
  }
}
