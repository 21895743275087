import Vuex from 'vuex';
import * as auth from './auth';
import * as user from './user';
import * as games from './games';
import * as challenges from './challenges';
import * as gameAccount from './gameAccount';
import * as notifications from './notifications';
import * as socket from './socket';
export function getStore(): any {
    const store = new Vuex.Store({
        modules: {
          auth,
          user,
          socket,
          games,
          challenges,
          gameAccount,
          notifications,
        },
      });

    return store;
}
