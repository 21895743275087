

























import Vue from 'vue';
import Avatar from '../../ProfileElements/Avatar/Avatar.vue';
import ChipLevel from '../../ProfileElements/ChipLevel/ChipLevel.vue';
import { config } from '@/env/Configuration';
import { auth } from '@/auth/index';

export default Vue.extend({
  name: 'n-NavProfile',
  components: { Avatar, ChipLevel },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed : {
    isAdmin(): boolean {
      return auth.isAdmin();
    },
    activePayment(): boolean {
      return config('app.nbz.activePayment') || this.isAdmin;
    },
  },
  methods: {
    redirectAccount() {
      if (this.$route.name != 'accountSettings') {
        this.$router.push({ name: 'accountSettings' });
      }
    },
    redirectDeposit() {
      if (this.$route.name != 'deposit') {
        this.$router.push({ name: 'deposit' });
      }
    },
  },
});
