import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

import { localize } from 'vee-validate';
import fr from 'vee-validate/dist/locale/fr.json';

import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { Vue } from 'vue-property-decorator';

import { setInteractionMode } from 'vee-validate';

import './customRules';

setInteractionMode('eager');

localize('fr', fr);

Object.keys(rules).forEach((rule: any) => {
  extend(rule, {
    ...(rules as any)[rule], // copies rule configuration
    message: (fr.messages as any)[rule], // assign message
  });
});

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

