






























import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import InputLabel from '../../LabelElements/InputLabel/InputLabel.vue';

@Component({
  components: {
    InputLabel,
  },
  name: 'n-InputText',
})
export default class InputText extends Vue {
  public innerValue: string = '';

  /**
   * Value de l'input
   */
  @Prop({
    type: String,
    required: false,
  })
  public value!: string;

  /**
   * Label de l'input placé haut dessus
   */
  @Prop({
    type: String,
    required: false,
  })
  public label!: string;

  /**
   * Nom de l'input permettant de lié le label à l'input
   */
  @Prop({
    type: String,
    required: true,
    default: 'name',
  })
  public name!: string;

  /**
   * Placeholder de l'input
   */
  @Prop({
    type: String,
    required: false,
    default: 'placeholder',
  })
  public placeholder!: string;

  /**
   * Permet de définir si l'input est requis ou non
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public required!: boolean;

  /**
   * Permet de passer en mode readonly
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public readonly!: boolean;

  /**
   *  Permet d'ajouter un icon ou une img à la fin du champ
   */
  @Prop({
    type: String,
    required: false,
  })
  public afterIcon!: string;

  /**
   * Utiliser pour forcer une erreur par exemple sur un retour d'API
   */
  @Prop({
    type: String || null,
    required: false,
    default: null,
  })
  public readonly error!: string | null;

  @Prop({
    type: String || null,
    required: false,
    default: null,
  })
  public readonly success!: string | null;

  @Watch('value')
  public updateValue() {
    this.innerValue = this.value;
  }

  public mounted() {
    this.innerValue = this.value;
  }

  get isReadonly(): string {
    return this.readonly ? 'readonly' : '';
  }

  get hasAfterIcon(): string {
    return this.$slots['after-icon'] ? 'after-icon' : '';
  }

  public hasError(): string {
    return this.error ? 'error' : '';
  }

  @Emit('input')
  public input() {
    return this.innerValue ? this.innerValue : null;
  }

  @Emit('blur')
  public blur() {
    return this.innerValue ? this.innerValue : null;
  }

  @Emit('focus')
  public focus() {
    //
  }
}
