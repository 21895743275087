














































import Vue from 'vue';
import { api } from '@/io/api';

interface InviteFriend {
  email: string | null;
  locale: string;
}

export default Vue.extend({
  name: 'InviteFriendForm',
  props: {
    senderEmail: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      inviteFriend: {
        email: null,
        locale: this.$i18n.locale,
      } as InviteFriend,
      loading: false,
      success: null,
      sent: false,
    };
  },
  methods: {
    async sendForm() {
      this.loading = true;
      try {
        await api()
          .route('user/accessKey')
          .params({ email: this.inviteFriend.email, locale: this.inviteFriend.locale, senderEmail: this.senderEmail })
          .post();
        this.success = this.$t('accessKey.success.invite');
      } catch (error) {
        let err = error.message ? error.message : 'error.server';
        if (!error.fatal) {
          err = 'error.server';
        }
        (this.$refs.validator as any).setErrors([this.$t(err)]);
      }
      this.sent = true;
      this.loading = false;
    },

    resetField() {
      (this.$refs.observer as any).reset();
      if (this.sent == true) {
        this.inviteFriend.email = null;
        this.success = null;
        this.sent = false;
      }
    },
  },
});
