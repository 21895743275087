






import Vue from 'vue';

export default Vue.extend({
  name: 'loaderText',
  props: {
    color: {
      type: String,
      required: false,
      default: '#d8d8d8', // $primary
    },
    text: {
      type: String, // Send key trad
      required: false,
    },
  },
  computed: {
    style() {
      return ` color: ${this.color};`;
    },
    loadingText() {
      if (this.text) {
        return this.text;
      } else {
        return this.$t('loadingText');
      }
    },
  },
});
