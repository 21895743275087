import * as configuration from '@/config';
import { config } from '@/env/Configuration';
import '@/services/veeValidate/vee-validate.ts';
import * as socketRoutes from './socket/index';

import Vue from 'vue';
import { Application } from '@/app/Application';
import { createVue } from '@/app/vue';
import { auth } from '@/auth';
import { api } from '@/io/api';
// import './registerServiceWorker';
import { socket } from '@/io/socket';

const app = new Application();

app.use('configuration', () => {
  config.merge(configuration);
  if (config('app.environment') == 'local' && config('app.debug') == true) {
    console.log(config());
  }
  // document.title = config('app.name');
});
app.use('api', async () => {
  api().config(config('api'));
});

let vueApp: Vue;
app.use('vue2', function() {
  vueApp = createVue();
});
app.use('auth', function() {
  return auth.initialize();
});

app.use('vue2-mounted', function() {
  vueApp.$mount('#app');
});

app.use('socket', function() {
  socket.config(config('socket'));
  socket.addRoutes(socketRoutes);
  if (auth.check()) {
    return socket.boot();
  }
});
app.boot();

export { app };
