





























import Vue from 'vue';
import GameInfo from '../GameInfo/GameInfo.vue';


export default Vue.extend({
  name: 'n-GameThumb',
  components: {
    GameInfo,
  },
  props: {
    game: {
      type: Object,
      required: true,
    },
    xl: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    name(): string|null {
      return this.game.label ? this.game.label : null;
    },
    logo(): string|null {
      return this.game.icon_url ? this.game.icon_url : null;
    },
    baner(): string|null {
      return this.game.cover_url ? this.game.cover_url : null;
    },
    registeredPlayers(): number {
      return this.game.registered_players ? this.game.registered_players : 0;
    },
    activePlayers(): number|boolean {
      return this.game.active_players ? this.game.active_players : false;
    },
    averageBet(): number|null {
      return this.game.average_bet ? this.game.average_bet : null;
    },
    modes(): object|null {
      return this.game.modes ? this.game.modes : null;
    },
    pc(): boolean {
      return this.game.platforms ? this.game.platforms.find((p) => p.type == 'pc') : false;
    },
    xbox(): boolean {
      return this.game.platforms ? this.game.platforms.find((p) => p.type == 'xbox') : false;
    },
    ps4(): boolean {
      return this.game.platforms ? this.game.platforms.find((p) => p.type == 'ps') : false;
    },
    isAvailable(): boolean {
      return !!this.game.available;
    },
  },
});
