







import Vue from 'vue';
import styles from '@nbzify/scss/_exports.scss';

export default Vue.extend({
  name: 'n-ChipLevel',
  props: {
    level: {
      type: [Number, String],
      required: true,
    },
    big: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: Number,
      required: false,
    },
    borderColor: {
      type: String,
      required: false,
      default: 'Primary',
    },
  },
  computed: {
    style() {
      if (this.width) {
        return `
          width: ${this.width}px;
          height: ${this.width}px;
        `;
      }
      return null;
    },
    border() {
      return {
        color: styles[`color${this.borderColor}`],
      };
    },
  },
});
