




































import Vue from 'vue';


export default Vue.extend({
  name: 'match-infos',
  props: {
    game: {
      type: Object,
      required: true,
    },
    mode: {
      type: Object,
      required: true,
    },
    platform: {
      type: [Object, null],
      required: false,
    },
    isMatchMaking: {
      type: Boolean,
      required: false,
      default: false,
    },
    opponentPlatform: {
      type: [Object, null],
      required: false,
    },
  },
  data() {
    return {
      newMode: false,
      newModeTimeout: null,
    };
  },
  beforeDestroy() {
    if (this.newModeTimeout) {
      clearTimeout(this.newModeTimeout);
    }
  },
  created() {
    if (this.isMatchMaking) {
      this.newModeTimeout = setTimeout(() => {
        this.hightLightMode();
      }, 500);
    }
  },
  watch: {
    mode(newValue, oldValue) {
      if (this.isMatchMaking) {
        if (oldValue) {
          if (newValue.id != oldValue.id) {
            this.hightLightMode();
          }
        } else {
          this.hightLightMode();
        }
      }
    },
  },
  computed: {
    opponentPlatformLabel(): string {
      if (this.opponentPlatform && this.opponentPlatform.label) {
        return this.opponentPlatform.label;
      }
      return '...';
    },
  },
  methods: {
    hightLightMode() {
      this.newMode = true;
      if (this.newModeTimeout) {
        clearTimeout(this.newModeTimeout);
      }
      this.newModeTimeout = setTimeout(() => {
        this.newMode = false;
        this.newModeTimeout = null;
      }, 1500);
    },
  },
});
