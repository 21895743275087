



















import Vue from 'vue';

import IconCross from '@/components/Icons/Cross.vue';

export default Vue.extend({
  name: 'modal',
  components: {
    IconCross,
  },
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    buttons: {
      type: Array,
    },
  },
});
