






































import Vue from 'vue';
import { mapMutations } from 'vuex';
import Loader from '@/components/General/Loader.vue';
import { auth } from '@/auth/index';

export default Vue.extend({
  name: 'user-challenge',
  components: { Loader },
  props: {
    challenge: {
      required: true,
      type: Object,
    },
    game: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      deleting: false,
    };
  },
  computed: {
    opponent(): any|null {
      const opponent = this.challenge.participants.find((p) => p.user.id != auth.id());
      return opponent ? opponent.user : null;
    },
    opponentParticipant(): any|null {
      const participant = this.challenge.participants.find((p) => p.user.id != auth.id());
      return participant;
    },
    mode(): any {
      if (!this.challenge || !this.challenge.mode_id) { return; }
      return this.game.modes.find((m) => m.id == this.challenge.mode_id);
    },
  },
  methods: {
    ...mapMutations('auth', ['updateCredit']),
    async deleteChallenge(): Promise<void> {
      this.deleting = true;
      (this as any).updateCredit({ amount: this.challenge.bet });
      try {
        await this.$store.dispatch('user/deleteChallenge', { gameId: this.challenge.game_id, challengeId: this.challenge.id });
        this.deleting = false;
      } catch (error) {
        this.deleting = false;
        let err = error.message ? error.message : 'error.server';
        if (!error.fatal) {
          err = 'error.server';
        } else if (error.fatal && err == 'challenge.error.delete.alreadyAccepted') {
          // challenge has been accepted by a player
          this.refreshUserChallenges();
        }
        Vue.$toast.open({
          message: (this.$t(err) as string),
          type: 'error',
          duration: 6000,
        });
      }
    },
    refreshUserChallenges() {
      try {
        this.$store.dispatch('user/getChallengesCreated', { gameId: this.game.id });
      } catch (e) {
        console.log(e);
      }
    },
    redirectMatch() {
      if (this.deleting) {
        return;
      }
      this.$router.push({ name: 'match', params: { challengeId: this.challenge.uuid, currentChallenge: this.challenge }});
    },
  },

});
