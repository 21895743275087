
















import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { isString, isObject, isBoolean, has, get, translate, px } from './utils';
const DEFAULT_COLOR_CHECKED = '#F8460A';
const DEFAULT_COLOR_UNCHECKED = '#bfcbd9';
const DEFAULT_LABEL_CHECKED = 'on';
const DEFAULT_LABEL_UNCHECKED = 'off';
const DEFAULT_SWITCH_COLOR = '#fff';

@Component({
  name: 'n-ToggleButton',
})
export default class ToggleButton extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  public value!: boolean;

  @Prop({
    type: String,
  })
  public name!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  public disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  public cssColors!: boolean;

  @Prop({
    type: Number,
    default: 300,
  })
  public speed!: number;

  @Prop({
    type: Number,
    default: 22,
  })
  public height!: number;

  @Prop({
    type: Number,
    default: 50,
  })
  public width!: number;

  @Prop({
    type: Number,
    default: 3,
  })
  public margin!: number;

  @Prop({
    type: Number,
  })
  public fontSize!: number;
  @Prop({
    type: [String, Object],
    validator(value) {
      return isString(value) || has(value, 'checked') || has(value, 'unchecked') || has(value, 'disabled');
    },
  })
  public color!: [string, object];

  @Prop({
    type: [Boolean, Object],
    default: false,
    validator(value) {
      return typeof value === 'object' ? value.checked || value.unchecked : typeof value === 'boolean';
    },
  })
  public labels!: [boolean, object];

  @Prop({
    type: [String, Object],
    validator(value) {
      return isString(value) || has(value, 'checked') || has(value, 'unchecked');
    },
  })
  public switchColor!: [string, object];

  @Prop({
    type: Boolean,
    default: false,
  })
  public sync!: boolean;

  @Prop({
    type: String,
  })
  public tag!: string;

  public toggled = !!this.value;

  get className() {
    const { toggled, disabled } = this;
    return [
      'vue-js-switch',
      {
        toggled,
        disabled,
      },
    ];
  }
  get coreStyle() {
    return {
      width: px(this.width),
      height: px(this.height),
      backgroundColor: this.cssColors ? null : this.disabled ? this.colorDisabled : this.colorCurrent,
      borderRadius: px(Math.round(this.height / 2)),
    };
  }
  get buttonRadius() {
    return this.height - this.margin * 2;
  }

  get distance() {
    return px(this.width - this.height + this.margin);
  }
  get buttonStyle() {
    const transition = `transform ${this.speed}ms`;
    const margin = px(this.margin);
    const transform = this.toggled ? translate(this.distance, margin) : translate(margin, margin);
    const background = this.switchColor ? this.switchColorCurrent : null;
    return {
      width: px(this.buttonRadius),
      height: px(this.buttonRadius),
      transition,
      transform,
      background,
    };
  }
  get labelStyle() {
    return {
      lineHeight: px(this.height),
      fontSize: this.fontSize ? px(this.fontSize) : null,
    };
  }
  get colorChecked() {
    const { color } = this;
    if (!isObject(color)) {
      return color || DEFAULT_COLOR_CHECKED;
    }
    return get(color, 'checked', DEFAULT_COLOR_CHECKED);
  }
  get colorUnchecked() {
    return get(this.color, 'unchecked', DEFAULT_COLOR_UNCHECKED);
  }
  get colorDisabled() {
    return get(this.color, 'disabled', this.colorCurrent);
  }
  get colorCurrent() {
    return this.toggled ? this.colorChecked : this.colorUnchecked;
  }
  get labelChecked() {
    return get(this.labels, 'checked', DEFAULT_LABEL_CHECKED);
  }
  get labelUnchecked() {
    return get(this.labels, 'unchecked', DEFAULT_LABEL_UNCHECKED);
  }
  get switchColorChecked() {
    return get(this.switchColor, 'checked', DEFAULT_SWITCH_COLOR);
  }
  get switchColorUnchecked() {
    return get(this.switchColor, 'unchecked', DEFAULT_SWITCH_COLOR);
  }
  get switchColorCurrent() {
    const { switchColor } = this;
    if (!isObject(this.switchColor)) {
      return this.switchColor || DEFAULT_SWITCH_COLOR;
    }
    return this.toggled ? this.switchColorChecked : this.switchColorUnchecked;
  }
  @Watch('value')
  public updateValue(value) {
    if (this.sync) {
      this.toggled = !!value;
    }
  }
  public keyToggle(event) {
    // the key event happens whether the control is disabled or not
    // nothing should be done if disabled is true
    if (this.disabled) {
      return;
    }
    this.toggle(event);
  }

  public toggle(event) {
    const toggled = !this.toggled;
    if (!this.sync) {
      this.toggled = toggled;
    }
    this.$emit('input', toggled);
    this.$emit('change', {
      value: toggled,
      tag: this.tag,
      srcEvent: event,
    });
  }
}
