export default {
  router: {
    landing: {
      title: 'Noobizes | Esports Platform',
      desc: ``,
    },
    register: {
      title: `Signup on Noobizes`,
      desc: ``,
    },
    login: {
      title: `Login on Noobizes`,
      desc: ``,
    },
    forgotpassword: {
      title: `Password reset request - Noobizes`,
      desc: ``,
    },
    resetpassword: {
      title: `Reset password - Noobizes`,
      desc: ``,
    },
    cgu: {
      title: `Terms of service - Noobizes`,
      desc: ``,
    },
    cgv: {
      title: `Terms of sale - Noobizes`,
      desc: ``,
    },
    privacy: {
      title: `Privacy policy - Noobizes`,
      desc: ``,
    },
    home: {
      title: `Home - Noobizes`,
      desc: ``,
    },
    games: {
      title: `Games available - Noobizes`,
      desc: ``,
    },
    ranking: {
      title: `Ranking - Noobizes`,
      desc: ``,
    },
    store: {
      title: `Shop - Noobizes`,
      desc: ``,
    },
    challenges: {
      title: `Challenges - Noobizes`,
      desc: ``,
    },
    matches: {
      title: `My matches - Noobizes`,
      desc: ``,
    },
    match: {
      title: `Match - Noobizes`,
      desc: ``,
    },
    matchmaking: {
      title: `Matchmaking - Noobizes`,
      desc: ``,
    },
    pools: {
      title: `Matchmaking pools - Noobizes`,
      desc: ``,
    },
    settings: {
      title: `Settings - Noobizes`,
      desc: ``,
    },
    updatepassword: {
      title: `Reset password - Noobizes`,
      desc: ``,
    },
    gamesettings: {
      title: `Link your favorite games - Noobizes`,
      desc: ``,
    },
    deposit: {
      title: `Deposit - Noobizes`,
      desc: ``,
    },
  },
  error: {
    server: 'An error has occurred ',
    lowCredit: 'Your credit is insufficient',
    banned: 'Your account has been banned. Contact support',
  },
  accessKey: {
    error: {
      account_already_exists: 'An account is already associated with this email',
      too_many_user: 'We are sorry but too many players are currently registered. Please wait for the next stage of the alpha.',
    },
    success: {
      user: 'An email has been sent to you with your key!',
      invite: 'An invitation email has been sent!',
    },
  },
  login: {
    error: {
      wrong_username_password: 'The login/password is not valid ',
      banned: 'Your account has been banned. Contact support',
    },
  },
  signIn: {
    title: 'Connect to Noobizes',
    username: {
      label: 'Identifiant / email',
      placeholder: 'Enter your login...',
    },
    password: {
      label: 'Password',
      placeholder: 'Enter your password...',
    },
    validationButton: 'connexion',
    forgotPassword: 'Forgot your password?',
    catchPhrase: 'Give the best <br> For the Best',
  },
  register: {
    title: 'Noobizes account creation',
    form: {
      title: 'Account info',
      username: {
        label: 'pseudo',
        placeholder: 'Choose your pseudo...',
      },
      email: {
        label: 'email',
        placeholder: 'Choose your email...',
      },
      password: {
        label: 'Password',
        placeholder: 'Enter your password...',
      },
      confirmPassword: {
        label: 'Password confirmation',
        placeholder: 'Enter your password...',
      },
      alphaKey: {
        label: 'Acces key aplha',
        placeholder: 'Enter your Key...',
      },
      cgu: {
        terms1: 'I certify that I am over 18 years old,',
        terms2: 'I accept the Termes Of uses of Noobizes.',
      },
      validationButton: 'Registration',
    },
    success: {
      title: 'Congratulations !',
      text: 'Your account has been created.',
      connectButton: 'Connexion',
      account_created: 'Your account has been created!',
    },
    error: {
      email_already_exists: 'An account with this email already exists',
      username_already_exists: 'An account with this pseudo already exists',
      wrong_access_key_or_email: 'The alpha key/email is not valid ',
      access_key_already_used: 'Your account is already created!',
      cgu_is_required: 'The login/password is not valid',
    },
    catchPhrase: {
      top: 'Challenge yourself',
      bottom: 'Win credits',
    },
  },
  updatePassword: {
    title: 'Reset password',
    oldPassword: {
      label: 'Current password',
      placeholder: 'Enter your current password...',
    },
    newPassword: {
      label: 'New password',
      placeholder: 'Enter new password...',
    },
    confirmPassword: {
      label: 'Confirm password',
      placeholder: 'Confirm new password',
    },
    validationButton: 'Confirm',
    error: {
      wrong_old_password: 'Current password is incorrect',
    },
  },
  resetPassword: {
    title: 'Forgot your password',
    subTitle: 'account info',
    email: {
      label: 'Email',
      placeholder: 'Enter your email...',
    },
    validationButton: 'Send',
    catchPhrase: {
      top: 'Challenge yourself',
      bottom: 'Win credits',
    },
    error: {
      expired: 'Your request has expired',
    },
    success: {
      send: 'A reset link has been sent to <br><span>{email}</span>',
      reset: 'Your new password has been successfully saved!',
    },
    confirmation: {
      title: 'Forgotten password',
      subTitle: 'Reset your password',
      password: {
        label: 'Password',
        placeholder: 'Enter password...',
      },
      confirmPassword: {
        label: 'Confirm password',
        placeholder: 'Enter password...',
      },
      validationButton: 'Confirm',
      backButton: 'Back to home page ',
      catchPhrase: {
        top: 'Challenge yourself',
        bottom: 'Win credits',
      },
    },
  },
  inviteFriend: {
    title: 'Invite your friends, or enemies  😈',
    emailPlaceholder: 'Enter email...',
  },
  home: {
    title: {
      firstLine: 'Become the best one and collect the money!',
      secondLine: 'A bet, a challenge, a victory,',
    },
    button: {
      soon: 'Coming soon',
      play: 'Play',
    },
    about: {
      title: 'Welcome on Noobizes,',
      text:
        'The Esport platform designed by players for players.<br> A simple principle: challenge players on your favorite games to win prizes.<br> Now that you are registered, all you have to do is invite your friends to compete on the games of your choice.<br><span class="lastWords">Have a good game,</span><span class="signature">Noobzteam.</span>',
    },
  },
  games: {
    button: 'Play',
  },
  mode: '0 mode | 1 mode | {count} modes',
  connector: {
    label: {
      platform: 'Plateform',
      username: 'Pseudo',
    },
    button: {
      associate: 'Link',
      dissociate: 'Unlink',
      newAccount: 'Link a new account',
    },
    error: {
      link: 'Your account could not be associated.<br>Contact us <b>contact@noobizes.com</b>',
      notFound: 'This player does not exist. Check the exact spelling of the pseudo and the associated platform.',
      challengesAccepted: 'You have games in progress.<br> Please finish your games before disconnecting your account.',
      challengesCreated: 'Please delete the challenges you have created <br>before disconnecting your account.',
      matchMakingCreated: 'Please cancel your current matchmaking<br>before disconnecting your account.',
    },
    loader: {
      associate: 'Link in process...',
      info: 'Please be patient, this operation may take several minutes.',
    },
    modal: {
      title: 'you have games in progress.',
      text:
        'By continuing the disassociation, all ongoing games will be automatically closed..<br> If you have not played these games, your bet will be lost.<br><br>Do you want to continue the dissociation of your account?',
      button: {
        confirm: 'Continue the dissociation',
        cancel: 'View current games.',
      },
    },
  },
  challenge: {
    error: {
      selectMode: 'Select a game mode',
      selectBet: 'Select a bet',
      delete: {
        alreadyAccepted: 'A Gamer has just accepted your challenge ',
      },
      tooManyChallenges: 'The maximum number of challenges has been reached.',
      notAvailable: 'The challenge is no longer available ',
    },
    user: {
      countUserChallenges: '| 1 challenge pending | {count} pending challenges',
      opponent: {
        accept: 'accepted your challenge.',
        start: 'has started its game.',
        end: 'finished his game.',
      },
      waiting: 'Pending...',
      play: 'Play now',
      delete: 'Delete',
    },
    challenges: {
      refresh: 'Search...',
      countChallenges: 'No challenge found | 1 challenge found | {count} Challenges found',
      waiting: 'Waiting for new challenges...',
    },
    accept: 'Challenge',
    form: {
      title: 'Create a challenge',
      button: 'Create',
      lowCredit: 'Insufficient credits ',
      mode: 'Game mode',
    },
    searchBar: {
      mode: 'Game mode',
      bet: 'Bet',
      username: 'Pseudo...',
      searchButton: 'Search',
    },
    table: {
      pseudo: `Pseudo`,
      ration: `Ratio`,
      mode: `Mode`,
      bet: `Bet`,
    },
  },
  matchMaking: {
    running: 'MatchMaking in progress',
    stopped: 'No opponent was found ',
    restartOrChallenge: 'Restart your matchmaking<br> or create a new challenge',
    searchOpponent: 'We are looking <br>for an opponent  of your level...',
    cancel: 'Cancel',
    createChallenge: 'Create a challenge',
    restart: 'Restart',
  },
  matchMakingTag: {
    title: 'MatchMaking :',
    search: 'Search in progress...',
    hasOpponent: 'Opponent found',
  },
  match: {
    actions: {
      confirmStart: {
        text: 'Confirmation of the launch of your game',
        button: 'I launch my game',
      },
      confirmEnd: {
        text: 'Confirm the end of your game',
        button: 'Confirm',
      },
      error: {
        selectPlatform: 'Select a platform',
      },
    },
    player: {
      state: {
        notStarted: 'Waiting for the launch<br>of the game...',
        ended: 'Game ended',
        playing: 'Game in progress...',
        timeout: 'The game has expired',
        waitingForOpponent: '<br>Waiting for <br> an opponent...',
      },
    },
    dotations: {
      title: 'Bet',
    },
    matchMaking: {
      found: 'Opponent found',
      ended: 'MatchMaking completed',
    },
    modesAvailable: 'Available modes',
    infos: {
      game: 'Game',
      mode: 'Mode',
      platform: 'Plateform',
      goal: {
        title: 'Goal',
        text: 'Ranking',
      },
      averageTime: 'average time',
    },
  },
  form: {
    select: {
      platform: 'Plateform',
    },
  },
  pools: {
    title: 'Join a pool',
    text: 'Choose your maximum bet and find an opponent at your level !<br>The choice of the mode is random.',
    form: {
      title: 'Choose your plateform',
      select: 'Plateform',
      confirm: 'Confirm',
    },
    pool: {
      infos: 'Bet between {min} - {max} Nbz',
    },
  },
  matches: {
    title: 'My challenges',
    countMatchesActive: 'No game in progress | 1 game in progress | {count} games in progress',
    countMatchesEnded: 'No completed games | 1 game completed | {count} games completed',
    matchesEnded: 'games completed',
    activedMatches: {
      opponent: 'Opponent',
      mode: 'Mode',
      bet: 'Bet',
      state: 'Statut',
      time: 'Launch',
      waitingOpponent: 'Waiting for an opponent...',
    },
    endedMatches: {
      opponent: 'Opponent',
      mode: 'Mode',
      bet: 'Win',
      state: 'Statut',
      time: 'Launch',
    },
    buttons: {
      createChallenge: 'Create a new challenge',
      matchMaking: 'MatchMaking',
    },
    state: {
      win: 'You have won',
      lose: 'You lost',
      timeout: 'The game has expired',
      draw: 'Equality',
      started: 'Your game is in progress',
      notStarted: "You haven't started your game yet ",
      opponentStarted: '{opponentName} is in progress',
      opponentNotStarted: '{opponentName} has not yet started its game ',
      noPlayer: 'Waiting for a gamer',
      processing: 'Waiting for the results',
    },
  },
  matchResume: {
    title: 'Games results',
    processing: 'Waiting for the results...',
    winner: 'Winner',
    loser: 'Loser',
    draw: 'Equality',
    labels: {
      versus: 'VS',
      status: 'Statut',
      gain: 'Win',
      rank: 'Rank',
      rankRatio: 'Rang ratio',
      points: 'Points',
      kills: 'Kills',
      dbnos: 'DBNOs',
    },
    infos: {
      title: 'Information :',
      text:
        'The player rankings only take into account real players, and exclude bots,<br> so your final ranking may differ from the ranking posted on the official sites.',
    },
  },
  sideMenu: {
    gameList: 'Game list',
    leaderboard: 'Leaderboard',
    store: 'shop',
    userGamesTitle: 'My Challenges',
    game: {
      challenges: 'Challenges',
      matchMaking: 'Matchmaking',
      matches: 'My games',
    },
    soon: 'Coming soon',
    buttonCoins: 'Add credits',
    settings: 'Settings',
    logout: 'Log out',
  },
  settingsHeader: {
    settings: 'Settings',
    buttonNbz: 'Add credits',
  },
  settings: {
    menu: {
      account: 'Account',
      games: 'Games',
    },
    games: {
      topText:
        'In order to play against other Noobizes players on the game of your choice, it is necessary to associate your respective accounts on the games and platforms concerned.<br>Please respect the exact spelling of your nickname.',
    },
    account: {
      labels: {
        pseudo: 'Pseudo',
        email: 'Email',
        profilpic: 'Profile picture',
        password: 'Password',
      },
      upload: 'Upload a picture',
      info: '(max 10M, JPG, PNG, GIF, SVG)',
      resetPassword: 'Reset my password',
      error: {
        entityTooLarge: 'The picture should not exceed 10M.',
      },
    },
  },
  loadingText: 'Loading...',
  rules: {
    menu: {
      howItWorks: 'How it works',
      nbzRules: 'Rules',
    },
    separator: 'or',
    nbz: {
      1: {
        title: `1 / Definition of a winner`,
        text: `The winner of the challenge is the player with the most points.<br />The points are calculated in relation to the player's final ranking, as a ratio of the total number of players in the game.<br />Additional points are added for each KILL or DBNO made.`,
      },
      2: {
        title: `2 / In case of a tie`,
        text: `If the players get the same number of points, the bets are divided equally between the players.`,
      },
      3: {
        title: `3 / Cheating information`,
        text: `Any form of assistance, which increases the player's actual level, is considered cheating.`,
      },
      4: {
        title: `4 / Respect of the players`,
        text: `As a reminder, it is illegal to make discriminatory comments based on ethnicity, nationality, race, gender, religion, sexual preference or belief. The player is liable to prosecution and account closure.`,
      },
    },
    challenge: {
      text: {
        1: {
          title: `1 / Create your own challenge`,
          text: `In the 'Create a Challenge' section, choose the game mode and stake you wish to play, then click 'Create'.<br>Your challenge is now visible to other players.<br>You can play your challenge now by clicking on the 'Play Now' button.<br>Once a challenge has been started or accepted by a player, you cannot remove it.<br>Once an opponent has joined your challenge, you have 24 hours to play your game,in which case your game will be considered as forfeited. You will be considered to have withdrawn.<br>`,
        },
        2: {
          title: `2 / Start your game independently <span class="accent"> independently </span> of your opponent`,
          text: `Start your game independently of your opponent. You do <b>NOT</b> playon the same server as your opponent. Each player plays his game independently from hisopponent, respecting the selected game mode.<br />As soon as you are ready, start your game on the chosen platform, respecting the mode that has been chosen for the challenge or match making.<br />Once your game is launched, click on the button "Launch my game" so that we canlater retrieve your game.`,
        },
        3: {
          title: `3 / Announce your endgame on Noobizes`,
          text: `Once you have finished your game, click on the "Finish" button.<br />From this moment on, you will be able to retrieve your results and compare them with those of your opponent.`,
        },
        4: {
          1: {
            title: `4 / You have won`,
            text: `Congratulations!<br />You will immediately collect the entire pot that was put into play.`,
          },
          2: {
            title: `4 / You lost`,
            text: `You lost the battle but not the war! Try your luck again! <br />You can ask for a rematch any time by proposing a new challenge to your opponent.`,
          },
        },
        last: `<i>It may take several minutes to retrieve the data. You will receive a notification as soon as your results are published.</i><br /><i><b> Please note : </b> Player rankings only take into account real players and exclude robots.<br /> Your final ranking may differ from the ranking displayed on the official sites.</i>`,
      },
    },
    matchmaking: {
      1: {
        title: `1 / Finding an opponent`,
        text: `Choose your maximum bet and find an opponent that fits your skills!.<br>The choice of mode is random.<br>        The search for an opponent is done according to your level on the platform Noobizes<br>        Once an opponent is found, you can start your game.<br>        If no opponent has been found within 5 minutes, you can restart a match making or create a challenge.`,
      },
      2: {
        title: `2 / Start your game <span class="accent"> independently </span> of your opponent`,
        text: `You do <b>NOT</b> play on the same server as your opponent. Each player plays his game independently from his opponent, respecting the selected game mode.<br>As soon as you are ready, start your game on the chosen platform, respecting the mode that has been chosen for the challenge or match making.<br>Once your game is launched, click on the button "Launch my game" so that we can later retrieve your game.`,
      },
      3: {
        title: `3 / Announce your endgame on Noobizes `,
        text: `Once you have finished your game, click on the "Finish" button.<br>From this moment on, you will be able to retrieve your results and compare them with those of your opponent.`,
      },
      4: {
        1: {
          title: `4 / You have won `,
          text: `Congratulations!<br>You will immediately collect the entire pot that was put into play.`,
        },
        2: {
          title: `4 / You lost`,
          text: `You lost the battle but not the war! Try your luck again!<br>You can ask for a rematch any time by proposing a new challenge to your opponent.`,
        },
      },
      last: `<i> It may take several minutes to retrieve the data. You will receive a notification as soon as your results are published.</i><br><i><b> Please note: </b> Player rankings only take into account real players and exclude robots. Your final ranking may differ from the ranking displayed on the official sites.</i>`,
    },
    matchs: {
      1: {
        title: `1 / Start your game <span class="accent"> independently </span> of your opponent`,
        text: `You do <b>NOT</b> play on the same server as your opponent.<br> Each player plays his game independently from his opponent, respecting the selected game mode.<br>As soon as you are ready, start your game on the chosen platform, respecting the mode that has been chosen for the challenge or match making.<br>Once your game is launched, click on the button "Launch my game" so that we can later retrieve your game.`,
      },
      2: {
        title: `2 / Announce your endgame on Noobizes`,
        text: `Once you have finished your game, click on the "Finish" button.<br>From this moment on, you will be able to retrieve your results and compare them with those of your opponent.`,
      },
      3: {
        1: {
          title: `3 / You have won `,
          text: `Congratulations !<br>You will immediately collect the entire pot that was put into play.`,
        },
        2: {
          title: `3 / You lost`,
          text: `You lost the battle but not the war! Try your luck again!<br>You can ask for a rematch any time by proposing a new challenge to your opponent.`,
        },
      },
      last: `<i> It may take several minutes to retrieve the data. You will receive a notification as soon as your results are published.</i><br><i><b> Please note : </b> Player rankings only take into account real players and exclude robots. Your final ranking may differ from the ranking displayed on the official sites.</i>`,
    },
  },
  notification: {
    link: {
      success: {
        title: 'Service link ✅',
        message: 'Congratulations, your {platformName} account has been successfully linked! You can now access games and events related to this service.',
      },
      error: {
        title: 'Service link ❌',
        message:
          'Damn... An error occurred while linking your {platformName} account. Please check the data linked to your service, and the way it was entered.',
      },
    },
    menu: {
      title: 'Notifications',
      empty: 'No new notifications',
      markAsRead: "I've seen it all - thank you.",
    },
    challenge: {
      accepted: {
        title: 'Your challenge has been accepted',
        message: '{opponentName} accepted your {modeLabel} challenge at  {bet} Nbz',
      },
      win: {
        title: 'You have won 🙌',
        // message: "Bravo",
      },
      lose: {
        title: 'You lost',
        timeout: {
          title: 'You have lost',
          message: 'You did not play your game',
        },
      },
    },
    matchMaking: {
      accepted: {
        title: 'Match making : Opponent found',
        message: 'Challenges against {opponentName}, {mode} to {bet} Nbz',
      },
    },
    levelup: {
      title: 'Higher level reached.',
      message: "You have reached <b class='level'>{level}</b>",
    },
    matchLink: '> See the game',
    payment: {
      success: {
        title: 'Thank you for your purchase !',
        message: 'Your account has been credited with {amountNbz} Nbz, now, let’s play!',
      },
      error: {
        title: 'An error occurred during your purchase',
        message: 'The transaction could not be completed, you will not be charged.',
      },
      refund: {
        title: 'Your purchase has been refunded ',
        message: 'The refund of {price}€ will appear in your account within 5 to 10 days.',
      },
    },
    transaction: {
      refund: {
        title: 'Your account has been credited.',
        message: 'You have just received {amount} Nbz.',
      },
    },
    apiForMatchNotFound: {
      title: 'Error when retrieving your games ❌',
      message: 'Please contact support at <b>contact@noobizes.com</b>',
    },
  },
  leaderboard: {
    title: 'Leaderboard',
    table: {
      player: 'Gamers',
      matches: 'Games',
      point: 'score',
    },
  },
  footer: {
    tradeMark: {
      full: '© 2021 Noobizes. All rights reserved.',
      short: '© 2021 Noobizes.',
    },
    cgu: 'Terms and conditions of use',
    cgv: 'Terms and Conditions of sale',
    privacy: 'Privacy Policy',
    contactTitle: 'Contacts',
    contactEmail: 'contact@noobizes.com',
  },
  landingPage: {
    form: {
      title: 'Request and receive your <span class="orange"> Alpha Key</span>.',
      text:
        'Sign up to participate exclusively to the first version of <strong>Noobizes</strong>.<br /> We want to collect your impressions and opinions to better meet your needs.',
      placeholder: 'Enter email...',
    },
    steps: {
      1: 'Create and set up your account',
      2: 'Search/post for <strong>challenges</strong>',
      3: 'Find gamers to challenge',
      4: {
        1: 'Win your games and ',
        2: 'promised',
      },
    },
    button: {
      connect: 'connect',
      redirectApp: 'Access to the website',
    },
    about: {
      title: 'Your <strong>Esport plateform</strong>',
      text:
        '<p><strong>Noobizes</strong>, the <strong>Esport plateform</strong> designed by <strong>gamers</strong> for gamers. Sign up to compete against other gamers, on the <strong>games</strong> of your choice, in an endowed or non-endowed game. Request your alpha key and come challenge your friends in order to win <strong>prizes</strong>.</p>',
      bottomTitle: 'Guaranteed <strong>winnings</strong> in case of victory',
    },
    aboutAlpha: {
      title: 'About Alpha',
      text: `<p> Our objective is to be able to launch our platform while testing our solution by collecting your remarks.</p><p> For this new stage, Noobizes will be open to 1000 people in free access. We will credit 1000 NBZ credits on each account so that you can create and accept the challenges. The credits awarded to the creation of your account will have no monetary value. They will be free tokens. For now, only the PUBG game is available. We're working on adding new ones.</p><p> If you have any questions about the game, suggestions for improvements or bug reports, a <a href="https://discord.gg/2HrRHaZFdN">discord</a> has been created to facilitate your feedback and simplify their treatment. You can also send us your feedback at <a href="mailto:contact@noobizes.com">contact@noobizes.com</a>.</p>`,
      bottomTitle: 'See you soon on Noobizes !',
    },
  },
  deposit: {
    title: 'Add credits',
    subtitle: 'Choose an offer',
    text:
      'Choose the amount that suits you and then complete the payment information.<br>Once the payment is validated, the NBZ credits will be instantly credited to your account.',
    orderButton: 'Order',
    modal: {
      success: {
        title: 'Thank you for your purchase ',
        text: 'Your account has been successfully credited.<br>Let’s play on Noobizes now !',
        button: {
          createNewChallenge: 'Create a new challenge',
          matchMaking: 'Matchmaking',
        },
      },
      error: {
        title: 'Your account could not be credited ',
        text: 'A problem occurred during the payment, you will not be debited..',
      },
    },
  },
};
