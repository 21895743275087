








import Vue from 'vue';
import { auth } from '@/auth/index';

export default Vue.extend({
  name: 'Header',
  computed: {
    isConnected() {
      return !!auth.check();
    },
  },
  methods: {
    redirectLogin() {
      this.$router.push({ name: 'login' }).catch(() => {
        // prevent Sentry to log an error when router redirect to 'home' if user is connected
      });
    },
    redirectApp() {
      this.$router.push({ name: 'home' }).catch(() => {
        // prevent Sentry to log an error on redirect
      });
    },
  },
});
