

















import Vue from 'vue';


export default Vue.extend({
  name: 'pagination',
  props: {
    /**
     * Page number
     */
    value: {
      type: Number,
      required: true,
    },
    /**
     * Total number of items
     */
    itemsCount: {
      type: Number,
      required: true,
    },
    /**
     * Max number of items per page
     */
    limit: {
      type: Number,
      required: true,
    },
    /**
     * Number of numbers to display
     * 1 2 3...15 (= 4 numbers)
     */
    visiblePagesCount: {
      type: Number,
      required: false,
      default: 5,
    },
  },
  data() {
    return {
      page: null,
    };
  },
  created() {
    this.page = this.value;
  },
  watch: {
    value() {
      this.page = this.value;
    },
  },
  computed: {
    maxPages(): number {
      return Math.ceil(this.itemsCount / this.limit);
    },
    isPrevDisabled(): Boolean {
      return !!(this.page === 1);
    },
    isNextDisabled(): Boolean {
      return this.page === this.maxPages;
    },
    pageNumbers(): number[] {
      let visiblePages = this.visiblePagesCount;
      if (visiblePages > this.maxPages) {
        visiblePages = this.maxPages;
      }
      let array = Array(visiblePages).fill(0);

      if (this.page < visiblePages) {
        array[0] = 1;
        array = array.map((el, i) => array[0] + i);
        if (visiblePages != this.maxPages) {
          array.push('...');
          array.push(this.maxPages);
        }
        return array;
      } else if (this.page > (this.maxPages - visiblePages + 1) ) {
        array[visiblePages - 1] = this.maxPages;
        array = array.map((el, i) => array[array.length - 1 ] - i);
        if (visiblePages != this.maxPages) {
          array.push('...');
          array.push(1);
        }
        return array.reverse();
      } else {
        array[0] = this.page - Math.ceil(visiblePages / 2) + 1;
        array = array.map((el, i) => array[0] + i);
        array.unshift('...');
        array.unshift(1);
        array.push('...');
        array.push(this.maxPages);
        return array;
      }
      return array;
    },
  },
  methods: {
    updatePage(p) {
      if (typeof p !== 'number') {
        return;
      }
      this.page = p;
      this.$emit('input', this.page);
    },
    previousPage() {
      if (this.isPrevDisabled) { return; }
      if (this.page > 1) {
        this.page -= 1;
      } else {
        this.page = 0;
      }
      this.updatePage(this.page);
    },
    nextPage() {
      if (this.isNextDisabled) { return; }
      if (this.page < this.maxPages) {
        this.page += 1;
      } else {
        this.page = this.maxPages;
      }
      this.updatePage(this.page);
    },
  },
});
