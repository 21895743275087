














































































































































import Vue from 'vue';
import { RegisterForm } from '@/services/userSpace/interfaces/RegisterForm.interface';
import { api } from '@/io/api';
import InviteFriendsForm from '@/components/Forms/InviteFriendForm.vue';
import Loader from '@/components/General/Loader.vue';

export default Vue.extend({
  components: {
    InviteFriendsForm,
    Loader,
  },
  name: 'register',
  props: {
    email: {
      type: String,
      default: null,
      required: true,
    },
    accessKey: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      form: {
        username: null,
        email: null,
        password: null,
        passwordConfirmation: null,
        accessKey: null,
        cgu: false,
        locale: this.$i18n.locale,
      } as RegisterForm,
      error: null,
      loading: false,
      loadingForm: false,
      success: null,
      senderEmail: null,
    };
  },
  async created() {
    this.loading = true;
    if (!this.email || !this.accessKey) {
      this.$router.push({ name: 'landingPage' });
    } else {
      await api()
        .route('user/register/check')
        .params({ email: this.email, access_key: this.accessKey })
        .post()
        .then(() => {
          // next();
        })
        .catch(err => {
          if (err.message == 'accessKey.error.account_already_exists') {
            this.$router.push({ name: 'login', query: { email: this.email } });
          } else {
            this.$router.push({ name: 'landingPage' });
          }
        });
    }
    this.loading = false;
  },
  mounted() {
    this.form.accessKey = this.accessKey;
    this.form.email = this.email;
  },

  methods: {
    async sendForm() {
      this.loadingForm = true;
      try {
        await api()
          .route('user/register')
          .params({
            username: this.form.username,
            email: this.form.email,
            password: this.form.password,
            password_confirmation: this.form.passwordConfirmation,
            access_key: this.form.accessKey,
            cgu: this.form.cgu,
            locale: this.form.locale,
          })
          .post();
        this.error = null;
        this.success = this.$t('register.success.account_created');
      } catch (error) {
        let err = error.message ? error.message : 'error.server';
        if (!error.fatal) {
          err = 'error.server';
        }
        this.error = this.$t(err);
      }
      this.loadingForm = false;
    },
  },
});
