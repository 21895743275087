












import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({
  name: 'n-ProfilePageMenuItem',
})
export default class ProfilePage extends Vue {
  // /**
  //  * route for router link
  //  */
  // @Prop({
  //   type: Object,
  //   required: true,
  // })
  // public readonly to!: any;

  /**
   * if important add icon
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public readonly important!: boolean;
  /**
   * Emit on dropdown item click
   */
  @Emit('click')
  public click() {
    //
  }
}
