export function deepEquals(a: any, b: any, exclude?: string[]): boolean {
    if (typeof a != typeof b) {
      return false;
    }
    if (typeof a == 'object') {
      if (a instanceof Date) {
        if (b instanceof Date) {
          return a == b;
        } else {
          return false;
        }
      } else if (b instanceof Date) {
        return false;
      }
      for (const p in a) {
        if (exclude && exclude.indexOf(p) !== -1) {
          continue;
        }
        if (!deepEquals(a[p], b[p])) {
          return false;
        }
      }
      for (const p in b) {
        if (exclude && exclude.indexOf(p) !== -1) {
          continue;
        }
        if (!deepEquals(a[p], b[p])) {
          return false;
        }
      }
      return true;
    } else {
      return a == b;
    }
  }

export function  clone(obj: any, ignore?: string[]|string|null, hidePrivate: boolean = false): any {
      // console.log(obj);
      if (ignore) {
          if (typeof ignore == 'string') {
              ignore = [ignore];
            }
        }
        // Handle the 3 simple types, and null or undefined
      if (null == obj || 'object' != typeof obj) { return obj; }
        // Handle Date
      if (obj instanceof Date) {
            const copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }
        // Handle Array
      if (obj instanceof Array) {
            const copyArray = [];
            for (let i = 0, len = obj.length; i < len; i++) {
                copyArray[i] = clone(obj[i], null, hidePrivate);
            }
            return copyArray;
        }
        // Handle Object
      if (obj instanceof Object) {
            const copyObject: any = {};
            for (const attr in obj) {
                if (
                    obj.hasOwnProperty(attr) &&
          (!ignore || ignore.indexOf(attr) == -1) &&
          (!hidePrivate || attr.substring(0, 1) != '_')
          ) {
          if (obj[attr] === obj) {
              // circular
              copyObject[attr] = copyObject;
            } else {
                copyObject[attr] = clone(obj[attr], null, hidePrivate);
            }
        }
    }
            return copyObject;
    }
      throw new Error('Unable to copy obj! Its type isn\'t supported.');
  }

export function merge(firstObject: any, secondObject: any): any {
    return mergeObjects(firstObject, secondObject);
  }
function  mergeProperties(propertyKey: string, firstObject: any, secondObject: any): any {
    const propertyValue = firstObject[propertyKey];
    const propertyValue2 = secondObject[propertyKey];
    if (
      typeof propertyValue === 'object' &&
      !(propertyValue instanceof Date) &&
      propertyValue2 !== undefined &&
      !(propertyValue2 instanceof Date)
    ) {
      return mergeObjects(
        firstObject[propertyKey],
        secondObject[propertyKey],
      );
    } else if (secondObject[propertyKey] === undefined) {
      return firstObject[propertyKey];
    }
    return secondObject[propertyKey];
  }
function mergeObjects(firstObject?: any, secondObject?: any): any {
    if (firstObject == undefined) {
      return secondObject;
    }
    if (secondObject == undefined) {
      return firstObject;
    }
    const finalObject: any = {};
    // Merge first object and its properties.
    // eslint-disable-next-line forin
    for (const propertyKey in firstObject) {
      finalObject[propertyKey] = mergeProperties(
        propertyKey,
        firstObject,
        secondObject,
      );
    }
    // Merge second object and its properties.
    for (const propertyKey in secondObject) {
      finalObject[propertyKey] = mergeProperties(
        propertyKey,
        secondObject,
        finalObject,
      );
    }
    return finalObject;
  }

export function hidden(object: any, property: string, value: any, writable: boolean = true): void {
    return Object.defineProperty(object, property, {
        enumerable: false,
        writable,
        value,
    });
  }
