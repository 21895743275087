










import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import styles from '@nbzify/scss/_exports.scss';

@Component({
  name: 'n-Button',
})
export default class Button extends Vue {
  /**
   * Boolean to change display of button as text
   */
  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  public text!: boolean;

  /**
   * Boolean to change state of button to disabled or not
   */
  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  public disabled!: boolean;

  /**
   * Boolean to make him small !
   */
  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  public small!: boolean;

  /**
   * Boolean for the loading image if necessary!
   */
  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  public loading!: boolean;

  /**
   * textColor override the default text color from design system Colors
   */
  @Prop({
    type: String,
    default: 'WhiteNormal',
    required: false,
  })
  public textColor!: 'WhiteNormal' |  'Primary';

  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  public dark!: boolean;

  @Prop({
    type: String,
    required: false,
  })
  public type!: string;

  @Emit('click')
  public click() {
    //
  }

  get isDark(): string {
    return this.dark ? 'dark' : '';
  }

  get isText(): string {
    return this.text ? 'text' : '';
  }

  get isDisabled(): string {
    return this.disabled ? 'disabled' : '';
  }

  get isSmall(): string {
    return this.small ? 'small' : '';
  }

  get customStyle() {
    return {
      '--color': styles[`color${this.textColor}`],
      '--color-hover': styles[`color${this.textColor}`],
    };
  }
}
