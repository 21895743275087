


















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'n-NavBar',
})
export default class NavBar extends Vue {
  /**
   * Padding left pour le mode déconnecté
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public leftPadded!: boolean;

  get isLeftPadded(): string {
    return this.leftPadded ? 'navbar-pad-left' : '';
  }
}
