















































import Vue from 'vue';
import { api } from '@/io/api';

export default Vue.extend({
  name: 'landingForm',
  data() {
    return {
      email: null,
      loading: false,
      success: null,
    };
  },
  methods: {
    async sendForm() {
      this.loading = true;
      try {
        await api()
          .route('user/accessKey')
          .params({ email: this.email, locale: this.$i18n.locale })
          .post();
        this.success = this.$t('accessKey.success.user');
      } catch (error) {
        let err = error.message;
        if (!error.fatal) {
          err = 'error.server';
        }
        (this.$refs.validator as any).setErrors([this.$t(err)]);
      }
      this.loading = false;
    },

    resetField() {
      (this.$refs.observer as any).reset();
      if (this.success) {
        (this.email = null), (this.success = null);
      }
    },
  },
});
