








































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { auth } from '@/auth/index';

interface IChallengeForm {
  platformId: number|string;
}

export default Vue.extend({
  name: 'match-actions',
  props: {
    challenge: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        platformId: '',
      } as IChallengeForm,
      error: null,
      start: false,
      end: false,
    };
  },
  created() {
    this.init();
  },
  watch: {
    'gamesAccounts'(value) {
      if (!value) { return; }
      this.init();
    },
    '$route.params.challengeId': {
      handler() {
        this.clear();
      },
    },
  },
  computed: {
    ...mapGetters('gameAccount', {gamesAccounts: 'games'}),
    /**
     * User participant (≠opponent)
     */
    participant(): any {
      if (!this.challenge?.participants) { return null; }
      const participant = this.challenge.participants.find((p) => p.user_id == auth.id());
      return participant;
    },
    multiPlatform() {
      if (this.gamesAccounts?.length > 1) { return true; }
      return false;
    },
    isStarted(): Boolean {
      if (this.participant) { return !!(this.participant?.start_date); }
      return false;
    },
    isEnded(): Boolean {
      if (this.participant) { return !!(this.participant?.end_date); }
      return false;
    },
    isTimeout(): Boolean {
      if (this.participant) { return !!this.participant.timeout; }
      return false;
    },
    isActive(): Boolean {
      return !this.isTimeout && (!this.isStarted || !this.isEnded);
    },
  },
  methods: {
    init() {
      if (this.gamesAccounts.length == 1) {
        this.form.platformId = this.gamesAccounts[0].platform_id;
      }
    },

    hasPlatform() {
      if (!this.form.platformId) {
        return false;
      }
      return true;
    },

    async startChallenge() {
      if (!this.hasPlatform()) {
        this.error = this.$t('actions.error.selectPlatform');
        return;
      }
      if (!this.start) {
        try {
          this.start = true;
          await this.$store.dispatch('user/startChallenge', { gameId: this.challenge.game_id, challengeId: this.challenge.id, platformId: this.form.platformId });

        } catch (error) {
          const err = this.$t(error.message);
          console.error('Error:', err );
          this.start = false;
        }
      }
    },

    async endChallenge(): Promise<void> {
      if (this.challenge && !this.end) {
        try {
          this.end = true;
          await this.$store.dispatch('user/endChallenge', { gameId: this.challenge.game_id, challengeId: this.challenge.id });

        } catch (error) {
          const err = this.$t(error.message);
          console.error('Error:', err );
          this.end = false;
        }
      }
    },

    clear() {
      this.form = {
        platformId: '',
      };
      this.error = null;
      this.start = false;
      this.end = false;
    },
  },
});
