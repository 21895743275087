




























































import Vue from 'vue';
import { SignInForm } from '../services/userSpace/interfaces/SignInForm.interface';

export default Vue.extend({
  name: 'signIn',
  props: {
    email: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      form: {
        login_id: '',
        password: '',
        locale: this.$i18n.locale,
      } as SignInForm,
      loading: false,
      error: null,
    };
  },
  created() {
    if (this.email) {
      this.form.login_id = this.email;
    }
  },

  methods: {
    async sendForm(valid: boolean) {
      this.loading = true;
      await this.$store
        .dispatch('auth/login', this.form)
        .then(res => {
          this.$router.push({ name: 'home' });
          this.error = null;
        })
        .catch(error => {
          let err = 'error.server';
          if (error.message && error.message.startsWith('login.error')) {
            err = error.message;
          }
          this.error = this.$t(err);
        });
      this.loading = false;
    },
  },
});
