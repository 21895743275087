














































import Vue from 'vue';
import Debug from '@/components/Mixins/Debug.vue';
import { LocaleMessage } from 'vue-i18n';
import { format } from 'date-fns';
import { auth } from '@/auth/index';
import { mapGetters } from 'vuex';
import { config } from '@/env/Configuration';

export default Vue.extend({
  name: 'match-line',
  mixins: [Debug],
  props: {
    match: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters('games', ['games']),
    game(): any {
      return this.games.find((g) => g.id == this.match.game_id);
    },
    user(): any {
      const participant = this.userParticipant;
      if (participant) { return participant.user; }
      return null;
    },
    userParticipant(): any {
      if (!this.match.participants) { return null; }
      const participant = this.match.participants.find((participant) => {
          return participant.user_id == auth.id();
      });
      return participant;
    },
    opponent(): any {
      const participant = this.opponentParticipant;
      if (participant) { return participant.user; }
      return null;
    },
    opponentParticipant(): any {
      if (!this.match.participants) { return null; }
      const participant = this.match.participants.find((participant) => {
          return participant.user_id != auth.id();
      });
      return participant;
    },
    mode(): any {
      if (!this.match || !this.match.mode_id || !this.game) { return; }
      return this.game.modes.find((mode) => mode.id == this.match.mode_id);
    },
    startTime(): string {
      const first = this.match.participants.reduce((prev, curr) => {
          if (curr.start_date == null) { return prev; }
          return prev.start_date < curr.start_date ? prev : curr;
      });
      if (first.start_date) {
        return format(new Date(first.start_date), 'dd/LL - kk:mm');
      }
      return '-';
    },
    state(): LocaleMessage|null {
      const state = this.userParticipant.state;

      // Challenge is ended
      if (this.match.state == 'ended' && !state) {
        return this.$t('matches.state.processing');
      }

      // Challenge is closed or timeout
      if (state) {
        if (state == 'win') {
          return this.$t('matches.state.win');
        }
        if (state == 'lose') {
          return this.$t('matches.state.lose');
        }
        if (state == 'draw') {
          return this.$t('matches.state.draw');
        }
      }

      // Challenge is running
      if (!this.userParticipant.start_date) { return this.$t('matches.state.notStarted'); }
      if (!this.userParticipant.end_date) { return this.$t('matches.state.started'); }

      if (this.opponentParticipant) {
        if (!this.opponentParticipant.start_date) { return this.$t('matches.state.opponentNotStarted', this.opponent.name, { opponentName: this.opponent.name }); }
        if (!this.opponentParticipant.end_date) { return this.$t('matches.state.opponentStarted', this.opponent.name, { opponentName: this.opponent.name }); }
      } else {
        return this.$t('matches.state.noPlayer');
      }

      return this.$t('matches.state.processing');
    },
    bet(): string|null {
      if (this.userParticipant.state == null) {
        return this.match.bet;
      }
      if (this.userParticipant.state == 'win') {
        const totalBet = this.match.bet * this.match.total_participants;
        return '+' + (totalBet - (totalBet * config('app.nbz.commission')));
      }
      if (this.userParticipant.state == 'lose') {
        return '-' + this.match.bet;
      }
      if (this.userParticipant.state == 'draw') {
        const totalBet = this.match.bet * this.match.total_participants;
        return '+' + (totalBet - (totalBet * config('app.nbz.commission')) / this.match.total_participants);
      }
    },
    result(): string|null {
      return this.userParticipant.state;
    },
  },
  methods: {
    redirectMatch(): void {
      this.$router.push({ name: 'match', params: { gameSlug: this.game.slug, currentChallenge: this.match, challengeId: this.match.uuid }});
    },
  },
});
