import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import { register } from './make';
import App from '../App.vue';
import { getStore } from '@/store';
import { getRouter } from '@/router';
import { Components } from './nbzify';
import { clickOutside } from '@/utils/Directives';
import VueToast from 'vue-toast-notification';
import VModal from 'vue-js-modal';
import 'vue-toast-notification/dist/theme-sugar.css';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { config } from '@/env/Configuration';
import i18n from '@/app/i18n';

export function createVue(): Vue {
  Vue.config.performance = true;
  Vue.use(VueRouter);
  Vue.use(Vuex);
  Vue.use(VueToast);
  Vue.use(VModal, {
    dynamicDefaults: {
      draggable: false,
      resizable: true,
      adaptive: true,
      height: 'auto',
    },
  });

  Vue.directive('click-outside', clickOutside);

  for (const name in Components) {
    Vue.component(Components[name].options.name, Components[name]);
  }

  let router: VueRouter, store: any;
  router = getRouter();
  store = getStore();
  register('store', store);
  register('router', router);

  // Create VueI18n instance with options

  if (config('sentry.sentryVueDns')) {
    Sentry.init({
      Vue,
      dsn: config('sentry.sentryVueDns'),
      integrations: [new Integrations.BrowserTracing()],
      logErrors: true,
      environment: config('app.environment'),

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: config('sentry.sentryTracesSampleRate'),
    });
  }

  const app: Vue = new Vue({
    router,
    store,
    i18n,
    render: (h: any) => h(App),
  });

  // global
  return app;
}
