







import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({
  components: {},
  name: 'n-DropdownMenuItem',
})
export default class DropdownMenuItem extends Vue {
  /**
   * Boolean to say if dropdownMenuItem use slot
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  private sloted!: boolean;

  /**
   * Emit on dropdown item click
   */
  @Emit('click')
  public click() {
    //
  }

  get isSloted(): string {
    return this.sloted ? 'slot' : '';
  }
}
