











import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import styles from '@nbzify/scss/_exports.scss';

interface Status {
  label: string;
  circle: 'plain' | 'border' | 'none';
  color: string;
  value: string;
  active: boolean;
}

@Component({
  name: 'n-InputStatus',
})
export default class InputStatus extends Vue {
  public statusTab: Status[] = [];

  /**
   * Current status of user
   */
  @Prop({
    type: String,
    required: true,
    default: 'online',
  })
  public readonly status;
  private internalStatus: string;

  public mounted() {
    this.statusTab = [
      { label: 'Disponible', circle: 'plain', color: 'Win', value: 'online', active: false },
      { label: 'Occupé', circle: 'border', color: 'Lose', value: 'busy', active: false },
      { label: 'Invisible', circle: 'border', color: 'GreyLight', value: 'invisible', active: false },
      { label: 'AFK', circle: 'none', color: '', value: 'afk', active: false },
    ];
    this.internalStatus = this.status;
    this.statusTab.find((val) => val.value === this.internalStatus).active = true;
  }

  @Emit('click')
  public click(status: Status) {
    const lastStatus = this.statusTab.find((val) => val.value === this.internalStatus);
    lastStatus.active = false;
    this.internalStatus = status.value;
    status.active = true;
    return status;
  }

  private colorCircle(status: Status) {
    if (status.circle !== 'none') {
      return {
        '--bg-color': styles[`color${status.color}`],
      };
    }
    return {};
  }
}
