import DropdownMenu from './DropdownMenu.vue';
import DropdownMenuItem from './DropdownMenuItem.vue';

const dropdownMenu = {
  DropdownMenu,
  DropdownMenuItem,
};

// @ts-ignore
for (const elem in dropdownMenu) {
  if (dropdownMenu.hasOwnProperty(elem)) {
    const element = (dropdownMenu as any)[elem];
    // @ts-ignore
    element.install = (Vue) => {
      Vue.component((element as any).options.name, element);
    };
  }
}

export default dropdownMenu;
