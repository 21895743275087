

















































import Vue from 'vue';
import { api } from '@/io/api';
import { mapGetters } from 'vuex';
import Loader from '@/components/General/Loader.vue';

export default Vue.extend({
  components: { Loader },
  name: 'ranking',
  data() {
    return {
      users: null,
      limit: 100,
    };
  },
  created() {
    this.getRanking();
  },
  computed: {
    ...mapGetters('auth', { auth: 'user' }),
  },
  methods: {
    async getRanking(): Promise<void> {
      const params = {
        game_id: null,
        limit: this.limit,
      };
      try {
        const users = await api().route('rank').params(params).get();
        this.users = users;
      } catch (error) {
        console.log('error', error);
      }
    },
  },
});
