






























import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import InputLabel from '../../LabelElements/InputLabel/InputLabel.vue';
import zxcvbn from 'ts-zxcvbn';

@Component({
  components: {
    InputLabel,
  },
  name: 'n-InputPassword',
})
export default class InputPassword extends Vue {
  public show: boolean = false;
  public innerValue: string = '';

  /**
   * Value de l'input
   */
  @Prop({
    type: String,
    required: false,
  })
  public value!: string;

  /**
   * Taille minimal du password
   */
  @Prop({
    type: Number,
    default: 8,
  })
  public minPassLength!: number;

  /**
   * Boolean pour afficher ou non le strength meter
   */
  @Prop({
    type: Boolean,
    default: true,
  })
  public showStrengthMeter!: boolean;

  /**
   * Label de l'input placé haut dessus
   */
  @Prop({
    type: String,
    required: false,
  })
  public label!: string;

  /**
   * Nom de l'input permettant de lié le label à l'input
   */
  @Prop({
    type: String,
    required: true,
    default: 'name',
  })
  public name!: string;

  /**
   * Placeholder de l'input
   */
  @Prop({
    type: String,
    required: true,
    default: 'placeholder',
  })
  public placeholder!: string;

  /**
   * Permet de définir si l'input est requis ou non
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public required!: boolean;

  /**
   * Utiliser pour le message d'erreur
   */
  @Prop({
    type: String || null,
    required: false,
    default: null,
  })
  public readonly error!: string | null;

  /**
   * Utiliser pour le message de succés
   */
  @Prop({
    type: String || null,
    required: false,
    default: null,
  })
  public readonly success!: string | null;

  /**
   * Utiliser pour définir la valeur de l'autocomplete (new-password || current-password)
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public readonly isNew: boolean;

  @Watch('value')
  public updateValue() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }

  public mounted() {
    this.innerValue = this.value;
  }

  public passwordStrengthBlockIsActive(id: number): string {
    if (this.innerValue && this.innerValue.length >= 1) {
      return id - 1 <= this.passwordStrength ? 'active' : '';
    }
    return '';
  }

  get passwordStrength(): number {
    let arg: string[] = [];
    if (this.innerValue && this.innerValue.length >= 1) {
      arg = this.innerValue.split('');
    }
    return this.innerValue ? zxcvbn(this.innerValue, arg).score : 0;
  }

  get passwordType(): string {
    if (this.isNew) {
      return 'new_password';
    } else {
      return 'current_password';
    }
  }

  public hasError(): string {
    return this.error ? 'error' : '';
  }

  @Emit('input')
  public input() {
    return this.innerValue ? this.innerValue : null;
  }

  @Emit('blur')
  public blur() {
    return this.innerValue ? this.innerValue : null;
  }

  @Emit('focus')
  public focus() {
    //
  }
}
