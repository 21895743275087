import chalk from 'chalk';

import {logger} from '../logs/logger';
import {kebab} from '../utils/Strings';

interface IStep {
    name: string;
    callback: () => (void|Promise<void>);
    condition?: boolean|(() => boolean|Promise<any>);
}
function debug(instance: any): debug.Debugger {
    return logger(`nbz:${kebab(instance.constructor.name)}:step`);

}
export function StepHandler(parent: any) {

  let step: number = 0;
  const steps: IStep[] = [];
  return class StepHandler extends parent {
    public async boot() {
      await this.steps();
    }
    public use(name: string, callback: () => (void|Promise<void>), condition?: boolean|(() => boolean|Promise<any>)) {
        if (typeof name == 'function') {
            callback = name;
            name = `step ${steps.length + 1}`;
        }
        const exists = steps.find((s) => s.name == name);
        if (exists) {
          debug(this)(`overwriting step ${name}`);
          steps.splice(steps.indexOf(exists), 1);
        }
        steps.push({
            name,
            callback,
            condition,
        });
    }
    public async steps(): Promise<any> {
      // console.log("step", this._steps);
      if (!steps[step]) {
        // end
        return this.bootedSteps();
      }
      const current = steps[step];
      let conditionResult;
      if (current.condition !== undefined) {
        if (typeof current.condition == 'boolean') {
          conditionResult = current.condition;
        } else {
          conditionResult = current.condition();
          if (!(conditionResult instanceof Promise)) {
            conditionResult = Promise.resolve(conditionResult);
          }
          try {
            conditionResult = await conditionResult;
          } catch (error) {
            conditionResult = false;
          }
        }
      } else {
        conditionResult = true;
      }
      if (conditionResult === false) {
        debug(this)(chalk.bgRed(`ignoring step ${current.name}`));
        step++;
        return this.steps();
      }
      debug(this)(`${current.name} ` + chalk.red(`(${step + 1}/${steps.length})`));
      let result = current.callback();
      if (!(result instanceof Promise)) {
        result = Promise.resolve(result);
      }
      return result.then(
        () => {
          step++;
          return this.steps();
        },
        (error: any) => {
          console.error('Error during step ' + current.name, error, this);
          this.onStepHandlerError(error, current);
        },
      );
    }
    public bootedSteps() {
      debug(this)('done');
    }
    public onStepHandlerError(error: any, step: IStep) {
      // to override if needed
    }
  };
}
