













import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'n-ProfilePage',
})
export default class ProfilePage extends Vue {}
