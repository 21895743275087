var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register"},[(!_vm.loading)?[_c('div',{staticClass:"register__content"},[_c('h2',{staticClass:"register__content-title"},[_vm._v(_vm._s(_vm.$t('register.title')))]),(_vm.success)?_c('div',{staticClass:"confirmation"},[_c('n-Card',{staticClass:"registerconf"},[_c('h3',[_vm._v(_vm._s(_vm.$t('register.success.title')))]),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('register.success.text')))]),_c('router-link',{attrs:{"to":{ name: 'login', params: { email: _vm.email } },"tag":"n-Button"}},[_vm._v(_vm._s(_vm.$t('register.success.connectButton')))])],1),_c('InviteFriendsForm',{attrs:{"senderEmail":_vm.email}}),_c('div',{staticClass:"registerconf-action"})],1):_c('ValidationObserver',{staticClass:"register-form",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{ref:"register",on:{"submit":function($event){$event.preventDefault();_vm.success || _vm.loadingForm ? null : handleSubmit(_vm.sendForm)}}},[_c('n-Card',{staticClass:"register__content-form"},[_c('h3',[_vm._v(_vm._s(_vm.$t('register.form.title')))]),(_vm.error)?_c('n-ErrorLabel',[[_vm._v(_vm._s(_vm.error))]],2):_vm._e(),_c('ValidationProvider',{staticClass:"validationProvider",attrs:{"name":"username","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('n-InputText',{ref:"username",attrs:{"label":_vm.$t('register.form.username.label'),"placeholder":_vm.$t('register.form.username.placeholder'),"name":"pseudo","error":errors[0],"required":""},on:{"input":reset,"focus":reset},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"validationProvider",attrs:{"name":"email","rules":"email|required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('n-InputText',{ref:"email",attrs:{"label":_vm.$t('register.form.email.label'),"placeholder":_vm.$t('register.form.email.label'),"name":"email","error":errors[0],"readonly":"","required":""},on:{"input":reset,"focus":reset},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"validationProvider",attrs:{"name":"password","rules":"required|password","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('n-InputPassword',{ref:"password",attrs:{"label":_vm.$t('register.form.password.label'),"placeholder":_vm.$t('register.form.password.placeholder'),"name":"password","isNew":true,"error":errors[0],"required":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"validationProvider",attrs:{"name":"passwordConf","rules":"required|confirmPassword:@password","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('n-InputPassword',{ref:"passwordConf",attrs:{"label":_vm.$t('register.form.confirmPassword.label'),"placeholder":_vm.$t('register.form.confirmPassword.placeholder'),"name":"passwordConf","isNew":true,"error":errors[0],"required":""},on:{"input":reset,"focus":reset},model:{value:(_vm.form.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.form, "passwordConfirmation", $$v)},expression:"form.passwordConfirmation"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"validationProvider",attrs:{"name":"accessKey","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('n-InputText',{ref:"accessKey",attrs:{"label":_vm.$t('register.form.alphaKey.label'),"placeholder":_vm.$t('register.form.alphaKey.placeholder'),"name":"accessKey","error":errors[0],"required":"","readonly":""},on:{"input":reset,"focus":reset},model:{value:(_vm.form.accessKey),callback:function ($$v) {_vm.$set(_vm.form, "accessKey", $$v)},expression:"form.accessKey"}})]}}],null,true)})],1)],1),_c('ValidationProvider',{staticClass:"validationProvider register__content-checkbox",attrs:{"name":"CGU","rules":{ required: { allowFalse: false } },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('n-Checkbox',{attrs:{"error":errors[0],"name":"cgu"},model:{value:(_vm.form.cgu),callback:function ($$v) {_vm.$set(_vm.form, "cgu", $$v)},expression:"form.cgu"}},[_c('p',[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('register.form.cgu.terms1'))+" ")]),_c('n-Links',{attrs:{"underline":"","to":{ name: 'cgu' },"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('register.form.cgu.terms2')))])],1)])]}}],null,true)}),_c('n-Button',{attrs:{"loading":_vm.loadingForm},on:{"click":function($event){return handleSubmit(_vm.sendForm)}}},[_vm._v(" "+_vm._s(_vm.$t('register.form.validationButton'))+" ")])]}}],null,false,3784278751)})],1),_c('div',{staticClass:"register__img"},[_c('div',{staticClass:"register__img-content"},[_c('img',{staticClass:"register__img-content-logo",attrs:{"src":require("@/assets/img/logo/noobizes-manette.png"),"srcset":require("@/assets/img/logo/noobizes-manette@2x.png") + " 2x"}}),_c('h3',{staticClass:"register__img-content-subtitle"},[_vm._v(_vm._s(_vm.$t('register.catchPhrase.top')))]),_c('h2',{staticClass:"register__img-content-title"},[_vm._v(_vm._s(_vm.$t('register.catchPhrase.bottom')))]),_c('img',{staticClass:"register__img-content-coin",attrs:{"src":require("@/assets/img/picto/nbz-coin.svg")}}),_c('img',{staticClass:"register__img-content-pubg",attrs:{"src":require("@/assets/img/background/right-registerpage.png"),"srcset":require("@/assets/img/background/right-registerpage@2x.png") + " 2x, " + require("@/assets/img/background/right-registerpage@3x.png") + " 3x"}})])])]:[_c('loader')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }