
































import Vue from 'vue';

export default Vue.extend({
  name: 'n-GameInfo',
  props: {
    name: {
      type: String,
      required: false,
    },
    logo: {
      type: String,
      required: false,
    },
    modes: {
      type: Array,
      required: false,
      default: [],
    },
    isAvailable: {
      type: Boolean,
      required: false,
      default: false,
    },
    averageBet: {
      type: Number,
      required: false,
      default: 0,
    },
    /**
     * Number of players with gameAccount connected
     */
    registeredPlayers: {
      type: Number,
      required: false,
      default: 0,
    },
    xl: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed : {
    modesLength(): number {
      return this.modes ? this.modes.length : 0;
    },
  },
});
