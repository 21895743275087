import { randInteger } from './Maths';

interface ILevenshteinOptions {
  insertion_cost: number;
  deletion_cost: number;
  substitution_cost: number;
}
const SOUNDEX: any = {
  b: 1,
  f: 1,
  p: 1,
  v: 1,
  c: 2,
  g: 2,
  j: 2,
  k: 2,
  q: 2,
  s: 2,
  x: 2,
  z: 2,
  d: 3,
  t: 3,
  l: 4,
  m: 5,
  n: 5,
  r: 6,
};
const tokens: string[] = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
/**
 * Replace all occurences of needle inside a text
 * @param {String} str string to search in
 * @param {String} needle  needle to be replaced
 * @param {String} replace needle replacement
 */
export function replaceAll(str: string, needle: string, replace: string): string {
  while (str.indexOf(needle) != -1) { str = str.replace(needle, replace); }
  return str;
}
/**
 * Converts text to kebab camelCase
 * @param {String} text Original text to convert into camelCase
 * @param {String} delimiter Default delimiters
 */
export function camel(text: string, delimiter: string = '-|_'): string {
  if (!text) {
    return text;
  }
  const reg: RegExp = new RegExp(delimiter, 'g');
  // console.log("REPLACE", text.replace(reg, " "));
  text = capitalizeAllWords(text.replace(reg, ' '));
  text = text.replace(/ /g, '');
  text = text.substring(0, 1).toLowerCase() + text.substring(1);
  return text;
}
/**
 * Converts text to kebab kebab_case
 * @param {String} text Original text to convert into kebab_case
 * @param {String} delimiter Default delimiters
 */
export function kebab(text: string, delimiter: string = '_'): string {
  if (!text) {
    return text;
  }
  text = text.substring(0, 1).toLowerCase() + text.substring(1);
  text = text.replace(/([A-Z])/g, ' $1');
  text = text.replace(/ /g, delimiter);
  return text.toLowerCase();
}
/**
 *  Transform first letter to uppercase
 * @param {String} text Text to transform
 */
export function firstUpperCase(text: string): string {
  if (!text) {
    return text;
  }

  return text.charAt(0).toUpperCase() + text.substring(1);
}
export function capitalizeAllWords(text: string): string {
  if (!text) {
    return text;
  }
  // text = text.toLowerCase();
  return text.replace(/\b[a-z]/g, function(letter) {
    return letter.toUpperCase();
  });
}
/**
 * Removes accents from words
 * @param {String} str string to clean
 * @return {String} cleaned string
 */
export function stripAcents(str: string): string {
  const norm: string[] = [
    'À',
    'Á',
    'Â',
    'Ã',
    'Ä',
    'Å',
    'Æ',
    'Ç',
    'È',
    'É',
    'Ê',
    'Ë',
    'Ì',
    'Í',
    'Î',
    'Ï',
    'Ð',
    'Ñ',
    'Ò',
    'Ó',
    'Ô',
    'Õ',
    'Ö',
    'Ø',
    'Ù',
    'Ú',
    'Û',
    'Ü',
    'Ý',
    'Þ',
    'ß',
    'à',
    'á',
    'â',
    'ã',
    'ä',
    'å',
    'æ',
    'ç',
    'è',
    'é',
    'ê',
    'ë',
    'ì',
    'í',
    'î',
    'ï',
    'ð',
    'ñ',
    'ò',
    'ó',
    'ô',
    'õ',
    'ö',
    'ø',
    'ù',
    'ú',
    'û',
    'ü',
    'ý',
    'ý',
    'þ',
    'ÿ',
  ];
  const spec: string[] = [
    'A',
    'A',
    'A',
    'A',
    'A',
    'A',
    'A',
    'C',
    'E',
    'E',
    'E',
    'E',
    'I',
    'I',
    'I',
    'I',
    'D',
    'N',
    'O',
    'O',
    'O',
    '0',
    'O',
    'O',
    'U',
    'U',
    'U',
    'U',
    'Y',
    'b',
    's',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'c',
    'e',
    'e',
    'e',
    'e',
    'i',
    'i',
    'i',
    'i',
    'd',
    'n',
    'o',
    'o',
    'o',
    'o',
    'o',
    'o',
    'u',
    'u',
    'u',
    'u',
    'y',
    'y',
    'b',
    'y',
  ];
  for (let i: number = 0; i < spec.length; i++) {
    str =
    replaceAll(str, norm[i], spec[i]);
  }
  return str;
}
/**
 * Mesures similarity between two strings using levenshteinDistance
 * @param {String} str1
 * @param {String} str2
 * @return Levenshtein ratio
 */
export function similarityExtends(str1: string, str2: string): number {
  str1 = String(str1);
  str2 = String(str2);
  if (!str1) {
    str1 = '';
  }
  if (!str2) {
    str2 = '';
  }
  str1 = str1.toLowerCase();
  str2 = str2.toLowerCase();
  if (str1.length + str2.length == 0) {
    return 1;
  }
  return (
    (str1.length +
      str2.length * 2 -
      levenshteinDistance(str1, str2)) /
    (str1.length + str2.length * 2)
  );
}
/**
 * Mesures similarity between two strings
 * @param {String} source
 * @param {String} target
 * @return Levenshtein distance
 */
export function levenshteinDistance(source: string, target: string, options?: ILevenshteinOptions): number {
  options = (options  || {}) as ILevenshteinOptions;
  if (isNaN(options.insertion_cost)) { options.insertion_cost = 1; }
  if (isNaN(options.deletion_cost)) { options.deletion_cost = 1; }
  if (isNaN(options.substitution_cost)) { options.substitution_cost = 1; }
  const sourceLength = source.length;
  const targetLength = target.length;
  const distanceMatrix = [[0]];
  let row, column;
  for (row = 1; row <= sourceLength; row++) {
    distanceMatrix[row] = [];
    distanceMatrix[row][0] =
      distanceMatrix[row - 1][0] + options.deletion_cost;
  }
  for (column = 1; column <= targetLength; column++) {
    distanceMatrix[0][column] =
      distanceMatrix[0][column - 1] + options.insertion_cost;
  }
  for (row = 1; row <= sourceLength; row++) {
    for (column = 1; column <= targetLength; column++) {
      const costToInsert =
        distanceMatrix[row][column - 1] + options.insertion_cost;
      const costToDelete =
        distanceMatrix[row - 1][column] + options.deletion_cost;
      const sourceElement = source[row - 1];
      const targetElement = target[column - 1];
      let costToSubstitute = distanceMatrix[row - 1][column - 1];
      if (sourceElement !== targetElement) {
        costToSubstitute = costToSubstitute + options.substitution_cost;
      }
      distanceMatrix[row][column] = Math.min(
        costToInsert,
        costToDelete,
        costToSubstitute,
      );
    }
  }
  return distanceMatrix[sourceLength][targetLength];
}
/**
 * Mesures similarity between two strings using jaro distance
 * @param {String} str1
 * @param {String} str2
 * @return jaro distance
 */
export function similarity(str1: string, str2: string, dj?: number): number {
  let jaro;
  typeof dj == 'undefined'
    ? (jaro = jaroDistance(str1, str2))
    : (jaro = dj);
  const p = 0.1; //
  let l = 0; // length of the matching prefix
  while (str1[l] == str2[l] && l < 4) { l++; }
  return jaro + l * p * (1 - jaro);
}
/**
 * Mesures similarity between two strings using levenshteinDistance
 * @param {String} s1
 * @param {String} s2
 * @return Jaro distance
 */
export function jaroDistance(s1: string, s2: string): number {
  if (typeof s1 != 'string' || typeof s2 != 'string') { return 0; }
  if (s1.length == 0 || s2.length == 0) { return 0; }
  (s1 = s1.toLowerCase()), (s2 = s2.toLowerCase());
  const matchWindow = Math.floor(Math.max(s1.length, s2.length) / 2.0) - 1;
  const matches1 = new Array(s1.length);
  const matches2 = new Array(s2.length);
  let m: number = 0; // number of matches
  let t: number = 0; // number of transpositions
  // debug helpers
  // console.log("s1: " + s1 + "; s2: " + s2);
  // console.log(" - matchWindow: " + matchWindow);
  // find matches
  let k: number = 0;
  let i: number;
  for (i = 0; i < s1.length; i++) {
    let matched = false;
    // check for an exact match
    if (s1[i] == s2[i]) {
      matches1[i] = matches2[i] = matched = true;
      m++;
    } else {
      // this for loop is a little brutal
      for (
        k = i <= matchWindow ? 0 : i - matchWindow;
        k <= i + matchWindow && k < s2.length && !matched;
        k++
      ) {
        if (s1[i] == s2[k]) {
          if (!matches1[i] && !matches2[k]) {
            m++;
          }
          matches1[i] = matches2[k] = matched = true;
        }
      }
    }
  }
  if (m == 0) { return 0.0; }
  // count transpositions

  for (i = 0; i < s1.length; i++) {
    if (matches1[k]) {
      while (!matches2[k] && k < matches2.length) { k++; }
      if (s1[i] != s2[k] && k < matches2.length) {
        t++;
      }
      k++;
    }
  }
  // debug helpers:
  // console.log(" - matches: " + m);
  // console.log(" - transpositions: " + t);
  t /= 2.0;
  return (m / s1.length + m / s2.length + (m - t) / m) / 3;
}
/**
 * Generate unique random token
 * @param {Number} size Token's size
 * @return {String} token
 */
export function token(size: number = 64): string {
  let generated: string = size > 13 + 4 ? Date.now() + '-' : '';
  while (generated.length < size) {
    generated +=
    tokens[randInteger(0, tokens.length - 1)];
  }
  while (generated.length > size) {
    generated = generated.substring(1);
  }
  return generated;
}
/**
 * Gets soundex code for a text. Allow to compares to strings sounds
 * @param {String} text String to compute
 * @param {Number} sizeMax Max size of the string to compute
 * @return {String} Soundex code
 */
export function soundex(text: string, sizeMax: number): string {
  let code = text.substring(0, 1);
  text = text.toLowerCase().replace(/wh/g, '');
  let i = 1;
  let last;
  for (i; i < text.length && (!sizeMax || code.length < 4); i++) {
    if (text[i - 1] == text[i] || last == SOUNDEX[text[i]]) {
      continue;
    }
    if (SOUNDEX[text[i]]) {
      last = SOUNDEX[text[i]];
      code += SOUNDEX[text[i]];
    }
  }
  while (code.length < 4) {
    code += '0';
  }
  return code;
}
/**
 * Test if a string starts with a needle
 * @param {String} str String to search in
 * @param {String} needle Needle to search
 * @return {Boolean}
 */
export function startsWith(str: string, needle: string): boolean {
  if (typeof str != 'string') {
    return false;
  }
  if (!str || !needle) {
    return false;
  }
  return str.indexOf(needle) == 0;
}
/**
 * Test if a string ends with a needle
 * @param {String} str String to search in
 * @param {String} needle Needle to search
 * @return {Boolean}
 */
export function endsWith(str: string, needle: string): boolean {
  if (!str || !needle) {
    return false;
  }
  const index = str.lastIndexOf(needle);
  if (!~index) { return false; }
  return index == str.length - needle.length;
}
