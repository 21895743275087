
















































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import loadGame from '@/components/Mixins/loadGame.vue';
import Pagination from '@/components/General/Pagination.vue';
import Loader from '@/components/General/Loader.vue';
import LoaderText from '@/components/General/LoaderText.vue';
import MatchLine from '@/components/Matches/MatchLine.vue';

export default Vue.extend({
  name: 'matches',
  components: {
    Pagination,
    Loader,
    LoaderText,
    MatchLine,
  },
  mixins: [loadGame],
  data() {
    return {
      game: null,
      limit: 20,
      totalEndedMatches: null,
      page: 1,
      isLoading: false,
    };
  },
  created() {
    this.getChallenges();
  },
  watch: {
    game(value) {
      this.getChallenges();
    },
    page() {
      this.getMatchesEnded();
    },
  },
  computed: {
    ...mapGetters('user', ['matches']),
    ...mapGetters('user', ['matchesActiveInitialized']),
    ...mapGetters('user', ['matchesEndedInitialized']),

    matchesActive(): any {
      return this.matches.active;
    },
    matchesEnded(): any {
      return this.matches.ended;
    },
    countMatchesActive(): string {
      return this.$tc('matches.countMatchesActive', this.matchesActive.length, { count: this.matchesActive.length });
    },
    countMatchesEnded(): string {
      if (this.totalEndedMatches == null) {
        return this.$tc('matches.matchesEnded');
      }
      return this.$tc('matches.countMatchesEnded', this.totalEndedMatches, { count: this.totalEndedMatches });
    },
    hasMatches(): boolean {
      return !!(this.matchesActive.length || this.matchesEnded.length);
    },
    isInit(): boolean {
      return !!(this.matchesActiveInitialized && this.matchesActiveInitialized);
    },
  },
  methods: {
    async getChallenges(): Promise<void> {
      if (this.game && !this.isLoading) {
        this.isLoading = true;
        this.getMatchesActived();
        await this.getMatchesEnded();
        this.isLoading = false;
      }
    },
    getMatchesActived(): void {
      this.$store.dispatch('user/getMatchesActive', { game: this.game });
    },
    async getMatchesEnded(): Promise<void> {
      const res = await this.$store.dispatch('user/getMatchesEnded', { game: this.game, limit: this.limit, page: this.page - 1 });
      this.totalEndedMatches = res.count;
    },
    redirectChallenges() {
      this.$router.push({ name: 'challenges', params: { gameSlug: this.game.slug }});
    },
    redirectPools() {
      this.$router.push({ name: 'pools', params: { gameSlug: this.game.slug }});
    },
  },
});
