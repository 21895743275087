






import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({
  name: 'n-InputSelectOption',
})
export default class InputSelectOption extends Vue {
  /**
   * Valeur de l'option du select
   */
  @Prop({
    type: String,
    required: true,
    default: 'value',
  })
  public value!: string;

  /**
   * Label de l'option du select
   */
  @Prop({
    type: String,
    required: true,
    default: 'value',
  })
  public label!: string;

  /**
   * Renvoie la valeur à la séléction
   */
  @Emit('selectValue')
  public selectValue(val: string, label: string): void {
    //
  }
}
