















import Vue from 'vue';
import SettingsHeader from '@/components/Account/SettingsHeader.vue';

export default Vue.extend({
  name: 'settings',
  components: {
    SettingsHeader,
  },
});
