















import { Vue, Component } from 'vue-property-decorator';
import LandingBanner from '@/components/LandingPage/LandingBanner.vue';
import LandingForm from '@/components/LandingPage/LandingForm.vue';
import LandingHeader from '@/components/LandingPage/LandingHeader.vue';
import LandingPresentation from '@/components/LandingPage/LandingPresentation.vue';
import LandingFooter from '@/components/LandingPage/LandingFooter.vue';
import LandingPresentationAlpha from '@/components/LandingPage/LandingPresentationAlpha.vue';

@Component({
  components: {
    LandingBanner,
    LandingHeader,
    LandingForm,
    LandingPresentation,
    LandingFooter,
    LandingPresentationAlpha,
  },
})
export default class LandingPage extends Vue {}
