



















import Vue from 'vue';
import NotificationsLayout from '@/components/Layouts/Notifications.vue';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { fr } from 'date-fns/esm/locale';
import { mapGetters } from 'vuex';


export default Vue.extend({
  name: 'challenge-results',
  components: {
    NotificationsLayout,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('games', ['games']),
    game(): any {
      if (!this.games) { return null; }
      const gameId = this.notification.payload.challenge.game_id;
      return this.games.find((g) => g.id == gameId);
    },
    gameSlug(): any {
      if (!this.game) { return null; }
      return this.game.slug;
    },
    amount(): number {
      return this.notification.payload.amount;
    },
    state(): string {
      return this.notification.payload.state;
    },
    timeout(): boolean {
      return this.notification.payload.timeout;
    },
    challenge(): any {
      return this.notification.payload.challenge;
    },
    message(): any {
      // For timeout
      return this.notification.payload.message;
    },
    delay(): any {
      return formatDistanceToNow(new Date(this.notification.created_at), { locale: fr });
    },
  },
});
