import { make } from '@/app/make';

export function found({matchMaking}) {
  const mm = make('store').getters['user/matchMaking'];
  if (mm && mm.id == matchMaking.id) {
    make('store').commit('user/setMatchMaking', matchMaking);
  }
}

export function timeout({matchMakingId}) {
  make('store').dispatch('auth/credit', null);
  const mm = make('store').getters['user/matchMaking'];
  if (mm && mm.id == matchMakingId) {
    make('store').commit('user/setMatchMaking', null);
  }
}
