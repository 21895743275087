import {make} from '@/app/make';
class Auth {

    public logout(): Promise<void> {
        return make('store').dispatch('auth/logout');
    }
    public check(): boolean {
        return !!this.user();
    }
    public user(): any {
        return make('store').getters['auth/user'];
    }
    public isAdmin(): any {
        return !!(this.user() && make('store').getters['auth/isAdmin']);
    }
    public id(): number {
        return this.user()?.id;
    }
    public token(): string {
        return this.user()?.api_token;
    }
    public initialize() {
        return make('store').dispatch('auth/initialize');
    }
}


export const auth = new Auth;
globalThis.auth = auth;
