




















import { Vue, Component, Prop } from 'vue-property-decorator';
import ApiConnector from '../ApiConnector/ApiConnector.vue';
// import LogoPubg from '../../../../assets/pubg.svg';
// import LogoSteam from '../../../../assets/steam.svg';
// import LogoEpic from '../../../../assets/epic.svg';
// import AvatarImg from '../../../../assets/avatar_test.jpg';
@Component({
  components: {
    ApiConnector,
  },
  name: 'n-GamesPage',
})
export default class GamesPage extends Vue {
  // public logoPubg: string = LogoPubg;
  // public logoSteam: string = LogoSteam;
  // public logoEpic: string = LogoEpic;
  // public avatar: string = AvatarImg;

  @Prop({
    type: Array,
    required: true,
  })
  public elements!: any;

  public created() {
    // this.elements = this.$children;
  }
  public selectFav() {
    //
  }
  public selectLink() {
    //
  }
}
