import {api} from '@/io/api';

interface IState {
    games: object|null;
    rankByGame: object|null;
    error: any|null;
    gamesInitialized: boolean;
}
export const state: IState = {
    games: null,
    rankByGame: null,
    error: null,
    gamesInitialized: false,
};
export const getters = {
    games(state: IState) {
        return state.games;
    },
    rankByGame(state: IState) {
        return state.rankByGame;
    },
    gamesInitialized(state: IState) {
        return state.gamesInitialized;
    },
};
export const actions = {
  async list({  commit   }: any) {
    commit('clearError');
    try {
        const games = await api().route('games').get();
        commit('setGames', games);
        commit('setGamesInitialized', true);
        return;
    } catch (error) {
        commit('clearGames');
        commit('setError', error.message);
        commit('setGamesInitialized', true);
        return;
    }
  },
  async getRankByGame({ commit }, { gameId, limit }: { gameId: number, limit: number }) {
    try {
        const data = await api().route('rankByGame').params({ game_id: gameId }).get();
        commit('setRankByGame', data);
    } catch (error) {
        commit('clearGames');
        commit('setError', error.message);
        return;
    }
  },
};
export const mutations = {
    setGames(state: IState, games: any) {
        state.games = games.map((g) => {
            g.available = true;
            return g;
        });
    },
    setRankByGame(state: IState, ranks: any) {
        state.rankByGame = ranks;
    },
    setGamesInitialized(state: IState, value: boolean) {
        state.gamesInitialized = value;
    },
    clearRankByGame(state) {
      state.rankByGame = null;
    },
    clearGames(state) {
      state.games = null;
    },
    setError(state, message) {
      state.error = message;
    },
    clearError(state) {
        state.error = null;
    },
};
export const namespaced = true;
