import ProfilePage from './ProfilePage.vue';
import ProfilePageMenuItem from './ProfilePageMenuItem.vue';

const profilePage = {
  ProfilePage,
  ProfilePageMenuItem,
};

// @ts-ignore
for (const elem in profilePage) {
  if (profilePage.hasOwnProperty(elem)) {
    const element = (profilePage as any)[elem];
    // @ts-ignore
    element.install = (Vue) => {
      Vue.component((element as any).options.name, element);
    };
  }
}

export default profilePage;
