






























import Vue from 'vue';
import { auth } from '@/auth/index';

export default Vue.extend({
  name: 'n-ChallengeTable',
  props: {
    value: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      sortOrder: null,
      sortParams: {
        bet: {
          field: 'bet',
          direction: 'asc',
        },
        ratio: {
          field: 'ratio',
          direction: 'asc',
        },
        mode: {
          field: 'mode',
          direction: 'asc',
        },
      },
    };
  },
  created() {
    this.sort();
  },
  watch: {
    value() {
      this.sort();
    },
  },
  computed: {
    activeRatio(): any {
      return !!(this.sortOrder?.field == 'ratio' && this.sortOrder?.direction == 'asc');
    },
    activeMode(): any {
      return !!(this.sortOrder?.field == 'mode' && this.sortOrder?.direction == 'asc');
    },
    activeBet(): any {
      return !!(this.sortOrder?.field == 'bet' && this.sortOrder?.direction == 'asc');
    },
    isAdmin(): boolean {
      return auth.isAdmin();
    },
  },
  methods: {
    sort() {
      this.sortOrder = this.value;
    },
    sortBy(type: any): any {

      if (this.sortOrder?.field == type.field) {
        if (this.sortOrder.direction == 'asc') {
          this.sortOrder.direction = 'desc';
        } else {
          this.sortOrder.direction = 'asc';
        }
      } else {
        this.sortOrder = Object.assign({}, type);
      }

      this.$emit('input', this.sortOrder);
    },
  },
});
