import {api} from '@/io/api';
import Background from '@/services/backgroundService';
import { config } from '@/env/Configuration';

interface IState {
    notifications: any;
    count: number; // Total unread notifications
    error: any|null;
}
export const state: IState = {
    notifications: [],
    count: 0,
    error: null,
};
export const getters = {
    notifications(state: IState) {
        return state.notifications;
    },
    count(state: IState) {
        return state.count;
    },
};
export const actions = {
  async get({ commit, state }: any, { offset, limit }: { offset: number, limit: number|null }) {
    commit('clearError');
    try {
        const res = await api().route('notifications').params({ offset, limit }).get();
        commit('setNotifications', res.notifications);
        commit('setCount', res.count);
        Background.watchNotifications(true);
        return;
      } catch (error) {
        Background.watchNotifications(true);
        commit('setError', error.message);
        return Promise.reject(error);
    }
  },
  async getNext({ commit }: any, { offset, limit }: { offset: number, limit: number|null }) {
    commit('clearError');
    try {
        const res = await api().route('notifications').params({ offset, limit }).get();
        commit('addNextNotifications', res.notifications);
        commit('setCount', res.count);
        return;
    } catch (error) {
        commit('setError', error.message);
        return Promise.reject(error);
    }
  },
  async getNews({ commit }: any, { lastDate }: { lastDate: any|null }) {
    commit('clearError');
    try {
        const res = await api().route('notifications/getNews').params({ lastDate }).get();
        if (res.notifications.length) {
          commit('addNewsNotifications', res.notifications);
          commit('setCount', res.count);
        }
        return;
    } catch (error) {
        commit('setError', error.message);
        return Promise.reject(error);
    }
  },

};
export const mutations = {
    setNotifications(state: IState, notifications: any) {
        state.notifications = notifications;
    },
    addNewsNotifications(state: IState, notifications: any) {
      console.log('NEW NOTIFICATION');
      state.notifications = [...notifications, ...state.notifications];
    },
    addNextNotifications(state: IState, notifications: any) {
      state.notifications = [...state.notifications, ...notifications];
    },
    update(state: IState, id) {
      const n = state.notifications.find((n) => n.id == id);
      n.read = true;
    },
    updateAll(state: IState) {
      state.notifications.map((n) => {
        n.read = true;
        return n;
      });
    },
    setCount(state: IState, count: number) {
      if (count >= 0) {
        state.count = count;
      }
    },
    increaseCount(state: IState) {
      state.count += 1;
    },
    decreaseCount(state: IState) {
      if (state.count > 0) {
        state.count -= 1;
      }
    },
    delete(state: IState, id) {
      const index = state.notifications.findIndex((n: any) => n.id == id );
      if (index > -1) {
          state.notifications.splice(index, 1);
      }
    },
    setError(state: IState, message) {
      state.error = message;
    },
    clearError(state: IState) {
        state.error = null;
    },
};
export const namespaced = true;
