








































import Vue from 'vue';
import RulesLayout from '@/components/Layouts/Rules.vue';
import ModesList from '@/components/General/ModesList.vue';

export default Vue.extend({
  name: 'match-rules',
  components: { RulesLayout, ModesList },
  props: {
    game: {
      type: Object,
      required: true,
    },
  },
});
