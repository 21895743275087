












































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import Card from '../../CardElements/Card/Card.vue';
import Avatar from '../../ProfileElements/Avatar/Avatar.vue';
import InputText from '../../InputElements/InputText/InputText.vue';

@Component({
  components: {
    Card,
    Avatar,
    InputText,
  },
  name: 'n-ApiConnector',
})
export default class ApiConnector extends Vue {
  public innerPlatform: any = '';

  /**
   * Game object
   */
  @Prop({
    type: Object,
    required: true,
  })
  public game!: object;

  /**
   * Description de la plateforme
   */
  @Prop({
    type: String,
    required: false,
  })
  public desc!: string;

  /**
   * Disponible sur PC ?
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public pc!: boolean;

  /**
   * Disponible sur PS4 ?
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public ps4!: boolean;

  /**
   * Disponible sur XBOX ?
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public xbox!: boolean;

  /**
   * Si la plateforme est lié au compte
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public linked!: boolean;

  /**
   * [IF linked = TRUE ] -> avatar du compte
   */
  @Prop({
    type: String,
    required: false,
  })
  public avatar!: string;

  /**
   * [IF linked = TRUE ] -> pseudo du compte
   */
  @Prop({
    type: String,
    required: false,
  })
  public account!: string;

  /**
   * Platform link with the game
   */
  @Prop({
    type: String,
    required: false,
  })
  public platformName!: string;

  /**
   * [IF linked = TRUE ] -> platforme du compte
   * @values 'pc' | 'xbox' | 'ps4'
   */
  @Prop({
    type: String,
    required: false,
  })
  public platform!: 'pc' | 'xbox' | 'ps4';

  public mounted() {
    this.innerPlatform = this.platform;
  }

  get isLinked(): string {
    return this.linked ? 'linked' : '';
  }

  @Emit('selectPlatform')
  public changePlatform(platform: string) {
    this.innerPlatform = platform;
    return platform;
  }
}
