
















import * as vClickOutside from 'v-click-outside';
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

Vue.use(vClickOutside);

@Component({
  components: {},
  name: 'n-DropdownMenu',
})
export default class DropdownMenu extends Vue {

  public vcoConfig = {
    handler: this.clickOutsideHandler,
    middleware: this.clickOutsideMiddleware,
    events: ['dblclick', 'click'],
    isActive: true,
  };
  private show: boolean = false;

  public clickOutsideHandler(): void {
    if (this.show) {
      this.show = false;
    }
  }

  public clickOutsideMiddleware(event: Event): boolean {
    return (event.target as HTMLTextAreaElement).parentElement.className !== 'dropdown__content';
  }
}
