import Router, { NavigationGuardNext, Route } from 'vue-router';
import ConnectedRoutes from '@/routes/ConnectedRoutes';
import DisconnectedRoutes from '@/routes/DisconnectedRoutes';
import { addMetaTags } from '@/utils/Seo';
import { auth } from './auth';

export function getRouter(): Router {
  const router: Router =  new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      ...DisconnectedRoutes,
      ...ConnectedRoutes,
      {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        beforeEnter: (to: any, from: any, next: any) => {
          if (auth.check()) {
            next({ name: 'home' });
          } else {
            next({ name: 'landingPage' });
          }
        },
      },
    ],
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    scrollBehavior(to, from, savedPosition) {
      return { x: 0, y: 0 };
    },
  });
  router.beforeEach((to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    if (!auth.check() && to.matched.some((record) => record.meta.requiresAuth)) {
      return next({ name: 'login' });
    }

    addMetaTags(to, from);

    return next();
  });

  return router;
}
