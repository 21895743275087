









import Vue from 'vue';
import IconCross from '@/components/Icons/Cross.vue';
import {api} from '@/io/api';
import { mapGetters, mapMutations } from 'vuex';

export default Vue.extend({
  name: 'notifications-layout',
  components: {
    IconCross,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('notifications', ['count']),
  },
  methods: {
    ...mapMutations('notifications', ['update', 'delete', 'decreaseCount']),
    async markAsRead() {
      if (!this.notification.read) {
        (this as any).update((this as any).notification.id);
        (this as any).decreaseCount();
        await api().route('notifications/update').params({ids: [(this as any).notification.id] }).put();
      }
    },
    async destroy(): Promise<void> {
      if (!this.notification.read) {
        (this as any).decreaseCount();
      }
      (this as any).delete((this as any).notification.id);
      await api().route('notification/delete').params({ids: [(this as any).notification.id] }).delete();
    },
  },

});
