









import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'n-NavProfileMenuItem',
})
export default class NavProfileMenuItem extends Vue {
  /**
   * route for router link
   */
  @Prop({
    type: Object,
    required: true,
  })
  public readonly to!: any;
}
