
















































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'n-NavProfileMenu',
})
export default class NavProfileMenu extends Vue {
  /**
   * Pseudo displayed on navprofilemenu
   */
  @Prop({
    type: String,
    required: true,
    default: 'Pseudo',
  })
  public readonly pseudo!: string;

  /**
   * current xp of player displayed on navprofilemenu
   */
  @Prop({
    type: Number,
    required: true,
    default: 0,
  })
  public readonly xp!: number;

  /**
   * current xp for next level of player displayed on navprofilemenu
   */
  @Prop({
    type: Number,
    required: true,
    default: 5000,
  })
  public readonly xpNextLvl!: number;

  /**
   * Converted value in euro displayed at top of nbz coin ammount
   */
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  public readonly fidVal!: string;

  /**
   * Nbz coin ammount of player displayed
   */
  @Prop({
    type: Number,
    required: true,
  })
  public readonly credit!: number;
}
