












































import Vue from 'vue';
import { api } from '@/io/api';
import { auth } from '@/auth/index';
import PlayerResume from '@/components/Match/PlayerResume.vue';


export default Vue.extend({
  name: 'match-resume',
  components: { PlayerResume },
  props: {
    challenge: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      apiParticipantUser: null,
      apiParticipantOpponent: null,
    };
  },
  created() {
    this.init();
  },
  watch: {
    challenge() {
      this.init();
    },
  },
  computed: {
    userParticipant(): any {
      if (!this.challenge) { return null; }
      if (!this.challenge.participants) {
        return null;
      }
      return this.challenge.participants.find((p) => p.user_id == auth.id());
    },
    opponentParticipant(): any {
      if (!this.challenge) { return null; }
      if (!this.challenge.participants) {
        return null;
      }
      return this.challenge.participants.find((p) => p.user_id != auth.id());
    },
  },
  methods: {
    async init(): Promise<void> {
      let userPromise = Promise.resolve(null) as any;
      let opponentPromise = Promise.resolve(null) as any;
      if (this.userParticipant && !this.userParticipant.timeout) {
        userPromise = api().route('apiParticipant').params({ participant_id: this.userParticipant.id, challenge_id: this.challenge.id }).get();
      }
      if (this.opponentParticipant && !this.opponentParticipant?.timeout) {
        opponentPromise = api().route('apiParticipant').params({ participant_id: this.opponentParticipant.id, challenge_id: this.challenge.id }).get();
      }
      await Promise.all([userPromise, opponentPromise]).then(([resUser, resOpponent]) => {
        this.apiParticipantUser = resUser ? resUser.payload.attributes.stats : null;
        this.apiParticipantOpponent = resOpponent ? resOpponent.payload.attributes.stats : null;
      });
    },
  },
});
