











































import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';

interface IChallengeForm {
  modeId: number|string;
  bet: number;
}

export default Vue.extend({
  name: 'challenge-form',
  components: {

  },
  props: {
    game: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        modeId: '',
        bet: 0,
      } as IChallengeForm,
      error: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('gameAccount', {gamesAccounts: 'games'}),

    hasCredit() {
      if (!this.user?.credit) {
        return false;
      }
      if (this.user.credit < this.form.bet) {
        return false;
      } else {
        return true;
      }
    },
    platform(): any {
      if (!this.user || !this.gamesAccounts?.length) { return false; }
      const gameAccount = this.gamesAccounts.find((el) => el.game.id == this.game.id);
      if (gameAccount?.platform) { return gameAccount.platform; }
      return false;
    },
    modesAvailabled(): any {
      if (!this.platform) { return []; }
      return this.game.platforms.find((p) => p.id == this.platform.id).modes;
    },
  },
  watch: {
    'form.modeId'() {
      this.error = null;
    },
    'form.bet'() {
      this.error = null;
    },
  },
  methods: {
    ...mapMutations('auth', ['updateCredit']),
    addBet(val) {
      this.form.bet += val;
    },
    clearBet() {
      this.form.bet = 0;
    },
    clearForm() {
      this.clearBet();
      this.form.modeId = '';
    },
    async sendForm() {
      if (this.loading) {
        return;
      }
      if (this.hasErrors()) {
        return;
      }
      if (!this.hasCredit) {
        Vue.$toast.open({
          message: (this.$t('error.lowCredit') as string),
          type: 'error',
        });
        return;
      }
      this.loading = true;
      try {
        (this as any).updateCredit({ amount: -this.form.bet });
        await this.$store.dispatch('user/createChallenge', {
          gameId: this.game.id,
          modeId: this.form.modeId,
          bet: this.form.bet,
          platformId: this.platform?.id,
          type: 'challenge',
        });
        this.clearForm();
        this.error = null;
      } catch (error) {
        if (error.message == 'error.lowCredit') {
          this.error = this.$t(error.message);
        } else if (error.message == 'challenge.error.tooManyChallenges') {
          this.error = this.$t(error.message);
        } else {
          this.error = this.$t('error.server');
        }

      }
      this.loading = false;
    },
    hasErrors() {
      if (!this.form.modeId) {
        this.error = this.$t('challenge.error.selectMode');
        return true;
      }
      if (!this.form.bet) {
        this.error = this.$t('challenge.error.selectBet');
        return true;
      }
      return false;
    },
  },
});
