


















import { Vue, Component, Prop, Watch, Emit, Model } from 'vue-property-decorator';

@Component({
  name: 'n-Checkbox',
})
export default class Checkbox extends Vue {
  public innerValue: []|boolean = false;
  @Model('input', { type: [Boolean, Array] }) public readonly modelValue!: boolean|[];

  /**
   * `Checkbox name`
   */
  @Prop({
    type: String,
    required: false,
  })
  public readonly name!: string;

  /**
   * Value de l'input
   */
  @Prop({
    required: false,
  })
  public value!: any;

  /**
   * Checkbox error
   */
  @Prop({
    type: String,
    required: false,
  })
  public readonly error!: string;

  @Watch('modelValue')
  public updateValue() {
    this.innerValue = this.modelValue;
  }

  public mounted() {
    this.innerValue = this.modelValue;
  }

  @Emit('input')
  public change() {
    return this.innerValue;
  }
}
