










































































import Vue from 'vue';
import { mapGetters } from 'vuex';

import loader from '@/components/General/Loader.vue';
import Modal from '@/components/Modal/Modal.vue';

interface IForm {
    name: string;
    platform: number;
    game: number;
}

export default Vue.extend({
  name: 'connector',
  components: { loader, Modal },
  props: {
    game: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        name: null,
        platform: null,
        game: null,
      } as IForm,
      active: null,
      loading: false,
      showForm: false,
      error: null,
      keepError: false,
    };
  },
  created() {
    if (this.isConnected) {
      this.showForm = false;
    }
    this.error = this.storeError;
    if (this.error && this.storeError.payload) {
      this.keepError = true;
      this.form = {
        name: this.storeError.payload.name,
        platform: this.storeError.payload.platform_id,
        game: this.storeError.payload.game_id,
      };
      this.active = this.storeError.payload.platform_id;
    }
  },
  watch: {
    form: {
      handler() {
        if (!this.keepError) {
          this.error = null;
        } else {
          this.keepError = false;
        }
      },
      deep: true,
    },
    storeError() {
      this.error = this.storeError;
    },
    error(value) {
      if (value) {
        if (!this.storeError.payload) {
          return;
        }
        this.keepError = true;
        this.form = {
          name: this.storeError.payload.name,
          platform: this.storeError.payload.platform_id,
          game: this.storeError.payload.game_id,
        };
        this.active = this.storeError.payload.platform_id;
      }
    },
    linkedAccounts() {
      this.resetForm();
    },
  },
  computed: {
    ...mapGetters('gameAccount', {gamesAccounts: 'games'}),
    ...mapGetters('gameAccount', { processing: 'isGameConnectProcessing'}),
    ...mapGetters('gameAccount', ['linkError']),
    ...mapGetters('gameAccount', { storeError: 'error' }),
    isConnected(): boolean {
      if (!this.gamesAccounts?.length) { return false; }
      return !!this.gamesAccounts.find((connectedGame) => connectedGame.game.id == this.game.id);
    },
    linkedAccounts(): any {
      if (!this.isConnected) { return false; }
      const accounts = this.gamesAccounts.filter((el) => {
        return el.game.id == this.game.id;
      });
      return accounts;
    },
    platformsAvailabled(): any {
      let platforms = this.game.platforms;
      if (this.linkedAccounts.length) {
        platforms = this.game.platforms.filter((p) => {
          return this.linkedAccounts.find((account) => account.platform.id != p.id);
        });
      }
      return platforms;
    },
  },
  methods: {
    async sendForm() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.showForm = false;
      this.error = null;

      const key = 'value';
      const gameId = this.$refs[this.game.slug][key];
      try {
        await this.$store.dispatch('gameAccount/createJobConnect', {
          slug: 'pubg',
          gameId,
          name: this.form.name,
          platformId: this.form.platform,
        });
        this.loading = false;

      } catch (e) {
        Vue.$toast.open({
          message: (this.$t('error.server') as string),
          type: 'error',
          duration: 5000,
        });
        this.loading = false;
      }
    },

    /**
     * Disocnnect game account
     * @param account game account
     * @param forceEndParticipants Force game disconnection and end all running participants for this game account
     */
    async disconnect(account, forceEndParticipants = false): Promise<void> {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        await this.$store.dispatch('gameAccount/disconnectGame', { gameAccountId: account.id, forceEndParticipants });
      } catch (error) {
        let err = 'error.server';
        if (error.fatal) {
          err = error.message;
        }
        this.askForceDisconnect(account);
      }
      this.loading = false;
    },
    hasError(errors): boolean {
      if (this.error) { return true; }
      if (errors.pseudo && errors.pseudo.length || errors.plateforme && errors.plateforme.length) { return true; }
      return false;
    },
    getError(errors): any {
      if (errors.pseudo && errors.pseudo.length) { return errors.pseudo[0]; } // Error pseudo from v-validate
      if (errors.plateforme && errors.plateforme.length) { return errors.plateforme[0]; } // Error plateforme from v-validate
      if (this.error) { return this.$t(this.error.message); }
    },
    resetForm() {
      this.showForm = false;
      this.active = null;
      this.form = {
        name: null,
        platform: null,
        game: null,
      };
    },

    askForceDisconnect(account) {
      this.$modal.show(
        Modal,
        {
          title: this.$t('connector.modal.title'),
          text: this.$t('connector.modal.text'),
          buttons: [
            {
              title: this.$t('connector.modal.button.confirm'),
              dark: false,
              handler: () => {
                this.$modal.hideAll();
                this.disconnect(account, true);
              },
            },
            {
              title: this.$t('connector.modal.button.cancel'),
              dark: true,
              handler: () => {
                this.$modal.hideAll();
                this.redirectMatches();
              },
            },
          ],
        },
        {
          width: '550px',
        },
      );
    },

    redirectMatches() {
      this.$router.push({ name: 'matches', params: { gameSlug: this.game.slug }});
    },
  },

});
