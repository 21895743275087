













































import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { api } from '@/io/api';
import loadGame from '@/components/Mixins/loadGame.vue';
import Debug from '@/components/Mixins/Debug.vue';

import Players from '@/components/Match/Players.vue';
import Actions from '@/components/Match/Actions.vue';
import Dotations from '@/components/Match/Dotations.vue';
import MatchInfos from '@/components/Match/MatchInfos.vue';
import MatchRules from '@/components/Rules/MatchRules.vue';
import MatchResume from '@/components/Match/Resume.vue';
import MatchMakingRules from '@/components/Rules/MatchMakingRules.vue';
import Loader from '@/components/General/Loader.vue';
import { auth } from '@/auth/index';

export default Vue.extend({
  name: 'match',
  components: { Players, Actions, Dotations, MatchInfos, MatchRules, MatchResume, MatchMakingRules, Loader },
  mixins: [loadGame, Debug],
  props: {
    currentChallenge: {
      type: Object,
      required: false,
    },
    challengeId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      game: null,
      isInit: false,
    };
  },
  async created() {
    console.log('Match:created()');
    this.init();
  },
  beforeDestroy() {
    (this as any).clearCurrentChallenge();
  },
  computed: {
    ...mapGetters('user', ['matchMaking']),
    ...mapGetters('user', { challenge: 'currentChallenge'}),
    isMatchMaking(): Boolean {
      // if (!this.matchMaking) { return false; }
      return !!(this.isMatchMakingType || (this.matchMaking && this.matchMaking.challenge_id == this.challenge.id));
    },
    isMatchMakingType(): Boolean {
      return !!(this.challenge.type == 'matchmaking');
    },
    matchMakingTitle(): any {
      if (!this.isMatchMaking) { return; }
      console.log('this.challenge.state', this.challenge.state);
      if (this.challenge.state == 'ended' || this.challenge.state == 'timeout') {
        return this.$t('match.matchMaking.ended');
      } else {
        return this.$t('match.matchMaking.found');
      }
    },
    loading(): Boolean {
      return !(this.challenge && this.game);
    },
    mode(): any {
      if (!this.challenge || !this.challenge.mode_id) { return; }
      return this.game.modes.find((m) => m.id == this.challenge.mode_id);
    },
    platform(): any {
      if (!this.challenge?.participants) { return; }
      const participant = this.challenge.participants.find((p) => p.user_id == auth.id());
      if (participant?.game_account) {
        return this.game.platforms.find((p) => p.id == participant.game_account.platform_id);
      } else {
        return;
      }
    },
    opponentPlatform(): any {
      if (!this.challenge?.participants) { return; }
      const participant = this.challenge.participants.find((p) => p.user_id != auth.id());
      if (participant?.game_account) {
        return this.game.platforms.find((p) => p.id == participant.game_account.platform_id);
      } else {
        return;
      }
    },
    isEnded(): boolean {
      return !!(this.challenge.state == 'ended' || this.challenge.state == 'closed' || this.challenge.state == 'timeout');
    },
    // For debug only
    showResults(): boolean {
      if (!(this as any).isAdmin || !this.challenge || !this.challenge?.participants?.length) { return false; }
      let show = false;
      this.challenge.participants.forEach((p) => {
        if (p.match && p.match.api_id) {
          show = true;
        }
      });
      return show;
    },
  },
  watch: {
    'game'(value) {
      if (value && !this.isInit) {
        this.init();
      }
    },
    '$route.params.challengeId': {
      handler() {
        this.init();
      },
    },
  },
  methods: {
    ...mapMutations('user', ['setCurrentChallenge', 'clearCurrentChallenge']),
    async init(): Promise<void> {
      console.log('Match:init');
      this.isInit = true;
      if (this.currentChallenge) {
        (this as any).setCurrentChallenge(this.currentChallenge);
      }
      if (this.game && this.challengeId) {
        this.getChallenge();
      }  else {
        this.isInit = false;
      }
    },

    async getChallenge() {
      try {
        console.log('Match: getChallenge', { uuid: this.challengeId, game_id: this.game.id });
        const challenge = await api().route('challenge').params({ uuid: this.challengeId, game_id: this.game.id }).get();
        (this as any).setCurrentChallenge(challenge);
      } catch (error) {
        console.log('error get challenge: ', error);
        debugger;
        this.redirectHome();
      }
    },
    redirectHome() {
      this.$router.push({ name: 'home' });
    },
  },

});
