var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"resetPassword"},[_c('div',{staticClass:"resetPassword__content"},[_c('h2',{staticClass:"resetPassword__content-title"},[_vm._v(_vm._s(_vm.$t('resetPassword.confirmation.title')))]),_c('ValidationObserver',{staticClass:"resetPassword-form",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{ref:"resetPassword",on:{"submit":function($event){$event.preventDefault();_vm.success || _vm.loading ? null : handleSubmit(_vm.sendForm)}}},[_c('n-Card',{staticClass:"resetPassword__content-form"},[_c('h3',{staticClass:"resetPassword__content-form-title"},[_vm._v(_vm._s(_vm.$t('resetPassword.confirmation.subTitle')))]),(_vm.error)?_c('n-ErrorLabel',[[_vm._v(_vm._s(_vm.error))]],2):_vm._e(),_c('ValidationProvider',{staticClass:"validationProvider",attrs:{"name":"password","rules":"required|password","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('n-InputPassword',{ref:"password",attrs:{"label":_vm.$t('resetPassword.confirmation.password.label'),"placeholder":_vm.$t('resetPassword.confirmation.password.placeholder'),"name":"password","error":errors[0],"isNew":true,"required":""},on:{"input":reset,"focus":reset},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"validationProvider",attrs:{"name":"passwordConf","rules":"required|confirmPassword:@password","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('n-InputPassword',{ref:"passwordConf",attrs:{"label":_vm.$t('resetPassword.confirmation.confirmPassword.label'),"placeholder":_vm.$t('resetPassword.confirmation.confirmPassword.placeholder'),"name":"passwordConf","success":_vm.success,"error":errors[0],"isNew":true,"required":""},on:{"input":reset,"focus":reset},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}})]}}],null,true)})],1)],1),(!_vm.success)?_c('n-Button',{staticClass:"resetPassword__content-submit",attrs:{"loading":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.sendForm)}}},[_vm._v(" "+_vm._s(_vm.$t('resetPassword.confirmation.validationButton'))+" ")]):_vm._e(),(_vm.success)?_c('n-Button',{staticClass:"resetPassword__content-submit",on:{"click":function($event){return _vm.redirect()}}},[_vm._v(_vm._s(_vm.$t('resetPassword.confirmation.backButton')))]):_vm._e()]}}])})],1),_c('div',{staticClass:"resetPassword__img"},[_c('div',{staticClass:"resetPassword__img-content"},[_c('img',{staticClass:"resetPassword__img-content-logo",attrs:{"src":require("@/assets/img/logo/noobizes-manette.png"),"srcset":require("@/assets/img/logo/noobizes-manette@2x.png") + " 2x"}}),_c('h3',{staticClass:"resetPassword__img-content-subtitle"},[_vm._v(_vm._s(_vm.$t('resetPassword.confirmation.catchPhrase.top')))]),_c('h2',{staticClass:"resetPassword__img-content-title"},[_vm._v(_vm._s(_vm.$t('resetPassword.confirmation.catchPhrase.bottom')))]),_c('img',{staticClass:"resetPassword__img-content-coin",attrs:{"src":require("@/assets/img/picto/nbz-coin.svg")}}),_c('img',{staticClass:"resetPassword__img-content-pubg",attrs:{"src":require("@/assets/img/background/right-registerpage.png"),"srcset":require("@/assets/img/background/right-registerpage@2x.png") + " 2x, " + require("@/assets/img/background/right-registerpage@3x.png") + " 3x"}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }