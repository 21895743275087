








































import Vue from 'vue';
import { api } from '@/io/api';
import { auth } from '@/auth/index';
import { config } from '@/env/Configuration';

import { StripeCheckout } from '@vue-stripe/vue-stripe';
import Product from '@/components/Deposit/Product.vue';
import Loader from '@/components/General/Loader.vue';
import Modal from '@/components/Modal/Modal.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'deposit',
  components: {
    Product,
    StripeCheckout,
    Loader,
    Modal,
  },
  data() {
    (this as any).publishableKey = config('stripe.stripePublishableKey');
    return {
      loading: true,
      loadingCheckout: false,
      products: [],
      selectedProduct: null,
    };
  },
  created() {
    this.getProducts();
    if (this.$route.query && this.$route.query.success) {
      if (this.$route.query.success === 'true') {
        this.paymentSuccess();
      } else {
        this.paymentCancel();
      }
    }
  },
  computed: {
    ...mapGetters('gameAccount', {gamesAccounts: 'games'}),
    authId(): string {
      return auth.id().toString();
    },
    authEmail(): string {
      return auth.user().email;
    },
    successURL() {
      return window.location.origin + '/deposit?success=true';
    },
    cancelURL() {
      return window.location.origin + '/deposit?success=false';
    },
    lineItems(): any {
      if (!this.selectedProduct) { return []; }
      const price = this.selectedProduct.price_id;
      return [{ price, quantity: 1 }];
    },
  },
  methods: {
    async getProducts() {
      const products = await api().route('products').get();
      this.loading = false;
      this.products = products.map(this.convertPrice).sort((a, b) => a.price - b.price);
    },

    /**
     * Convert price from cents to unit
     */
    convertPrice(product) {
      product.price = product.price / config('app.nbz.oneNbzPrice');
      return product;
    },
    submit() {
      // You will be redirected to Stripe's secure checkout page
      (this as any).$refs.checkoutRef.redirectToCheckout();
    },
    paymentSuccess() {
      this.$modal.show(
        Modal,
        {
          title: this.$t('deposit.modal.success.title'),
          text: this.$t('deposit.modal.success.text'),
          buttons: [
            {
              title: this.$t('deposit.modal.success.button.createNewChallenge'),
              dark: true,
              handler: () => {
                this.$modal.hideAll();
                this.redirectChallenges();
              },
            },
            {
              title: this.$t('deposit.modal.success.button.matchMaking'),
              dark: false,
              handler: () => {
                this.$modal.hideAll();
                this.redirectMatchMaking();
              },
            },
          ],
        },
        {
          width: '450px',
        },
      );
    },
    paymentCancel() {
      this.$modal.show(
        Modal,
        {
          title: this.$t('deposit.modal.error.title'),
          text: this.$t('deposit.modal.error.text'),
        },
      );
    },
    redirectChallenges() {
      if (this.gamesAccounts.length) {
        const gameSlug = this.gamesAccounts[0]?.game.slug;
        this.$router.push({ name: 'challenges', params: { gameSlug } });
      } else {
        this.$router.push({ name: 'home' });
      }
    },
    redirectMatchMaking() {
      if (this.gamesAccounts.length) {
        const gameSlug = this.gamesAccounts[0]?.game.slug;
        this.$router.push({ name: 'pools', params: { gameSlug } });
      } else {
        this.$router.push({ name: 'home' });
      }
    },
    clearChoice(e) {
      if (!this.selectedProduct) { return; }
      let target: any = e.target;
      while (target) {
        if (this.$refs.product && (this.$refs.product as any).indexOf(target) != -1 || this.$refs.checkoutButton && this.$refs.checkoutButton === target) {
          return;
        }
        target = target.parentElement;
      }
      this.selectedProduct = null;
    },
  },
});
