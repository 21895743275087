
import Vue from 'vue';
import { auth } from '@/auth';
import { add, format, getHours } from 'date-fns';

export default Vue.extend({
  computed: {
    debugOpponent(): any|null {
      if (!(this as any).challenge) { return null; }
      return (this as any).challenge.participants.find((p) => p.user.id != auth.id());
    },
    isAdmin(): boolean {
      return auth.isAdmin();
    },
  },
  methods: {
    hasMatch(participant): boolean {
      if (!participant) { return false; }
      return !!(participant.match ? participant.match.api_id : null);
    },
    challengeTimeout(participant, challenge = null): any {
      const ch = challenge ? challenge : (this as any).challenge ? (this as any).challenge : null;
      if (!ch) { return null; }
      if (!this.hasMatch(participant) && participant) {
        if (participant.timeout) {
          return format(new Date(participant.timeout), 'dd/LL - kk:mm');
        }
        const startDate = participant.start_date ? participant.start_date : ch.start_date;
        if (!startDate) { return 'Not started'; }

        const date = add(new Date(startDate), { days: 1 });
        let hour = getHours(new Date(date));
        if (hour > 4 && hour < 16) {
          hour = 16;
        } else {
          hour = 4;
        }
        date.setUTCHours(hour);
        date.setMinutes(0);
        date.setSeconds(0);
        return format(new Date(date), 'dd/LL - kk:mm');
      }
      return null;
    },
  },

});
