import { make } from '@/app/make';

export function update({challenge}) {
  // update current challenge
  make('store').commit('user/updateChallenge', challenge);
  const currentChallenge = make('store').getters['user/currentChallenge'];
  if (currentChallenge && (currentChallenge.id == challenge.id)) {
    make('store').commit('user/updateCurrentChallenge', challenge);
  }
  const matchMaking = make('store').getters['user/matchMaking'];
  if (matchMaking && (matchMaking.challenge.id == challenge.id)) {
    make('store').commit('user/updateMatchMakingChallenge', challenge);
  }

  // update matches
  const matches = make('store').getters['user/matches'];
  const matchActive = matches.active.find((m) => m.id == challenge.id);
  if (matchActive) {
    if (challenge.state == 'ended') {
      make('store').commit('user/removeMatchActive', challenge);
      make('store').commit('user/addMatchEnded', challenge);
    } else {
      make('store').commit('user/updateMatchActive', challenge);
    }
    return;
  }
  const matchEnded = matches.ended.find((m) => m.id == challenge.id);
  if (matchEnded) {
    make('store').commit('user/updateMatchEnded', challenge);
    return;
  }

  // Matchmaking accepted
  if (challenge.type == 'matchmaking') {
    make('store').commit('user/addMatchActive', challenge);
  }
}
