















import Vue from 'vue';
import { auth } from '@/auth/index';
import { config } from '@/env/Configuration';
import { local } from '@/storage/local';
import { mapGetters } from 'vuex';

import Header from '@/components/App/Header.vue';
import SideMenu from '@/components/App/SideMenu.vue';
import Footer from '@/components/Footer/Footer.vue';
import Loader from '@/components/General/Loader.vue';
import Background from '@/services/backgroundService';

export default Vue.extend({
  components: {
    Header,
    SideMenu,
    Footer,
    Loader,
  },
  mounted() {
    window.addEventListener('focus', this.checkAuthToken);
    this.init();
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('games', ['games']),
    ...mapGetters('games', ['gamesInitialized']),
    ...mapGetters('user', ['matchMaking']),
    ...mapGetters('user', ['matchMakingInitialized']),
    ...mapGetters('gameAccount', ['gamesAccountsInitialized']),
    ...mapGetters('gameAccount', ['isGameConnectProcessing']),
    ...mapGetters('gameAccount', { gamesAccounts: 'games' }),
    ...mapGetters('user', { userChallenges: 'challenges' }),
    ...mapGetters('socket', { socketConnected: 'connected' }),

    isLightMenu(): Boolean {
      if (this.$route.meta.lightMenu) {
        return !!this.$route.meta.lightMenu;
      }
      return false;
    },
    isLargeFooter(): Boolean {
      if (this.$route.meta.largeFooter) {
        return !!this.$route.meta.largeFooter;
      }
      return false;
    },
    showHeader(): Boolean {
      if (this.$route.meta.hideHeader) {
        return !this.$route.meta.hideHeader;
      }
      return true;
    },
    showSideBar(): Boolean {
      if (this.$route.meta.hideSideBar) {
        return !this.$route.meta.hideSideBar;
      }
      return true;
    },
    routeName(): string {
      return this.$route.name;
    },
    isInit(): boolean {
      if (this.showSideBar) {
        return !!(auth.check() && this.gamesInitialized);
      }
      return true;
    },
    slug(): string | null {
      if (this.$route.meta.slug) {
        return this.$route.meta.slug;
      }
      return null;
    },
    isAdmin(): boolean {
      return auth.isAdmin();
    },
  },
  watch: {
    user(newValue, oldValue) {
      if (newValue && oldValue == null) {
        this.init();
      }
    },
    games() {
      this.getMatchMakings();
    },
    gamesAccountsInitialized() {
      this.getMatchMakings();
    },
    matchMaking(newValue, oldValue) {
      if (newValue && newValue.challenge_id) {
        Background.watchMatchMaking(false);
      } else if (newValue && oldValue == null) {
        Background.watchMatchMaking(true, 10000); // 10 sec
      } else if (newValue == null) {
        this.$store.dispatch('auth/credit', null, { root: true });
        Background.watchMatchMaking(false);
      }
    },
    socketConnected(value) {
      if (value == true) {
        Background.clearWatchers();
      } else {
        if (auth.check()) {
          Background.watchNotifications(true);
          if (this.userChallenges.length) {
            const gameId = this.userChallenges[0].game_id;
            Background.watchUserChallenges(true, 10000, gameId);
          }
        }
      }
    },
  },
  methods: {
    async init(): Promise<void> {
      if (auth.check()) {
        this.getGamesList();
        if (!this.gamesAccountsInitialized) {
          await this.getUserGames();
        }
        if (!this.isGameConnectProcessing) {
          this.getGameConnectJob();
        }
      }
    },
    getGamesList(): void {
      this.$store.dispatch('games/list');
    },
    getUserGames(): void {
      this.$store.dispatch('gameAccount/games');
    },
    getMatchMakings() {
      if (this.games?.length && !this.matchMakingInitialized && this.gamesAccountsInitialized) {
        this.games.forEach(game => {
          const connected = this.gamesAccounts.find(ga => ga.game_id == game.id);
          if (connected) {
            this.getMatchMaking(game.id);
          }
        });
      }
    },
    getMatchMaking(gameId) {
      this.$store.dispatch('user/getMatchMaking', { gameId }).catch(err => {
        console.log(err);
      });
    },
    getGameConnectJob() {
      this.$store.dispatch('gameAccount/getGameConnectJob').catch(err => {
        console.log(err);
      });
    },
    checkAuthToken() {
      if (!auth.token()) {
        return;
      }
      if (auth.token() != local.getItem('user')?.api_token) {
        location.reload();
      }
    },
  },
});
