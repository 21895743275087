import { api } from '@/io/api';

interface IState {
    challenges: any[]; // Challenges created by current user and not started by him yet
    currentChallenge: any; // Challenge currently displayed on page
    matchMaking: any;
    matches: {
        active: object[], // Challenges running for current user (has his participants created)
        ended: object[], // Challenges ended for current user
    };
    matchMakingInitialized: boolean;
    matchesActiveInitialized: boolean;
    matchesEndedInitialized: boolean;
    error: any | null;
}
export const state: IState = {
    challenges: [],
    currentChallenge: null,
    matchMaking: null,
    matches: {
        active: [],
        ended: [],
    },
    matchMakingInitialized: false,
    matchesActiveInitialized: false,
    matchesEndedInitialized: false,
    error: null,
};
export const getters = {
    challenges(state: IState) {
        return state.challenges;
    },
    currentChallenge(state: IState) {
        return state.currentChallenge;
    },
    matchMaking(state: IState) {
        return state.matchMaking;
    },
    matches(state: IState) {
        return state.matches;
    },
    matchMakingInitialized(state: IState) {
        return state.matchMakingInitialized;
    },
    matchesActiveInitialized(state: IState) {
        return state.matchesActiveInitialized;
    },
    matchesEndedInitialized(state: IState) {
        return state.matchesEndedInitialized;
    },
    error(state: IState) {
        return state.error;
    },
};
export const actions = {

    /**
     * Recover all challenges created by auth user (and not played by him yet)
     * @param gameId game id
     */
    async getChallengesCreated({  commit  }: any, { gameId }: { gameId: number }) {
        commit('clearError');
        try {
            console.log('getChallengesCreated');
            if (!gameId) {
                return;
            }
            const challenges = await api().route('/challenges/user').params({ game_id: gameId }).get();
            commit('setChallenges', challenges);
        } catch (error) {
            commit('setError', error.message);
            return Promise.reject(error);
        }
    },

    /**
     * Recover all challenges running for auth user
     * @param game game object
     */
    async getMatchesActive({ commit }: any, { game }: { game: any }) {
        commit('clearError');
        try {
            const challenges = await api().route('challenges/started').params({ game_id: game.id }).get();
            commit('setMatchesActive', challenges);
            commit('setMatchesActiveInitialized', true);
            return challenges;
        } catch (error) {
            commit('setError', error.message);
            commit('setMatchesActiveInitialized', true);
            return Promise.reject(error);
        }
    },

    /**
     * Recover all challenges ended for auth user, ended by both players
     * @param game game object
     * @param limit total matches to retrieved
     * @param page page to display
     */
    async getMatchesEnded({ commit }: any, { game, limit, page }: { game: any, limit: number, page: number }) {
        commit('clearError');
        try {
            const res = await api().route('challenges/history').params({ game_id: game.id, limit, page }).get();
            commit('setMatchesEnded', res.challenges);
            commit('setMatchesEndedInitialized', true);
            return res;
        } catch (error) {
            commit('setError', error.message);
            commit('setMatchesEndedInitialized', true);
            return Promise.reject(error);
        }
    },

    /**
     * Create a new challenge
     * @param gameId
     * @param modeId
     * @param bet
     * @param platformId
     * @param type type challenge or matchmaking
     */
    async createChallenge({ commit, dispatch }: any, { gameId, modeId, bet, platformId, type  }: { gameId: number, modeId: number, bet: number,  platformId: number, type: string }) {
        commit('clearError');
        try {
            const challenge = await api().route('/challenge/create').params({ game_id: gameId, bet, mode_id: modeId, platform_id: platformId, type }).post();
            commit('addChallenge', challenge);
            dispatch('auth/credit', null, { root: true });
            return;
        } catch (error) {
            commit('setError', error.message);
            dispatch('auth/credit', null, { root: true });
            return Promise.reject(error);
        }
    },

    /**
     * Delete its challenge created (only challenge with state 'created')
     * @param gameId
     * @param challengeId
     */
    async deleteChallenge({ commit, dispatch }: any, { gameId, challengeId }: { gameId: number, challengeId: number }) {
        try {
            console.log('delete challenge id', challengeId);
            await api().route('challenge/delete').params({ game_id: gameId, challenge_id: challengeId }).delete();
            commit('removeChallenge', challengeId);
            dispatch('auth/credit', null, { root: true });
        } catch (error) {
            commit('setError', error.message);
            dispatch('auth/credit', null, { root: true });
            return Promise.reject(error);
        }
    },

    /**
     * Accept a challenge from other player
     * @param gameId
     * @param challengeId
     */
    async acceptChallenge({ commit, dispatch }: any, { gameId, challengeId }: { gameId: number, challengeId: number }) {
        try {
            console.log('accept challenge id', challengeId);
            const data = await api().route('challenge/accept').params({ game_id: gameId, challenge_id: challengeId }).post();
            if (!data) { throw { message : 'error.server' }; }
            commit('challenges/removeChallenge', data, { root: true });
            commit('addMatchActive', data);
            dispatch('auth/credit', null, { root: true });
            return data;
        } catch (error) {
            commit('setError', error.message);
            if (error.message == 'challenge.error.notAvailable') {
                commit('challenges/removeChallenge', challengeId, { root: true });
            }
            dispatch('auth/credit', null, { root: true });
            return Promise.reject(error);
        }
    },

    /**
     * Start a challenge
     * @param gameId
     * @param challengeId
     */
    async startChallenge({ commit }: any, { gameId, challengeId, platformId }: { gameId: number, challengeId: number, platformId: number }) {
        try {
            console.log('start challenge id', challengeId);
            const data = await api().route('challenge/start').params({ game_id: gameId, challenge_id: challengeId, platform_id: platformId }).post();
            if (!data) { throw { message : 'error.server' }; }
            commit('removeChallenge', data.id);
            commit('addMatchActive', data);
            commit('updateCurrentChallenge', data);
            if (data.type == 'matchmaking') {
                commit('updateMatchMakingChallenge', data);
            }
            if (state.matchMaking && (state.matchMaking.challenge_id == challengeId)) {
                commit('updateMatchMakingChallenge', data);
            }
            return data;
        } catch (error) {
            commit('setError', error.message);
            return Promise.reject(error);
        }
    },

    /**
     * End a challenge
     * @param gameId
     * @param challengeId
     */
    async endChallenge({ state, commit }: any, { gameId, challengeId }: { gameId: number, challengeId: number }) {
        try {
            console.log('end challenge id', challengeId, gameId);
            const data = await api().route('challenge/end').params({ game_id: gameId, challenge_id: challengeId }).post();
            if (!data) { throw { message : 'error.server' }; }
            commit('updateCurrentChallenge', data);
            if (data.state == 'ended') {
                commit('removeMatchActive', data);
                commit('addMatchEnded', data);
            } else {
                commit('updateMatchActive', data);
            }
            if (data.type == 'matchmaking') {
                commit('setMatchMaking', null);
            }
            if (state.matchMaking && (state.matchMaking.challenge_id == challengeId)) {
                commit('setMatchMaking', null);
            }
            return data;
        } catch (error) {
            commit('setError', error.message);
            return Promise.reject(error);
        }
    },

    /**
     * Get running matchmaking
     * @param gameId
     * @param id matchmaking id
     */
    async getMatchMaking({ commit, getters }: any, { gameId, id = null }: { gameId: number, id: number|null }) {
        console.log('getMatchMaking', {id});
        const isInit = getters.matchMakingInitialized;
        try {
            const matchMaking = await api().route('/matchMaking').params({ game_id: gameId, id }).get();
            if (matchMaking) {
                commit('setMatchMaking', matchMaking);
            } else {
                commit('setMatchMaking', null);
            }
            if (!isInit) {
                commit('setMatchMakingInitialized', true);
            }
            return matchMaking;
        } catch (error) {
            commit('setMatchMaking', null);
            if (!isInit) {
                commit('setMatchMakingInitialized', true);
            }
            return Promise.reject(error);
        }
    },

    /**
     * Start the matchmaking request
     * @param gameId
     * @param bet
     * @param platformId
     */
    async startMatchMaking({ commit, dispatch }: any, { gameId, bet, platformId }: { gameId: number, bet: number, platformId: number }) {
        try {
            const matchMaking = await api().route('/matchMaking/start').params({ game_id: gameId, bet, platform_id: platformId  }).post();
            if (matchMaking) {
                const challenge = matchMaking.challenge;
                commit('setMatchMaking', matchMaking);
                if (challenge) {
                    commit('addMatchActive', challenge);
                }
                dispatch('auth/credit', null, { root: true });
            }
            return matchMaking;

        } catch (error) {
            commit('setError', error.message);
            dispatch('auth/credit', null, { root: true });
            return Promise.reject(error);
        }
    },

    /**
     * Cancel the matchmaking request, only if no opponent has been found
     * @param gameId
     */
    async deleteMatchMaking({ commit, getters, dispatch }: any, { gameId }: { gameId: number }) {
        try {
            const currentMatchMaking = getters.matchMaking;
            if (!currentMatchMaking) {
                return;
            }
            const matchMakingId = currentMatchMaking.id;
            const matchMaking = await api().route('/matchMaking/delete' ).params({ game_id: gameId, id: matchMakingId }).delete();
            if (!matchMaking) {
                commit('setMatchMaking', null);
            } else {
                // Matchmaking has been accepted by opponent before deleting
                commit('setMatchMaking', matchMaking);
                commit('addMatchActive', matchMaking.challenge);
            }
            dispatch('auth/credit', null, { root: true });
        } catch (error) {
            commit('setError', error.message);
            dispatch('auth/credit', null, { root: true });
            return Promise.reject(error);
        }
    },
};
export const mutations = {
    setChallenges(state: IState, challenges: any) {
        state.challenges = challenges;
    },
    updateChallenge(state: IState, challenge: any) {
        const index = state.challenges.findIndex((c: any) => c.id == challenge.id );
        if (index > -1) {
            state.challenges.splice(index, 1, challenge);
        }
    },
    addChallenge(state: IState, challenge: any) {
        state.challenges.push(challenge);
    },
    removeChallenge(state: IState, challengeId: number) {
        const index = state.challenges.findIndex((c: any) => c.id == challengeId );
        if (index > -1) {
            state.challenges.splice(index, 1);
        }
    },
    setCurrentChallenge(state: IState, challenge: any) {
        console.log('setCurrentChallenge', challenge);
        state.currentChallenge = challenge;
    },
    updateCurrentChallenge(state: IState, challenge: any) {
        console.log('updateCurrentChallenge', challenge);
        if (!state.currentChallenge) { return; }
        if (state.currentChallenge.id == challenge.id) {
            state.currentChallenge = challenge;
        }
    },
    clearCurrentChallenge(state: IState) {
        state.currentChallenge = null;
    },
    setMatchMaking(state: IState, matchMaking: any) {
        state.matchMaking = matchMaking;
    },
    updateMatchMakingChallenge(state: IState, challenge: any) {
        state.matchMaking.challenge = challenge;
    },
    removeMatchMaking(state: IState) {
        state.matchMaking = null;
    },
    setMatches(state: IState, challenges: any) {
        state.matches = challenges;
    },
    setMatchesActive(state: IState, challenges: any) {
        state.matches.active = challenges;
    },
    setMatchesEnded(state: IState, challenges: any) {
        state.matches.ended = challenges;
    },
    addMatchActive(state: IState, challenge: any) {
        const index = state.matches.active.findIndex((el: any) => el.id == challenge.id);
        if (index > -1) {
            state.matches.active.splice(index, 1, challenge);
        } else {
            state.matches.active.unshift(challenge);
        }
    },
    updateMatchActive(state: IState, challenge: any) {
        const index = state.matches.active.findIndex((el: any) => el.id == challenge.id);
        if (index > -1) {
            state.matches.active.splice(index, 1, challenge);
        }
    },
    removeMatchActive(state: IState, challenge: any) {
        const index = state.matches.active.findIndex((el: any) => el.id == challenge.id);
        if (index > -1) {
            state.matches.active.splice(index, 1);
        }
    },
    addMatchEnded(state: IState, challenge: any) {
        state.matches.ended.unshift(challenge);
    },
    updateMatchEnded(state: IState, challenge: any) {
        const index = state.matches.ended.findIndex((el: any) => el.id == challenge.id);
        if (index > -1) {
            state.matches.ended.splice(index, 1, challenge);
        }
    },
    setMatchMakingInitialized(state, value) {
        state.matchMakingInitialized = value;
    },
    setMatchesActiveInitialized(state, value) {
        state.matchesActiveInitialized = value;
    },
    setMatchesEndedInitialized(state, value) {
        state.matchesEndedInitialized = value;
    },
    clearStore(state) {
        state.challenges = [];
        state.currentChallenge = null;
        state.matchMaking = null;
        state.matches = {
            active: [],
            ended: [],
        };
        state.matchMakingInitialized = false;
        state.matchesActiveInitialized = false;
        state.matchesEndedInitialized = false;
        state.error = null;
    },
    setError(state, message) {
        state.error = message;
    },
    clearError(state) {
        state.error = null;
    },
    clearChallenges(state: IState) {
        state.challenges = [];
    },
    clearMatchMaking(state: IState) {
        state.matchMaking = null;
        state.matchMakingInitialized = false;
    },
};
export const namespaced = true;
