





























































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { auth } from '@/auth/index';
import Debug from '@/components/Mixins/Debug.vue';

export default Vue.extend({
  name: 'players',
  mixins: [ Debug ],
  props: {
    challenge: {
      type: Object,
      required: false,
    },
    game: {
      type: Object,
      required: true,
    },
  },
  computed : {
    ...mapGetters('auth', ['user']),

    userParticipant(): any {
      if (!this.challenge) { return null; }
      if (!this.challenge.participants) {
        return null;
      }
      return this.challenge.participants.find((p) => p.user_id == auth.id());
    },
    opponent(): any {
      if (this.opponentParticipant) { return this.opponentParticipant.user; }
      return null;
    },
    opponentParticipant(): any {
      if (!this.challenge) { return null; }
      if (!this.challenge.participants) {
        return null;
      }
      return this.challenge.participants.find((p) => p.user_id != auth.id());
    },
    userState(): any {
      return this.matchState(this.userParticipant);
    },
    opponentState(): any {
      return this.matchState(this.opponentParticipant);
    },
    isActiveChallenge(): boolean {
      if (!this.challenge) { return false; }
      return !!(this.challenge.state != 'closed' && this.challenge.state != 'timeout');
    },

  },
  methods: {
    matchState(participant): any {
      if (!participant) {
        return this.$t('match.player.state.notStarted');
      }
      if (participant.timeout) {
        return this.$t('match.player.state.timeout');
      }
      if (participant.end_date) {
        return this.$t('match.player.state.ended');
      }
      if (participant.start_date) {
        return this.$t('match.player.state.playing');
      }
      return this.$t('match.player.state.notStarted');
    },
  },
});
