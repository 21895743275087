import { make } from '@/app/make';

class Background {

    public matchMaking = { interval: null, delay: null };
    public userChallenges = { interval: null, delay: null };
    public notifications = { interval: null, delay: null };

    constructor() {
        //
    }

    public watchMatchMaking(isActive, delay = 10000) {
        const socket = make('store').getters['socket/connected'];
        if (socket) {
            if (this.matchMaking.interval) {
                this.clearWatchInterval(this.matchMaking);
            }
            return;
        }
        console.log('watchMatchMaking', isActive, delay);
        if (isActive) {
            const matchMaking = make('store').getters['user/matchMaking'];
            if (this.matchMaking.interval) {
                this.clearWatchInterval(this.matchMaking);
            }
            this.matchMaking.delay = delay;
            this.matchMaking.interval = setInterval(() => {
                make('store').dispatch('user/getMatchMaking', {
                    id: matchMaking.id,
                    gameId: matchMaking.game_id,
                })
                .catch((err) => {
                    this.clearWatchInterval(this.matchMaking);
                });
            }, delay);
        } else {
            this.clearWatchInterval(this.matchMaking);
        }
    }

    public watchUserChallenges(isActive, delay = 10000, gameId = null) {
        const socket = make('store').getters['socket/connected'];
        if (socket) {
            if (this.userChallenges.interval) {
                this.clearWatchInterval(this.userChallenges);
            }
            return;
        }
        console.log('watchUserChallenges', isActive, delay, gameId);
        if (isActive) {
            if (this.userChallenges.interval) {
                this.clearWatchInterval(this.userChallenges);
            }
            this.userChallenges.delay = delay;
            this.userChallenges.interval = setInterval(() => {
                make('store').dispatch('user/getChallengesCreated', {
                    gameId,
                }).catch((err) => {
                    this.clearWatchInterval(this.userChallenges);
                });
            }, delay);
        } else {
            this.clearWatchInterval(this.userChallenges);
        }
    }

    public watchNotifications(isActive, delay = 60000) {
        const socket = make('store').getters['socket/connected'];
        if (socket) {
            if (this.notifications.interval) {
                this.clearWatchInterval(this.notifications);
            }
            return;
        }
        console.log('watchNotifications', isActive, delay);
        if (isActive) {
            if (this.notifications.interval) {
                this.clearWatchInterval(this.notifications);
            }
            this.notifications.delay = delay;
            this.notifications.interval = setInterval(() => {
                const lastNotification = make('store').getters['notifications/notifications'][0];
                let lastDate = null;
                if (lastNotification) {
                    lastDate = lastNotification.created_at;
                }
                make('store').dispatch('notifications/getNews', { lastDate })
                .catch((err) => {
                    this.clearWatchInterval(this.notifications);
                });
            }, delay);
        } else {
            this.clearWatchInterval(this.notifications);
        }
    }

    public clearWatchers() {
        if (this.matchMaking.interval) {
            this.clearWatchInterval(this.matchMaking);
        }
        if (this.notifications.interval) {
            this.clearWatchInterval(this.notifications);
        }
        if (this.userChallenges.interval) {
            this.clearWatchInterval(this.userChallenges);
        }
    }
    public clearWatchInterval(watcher) {
        if (watcher.interval) {
            clearInterval(watcher.interval);
            watcher.interval = null;
            watcher.delay = null;
        }
    }
}

export default new Background();
