





























import Vue from 'vue';
import { api } from '@/io/api';
import { mapGetters, mapMutations } from 'vuex';
import IconBell from '@/components/Icons/Bell.vue';
import Loader from '@/components/General/Loader.vue';
import { config } from '@/env/Configuration';
import ChallengeJoin from '@/components/Notifications/ChallengeJoin.vue';
import ChallengeResults from '@/components/Notifications/ChallengeResults.vue';
import Info from '@/components/Notifications/Info.vue';

export default Vue.extend({
  name: 'notifications-list',
  components: {
    IconBell,
    Loader,
    'challenge-join': ChallengeJoin,
    'challenge-results': ChallengeResults,
    'info': Info,
  },
  data() {
    return {
      active: false,
      readAll: false,
      loading: false,
    };
  },
  created() {
    this.getNotifications();
  },
  watch: {
    readAll() {
      this.markAllAsRead();
    },
  },
  computed: {
    ...mapGetters('notifications', ['notifications', 'count']),
  },
  methods: {
    ...mapMutations('notifications', ['updateAll', 'setCount']),
    getNotifications(): void {
      this.$store.dispatch('notifications/get', {offset: 0, limit: config('app.nbz.limitNextNotifications')});
    },
    async markAllAsRead(): Promise<void> {
      (this as any).updateAll();
      (this as any).setCount(0);
      await api().route('notifications/update').put();
    },
    checkScroll(): void {
      const scrollableList: any = this.$refs.scrollable;
      if (!scrollableList) { return; }
      if (scrollableList.scrollTop + scrollableList.clientHeight >= scrollableList.scrollHeight) {
        this.loadMore();
      }
    },
    async loadMore(): Promise<void> {
      this.loading = true;
      await this.$store.dispatch('notifications/getNext', {offset: this.notifications.length, limit: null});
      this.loading = false;
    },
    closeMenu(): void {
      this.active = false;
    },
  },
});
