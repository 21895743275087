









import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'n-Ratio',
})
export default class Ratio2 extends Vue {
  /**
   * User game number of win
   */
  @Prop({
    type: Number,
    required: true,
  })
  public win: number;
  /**
   * User game number of lose
   */
  @Prop({
    type: Number,
    required: true,
  })
  public lose: number;

}
