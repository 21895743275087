import { env } from '@/env/env';


export default {
    environment: env(process.env.VUE_APP_ENVIRONMENT, 'local'),
    name: env(process.env.VUE_APP_NAME, 'noobizes'),
    debug: env(process.env.VUE_APP_DEBUG, false),
    nbz: {
        commission: parseFloat(env(process.env.VUE_APP_NBZ_COMMISSION, 0.1)),
        limitChallenges: parseFloat(env(process.env.VUE_APP_LIMIT_CHALLENGES, 4)),
        limitNextNotifications: parseFloat(env(process.env.VUE_APP_LIMIT_NEXT_NOTIFICATIONS, 10)),
        matchMaking: {
            timeout: parseInt(env(process.env.VUE_APP_MATCHMAKING_TIMEOUT, 300000), 10),
        },
        activePayment: env(process.env.VUE_APP_ACTIVE_PAYMENT, false),
        oneNbzPrice: env(process.env.VUE_APP_ONE_NBZ_PRICE, 100),
    },
};
