


































import Vue from 'vue';

const search = { mode: '', bet: '', name: null };

export default Vue.extend({
  name: 'search-challenge',
  props: {
    modes: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      betRanges: [
        { value: { min: 0, max: 200 } },
        { value: { min: 200, max: 500 } },
        { value: { min: 500, max: 750 } },
        { value: { min: 750, max: 1000 } },
        { value: { min: 1000, max: null } },
      ],
      search: Object.assign({}, search),
      currentSearch: null,
      isCleared: true,
    };
  },
  methods: {
    clear() {
      this.search = Object.assign({}, search);
      if (!this.isCleared && this.currentSearch) {
        this.getChallenges();
        this.isCleared = true;
      }
    },
    getChallenges() {
      this.$emit('search', this.search);
      if (this.isReset) {
        this.currentSearch = null;
      } else {
        this.currentSearch = this.search;
      }
    },
  },
  watch: {
    search: {
      handler() {
        if (!this.isReset) {
          this.isCleared = false;
        }
      }, deep: true,
    },
  },
  computed : {
    isReset(): boolean {
      return !!(this.search.mode == search.mode && this.search.bet == search.bet && this.search.name == search.name);
    },
  },
});
