
















import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import InputLabel from '../../LabelElements/InputLabel/InputLabel.vue';
import * as vClickOutside from 'v-click-outside';
Vue.use(vClickOutside);

@Component({
  components: {
    InputLabel,
  },
  name: 'n-InputSelect',
})
export default class InputSelect extends Vue {
  /** Boolean pour afficher les options du select */
  public isActive: boolean = false;

  public value: string | null = null;

  public curPlaceholder: string = '';

  public vcoConfig = {
    handler: this.clickOutsideHandler,
    middleware: this.clickOutsideMiddleware,
    isActive: true,
  };

  /**
   * Label de l'input placé haut dessus
   */
  @Prop({
    type: String,
    required: true,
    default: 'label',
  })
  public label!: string;

  /**
   * Nom de l'input permettant de lié le label à l'input
   */
  @Prop({
    type: String,
    required: true,
    default: 'name',
  })
  public name!: string;

  /**
   * Placeholder de l'input
   */
  @Prop({
    type: String,
    required: true,
    default: 'placeholder',
  })
  public placeholder!: string;

  /**
   * Permet de définir si l'input est requis ou non
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public required!: boolean;

  /**
   * Can set an error message at bottom of select
   */
  @Prop({
    type: String,
    required: false,
  })
  private readonly error!: string;

  public mounted() {
    this.curPlaceholder = this.placeholder;
  }

  get compIsActive(): string {
    return this.isActive ? 'active' : '';
  }

  get compHasValue(): string {
    return this.value ? 'hasValue' : '';
  }

  public clickOutsideHandler(): void {
    if (this.isActive) {
      this.isActive = false;
    }
  }

  public clickOutsideMiddleware(event: Event): boolean {
    return (event.target as HTMLTextAreaElement).className !== 'input-select--option';
  }

  public selectValue(val: string, label: string): void {
    this.curPlaceholder = label;
    this.value = val;
    this.isActive = false;
    this.selectedValue(val, label);
  }

  @Emit('selectedValue')
  public selectedValue(val: string, label: string): void {
    //
  }
}
