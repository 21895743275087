















import Vue from 'vue';
import Facebook from '@/components/Icons/SocialNetworks/Facebook.vue';
import Twitter from '@/components/Icons/SocialNetworks/Twitter.vue';
import Twitch from '@/components/Icons/SocialNetworks/Twitch.vue';
import Instagram from '@/components/Icons/SocialNetworks/Instagram.vue';
import Discord from '@/components/Icons/SocialNetworks/Discord.vue';

export default Vue.extend({
  name: 'footer-networks',
  components: {
    Facebook,
    Twitter,
    Twitch,
    Instagram,
    Discord,
  },
  props: {
    color: {
      type: String,
      required: false,
      default: '#d8d8d8',
    },
  },
});
