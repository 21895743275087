import { isClass } from '../utils/Classes';

const factory = {};
/**
 * Retrieve instance/create instance from factory
 * @param {String} name Instance name
 */
export function make(name) {
    const value = factory[name];
    if (typeof value != 'function') {
    return value; // instance or null
  }
    if (isClass(value)) {
        return new value;
    }
    return value();
}
/**
 * Register instance into the factory
 * @param {String} name Name of instance
 * @param {*|Function|Class} value Register value to the factory. If value is a class it will be instanciated, if it is a function is will be called
 */
export function register(name, value) {
  factory[name] = value;
}

// debug purpose
globalThis.make = make;
