import VueI18n from 'vue-i18n';
import Vue from 'vue';
import * as messages from '@/i18n';
import { register } from './make';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: navigator.language.split('-')[0],
  fallbackLocale: 'en',
  silentFallbackWarn: true,
  messages, // set locale messages
});

register('i18n', i18n);

export default i18n;
