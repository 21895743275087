

































import Vue from 'vue';
import Loader from '@/components/General/Loader.vue';
import { mapGetters } from 'vuex';
import { compressFile } from '@/utils/File';

export default Vue.extend({
  name: 'account',
  components: {
    Loader,
  },
  data() {
    return {
      avatar: null,
      fileLoading: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  methods: {
    async fileUpload() {
      this.fileLoading = true;
      this.avatar = (this.$refs.avatar as any).files[0];
      const avatarMaxWidth = 112;

      try {
        const avatarCompressed = await compressFile(this.avatar, avatarMaxWidth);
        await this.$store.dispatch('auth/update', { form: { avatar: avatarCompressed } });
      } catch (error) {
        let err = 'error.server';
        if (error.fatal) {
          err = error.message;
        } else if (error.response && error.response.statusText == 'Request Entity Too Large') {
          err = 'settings.account.error.entityTooLarge';
        }
        Vue.$toast.open({
          message: this.$t(err) as string,
          type: 'error',
          duration: 5000,
        });
      }
      this.fileLoading = false;
    },
  },
});
