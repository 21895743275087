






































































import Vue from 'vue';
import { config } from '@/env/Configuration';
import { mapGetters, mapMutations } from 'vuex';

import loadGame from '@/components/Mixins/loadGame.vue';
import Players from '@/components/Match/Players.vue';
import Dotations from '@/components/Match/Dotations.vue';
import MatchInfos from '@/components/Match/MatchInfos.vue';
import MatchMakingRules from '@/components/Rules/MatchMakingRules.vue';
import Loader from '@/components/General/Loader.vue';


export default Vue.extend({
    name: 'match-making',
    components: { Players, Dotations, MatchInfos, MatchMakingRules, Loader },
    mixins: [loadGame],
    props: {
      gameSlug: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        interval: null,
        timer: null,
        bet: null,
        platformId: null,
        modeId: null,
        game: null, // on mixin gameLoaded
        isInit: false,
        isStopped: false,
      };
    },
    created() {
      this.init();
    },
    beforeDestroy() {
      this.clearTimer();
    },
    computed: {
      ...mapGetters('user', ['matchMaking']),
      matchMakingTitle(): any {
        if (!this.isActive) {
          return this.$t('matchMaking.stopped');
        } else {
          return this.$t('matchMaking.running');
        }
      },
      mode(): any {
        if (!this.modeId) { return; }
        return this.game.modes.find((m) => m.id == this.modeId);
      },
      platform(): any {
        if (!this.modeId) { return; }
        return this.game.platforms.find((p) => p.id == this.platformId);
      },
      timeout(): number {
        return config('app.nbz.matchMaking.timeout');
      },
      timeoutTimestamp(): any {
        if (!this.matchMaking) { return null; }
        return (new Date(this.matchMaking.created_at)).getTime() + this.timeout;
      },
      hasChallenge(): boolean {
        if (!this.matchMaking) { return false; }
        return !!this.matchMaking.challenge_id;
      },
      isActive() {
        if (!this.matchMaking) { return false; }
        const timeout =  Math.floor((this.timeoutTimestamp - Date.now()));
        if (timeout > 0 && !this.hasChallenge) {
          return true;
        }
        return false;
      },
      loading(): Boolean {
        return !this.isInit;
      },
    },
    watch : {
      matchMaking(newValue, oldValue) {
        if (!newValue) { return; }
        if (!this.hasChallenge) {
          if (!oldValue || (oldValue.id != newValue.id)) {
            this.setMatchMakingInfos(parseInt(newValue.bet, 10), newValue.platform_id, newValue.mode_id);
          }
        } else {
          this.redirectMatch(this.matchMaking.challenge);
        }
      },
      isActive(value) {
        if (value) {
          if (!this.interval) {
            this.interval = setInterval(() => {
              this.tickUp();
            }, 1000);
          }
        }
      },
      isInit() {
        if (!this.matchMaking) {
          this.redirectPools();
        }
      },
    },
    methods: {
      ...mapMutations('auth', ['updateCredit']),
      async init() {
        console.log('matchMaking: Init');
        if (this.matchMaking) {
          this.setMatchMakingInfos(this.matchMaking.bet, this.matchMaking.platform_id, this.matchMaking.mode_id);
          try {
            await this.$store.dispatch('user/getMatchMaking', { id: this.matchMaking.id, gameId: this.game.id });

            if (this.isActive) {
              if (!this.interval) {
                this.interval = setInterval(() => {
                  this.tickUp();
                }, 1000);
              }
            }
          } catch (e) {
            this.clearTimer();
          }
        }
        console.log('isAstive->', this.isActive);
        this.isInit = true;
      },
      async restartMatchMaking() {
        if (this.isStopped) {
          this.isStopped = false;
          (this as any).updateCredit({ amount: -this.bet });
          const matchMaking = await this.$store.dispatch('user/startMatchMaking', {
            gameId: this.game.id,
            bet: this.bet,
            platformId: this.platformId,
          });

          if (matchMaking.challenge) {
            console.log('MM: start redirect');
            this.redirectMatch(matchMaking.challenge);
          }
        }
      },

      stopMatchMaking() {
        if (!this.isStopped) {
          this.isStopped = true;
          console.log('matchMaking: stop');
          (this as any).updateCredit({ amount: this.bet });
          this.$store.dispatch('user/deleteMatchMaking', { gameId: this.game.id });
          this.timer = null;
          this.clearTimer();
        }
      },

      tickUp() {
        let seconds: any = Math.floor((this.timeoutTimestamp - Date.now()) / 1000);
        if (seconds <= 0) {
          seconds = 0;
          this.stopMatchMaking();
          return;
        }
        seconds = Math.floor((this.timeout / 1000) - seconds);

        let minutes: any = Math.floor(seconds / 60);
        seconds = seconds - (minutes * 60);

        if (seconds < 10) {
          seconds = '0' + seconds.toString();
        }
        minutes = '0' + minutes.toString();
        this.timer = minutes + ' : ' + seconds;
      },

      clearTimer() {
        if (this.interval) {
          clearInterval(this.interval);
          this.interval = null;
        }
      },

      setMatchMakingInfos(bet, platformId, modeId) {
        this.bet = parseInt(bet, 10);
        this.platformId = platformId;
        this.modeId = modeId;
      },

      redirectChallenges() {
        this.$router.push({ name: 'challenges', params: { gameSlug: this.gameSlug }});
      },
      redirectPools() {
        this.$router.push({ name: 'pools', params: { gameSlug: this.gameSlug }});
      },
      redirectMatch(challenge) {
        console.log('MM: redirect match');
        console.log('MM: challenge', challenge);
        this.$router.push({ name: 'match', params: { gameSlug: this.gameSlug, challengeId: challenge.uuid, currentChallenge: challenge }});
      },

    },
});
