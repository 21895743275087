











import Vue from 'vue';
import marked from 'marked';
import { api } from '@/io/api';
import Loader from '@/components/General/Loader.vue';
import Header from '@/components/App/Header.vue';

export default Vue.extend({
  name: 'terms',
  components: {
    Loader,
    Header,
  },
  data() {
    return {
      content: null,
      loading: true,
    };
  },
  created() {
    this.getFile();
  },
  watch : {
    $route : {
      handler() {
        this.getFile();
      },
    },
  },
  computed: {
    termsType() {
      return this.$route.meta.terms;
    },
    terms(): any {
      if (this.content) {
        return marked(this.content);
      }
      return null;
    },
  },
  methods: {
    async getFile() {
      this.loading = true;
      this.content = await api().route('/terms').params({ type: this.termsType }).get();
      this.loading = false;
    },
  },

});
