






import Vue from 'vue';

export default Vue.extend({
  name: 'loader',
  props: {
    width: {
      type: Number,
      required: false,
      default: 50,
    },
    borderWidth: {
      type: Number,
      required: false,
      default: 4,
    },
    color: {
      type: String,
      required: false,
      default: '#f8460a', // $primary
    },
    backgroundColor: {
      type: String,
      required: false,
      default: '#202227', // $grey_dark
    },
  },
  computed: {
    style() {
      return `
        width: ${this.width}px;
        height: ${this.width}px;
        border: ${this.borderWidth}px solid ${this.backgroundColor};
        border-top-color: ${this.color};
      `;
    },
  },
});
