export default {
  router: {
    landing: {
      title: 'Noobizes | Plateforme Esports',
      desc: ``,
    },
    register: {
      title: `S'inscrire sur Noobizes`,
      desc: ``,
    },
    login: {
      title: `Connectez-vous sur Noobizes`,
      desc: ``,
    },
    forgotpassword: {
      title: `Demande de réinitialisation du mot de passe - Noobizes`,
      desc: ``,
    },
    resetpassword: {
      title: `Réinitialisation du mot de passe - Noobizes`,
      desc: ``,
    },
    cgu: {
      title: `Conditions générales d'utilisation - Noobizes`,
      desc: ``,
    },
    cgv: {
      title: `Conditions générales de ventes - Noobizes`,
      desc: ``,
    },
    privacy: {
      title: `Politique de confidentialité - Noobizes`,
      desc: ``,
    },
    home: {
      title: `Accueil - Noobizes`,
      desc: ``,
    },
    games: {
      title: `Jeux disponibles - Noobizes`,
      desc: ``,
    },
    ranking: {
      title: `Classement - Noobizes`,
      desc: ``,
    },
    store: {
      title: `Boutique - Noobizes`,
      desc: ``,
    },
    challenges: {
      title: `Défis - Noobizes`,
      desc: ``,
    },
    matches: {
      title: `Mes matches - Noobizes`,
      desc: ``,
    },
    match: {
      title: `Match - Noobizes`,
      desc: ``,
    },
    matchmaking: {
      title: `Matchmaking - Noobizes`,
      desc: ``,
    },
    pools: {
      title: `Pools matchmaking - Noobizes`,
      desc: ``,
    },
    settings: {
      title: `Paramètres - Noobizes`,
      desc: ``,
    },
    updatepassword: {
      title: `Réinitialisation du mot de passe - Noobizes`,
      desc: ``,
    },
    gamesettings: {
      title: `Associez vos jeux préférés - Noobizes`,
      desc: ``,
    },
    deposit: {
      title: `Dépôt - Noobizes`,
      desc: ``,
    },
  },
  error: {
    server: "Une erreur s'est produite",
    lowCredit: `Votre crédit est insuffisant`,
    banned: `Votre compte a été banni. Contactez le support`,
  },
  accessKey: {
    error: {
      account_already_exists: `Un compte est déjà associé à cet email`,
      too_many_user: `Nous sommes désolés mais trop de joueurs sont actuellement inscrits. Veuillez attendre la prochaine étape de l'alpha.`,
    },
    success: {
      user: `Un email vous a été envoyé avec votre clé !`,
      invite: "Un email d'invitation a été envoyer !",
    },
  },
  login: {
    error: {
      wrong_username_password: "Le couple identifiant/mot de passe n'est pas valide",
      banned: `Votre compte a été banni. Contactez le support`,
    },
  },
  signIn: {
    title: `Se connecter à Noobizes`,
    username: {
      label: `Identifiant / email`,
      placeholder: `Saisir votre identifiant...`,
    },
    password: {
      label: `Mot de passe`,
      placeholder: `Saisir votre mot de passe...`,
    },
    validationButton: `connexion`,
    forgotPassword: `Mot de passe oublié`,
    catchPhrase: `Donnez le meilleur <br> Pour le meilleur`,
  },
  register: {
    title: `Création de compte Noobizes`,
    form: {
      title: `Infos compte`,
      username: {
        label: `pseudo`,
        placeholder: `Choisir votre pseudo...`,
      },
      email: {
        label: `email`,
        placeholder: `Choisir votre email...`,
      },
      password: {
        label: `Mot de passe`,
        placeholder: `Saisir votre mot de passe...`,
      },
      confirmPassword: {
        label: `Confirmation mot de passe`,
        placeholder: `Saisir un mot de passe...`,
      },
      alphaKey: {
        label: "clé d'accès alpha",
        placeholder: `Saisir votre clé...`,
      },
      cgu: {
        terms1: `Je certifie avoir plus de 18 ans,`,
        terms2: `j’accepte les CGU de Noobizes.`,
      },
      validationButton: `Inscription`,
    },
    success: {
      title: `Félicitations !`,
      text: `Votre compte a été créé avec succès.`,
      connectButton: `Connexion`,
      account_created: `Votre compte à bien été créé !`,
    },
    error: {
      email_already_exists: `Un compte avec cet email existe déjà`,
      username_already_exists: `Un compte avec ce pseudo existe déjà`,
      wrong_access_key_or_email: "Le couple clé alpha/email n'est pas valide",
      access_key_already_used: `Votre compte est déja créé !`,
      cgu_is_required: "Le couple identifiant/mot de passe n'est pas valide",
    },
    catchPhrase: {
      top: `Affrontez vous sans relâche`,
      bottom: `rafflez les mises`,
    },
  },
  updatePassword: {
    title: `Réinitialisation du mot de passe`,
    oldPassword: {
      label: `Mot de passe actuel`,
      placeholder: `Saisir le mot de passe actuel...`,
    },
    newPassword: {
      label: `Nouveau mot de passe`,
      placeholder: `Saisir un nouveau mot de passe...`,
    },
    confirmPassword: {
      label: `confirmation mot de passe`,
      placeholder: `Confirmer le nouveau mot de passe`,
    },
    validationButton: `Confirmer`,
    error: {
      wrong_old_password: `Le mot de passe actuel est incorrect`,
    },
  },
  resetPassword: {
    title: `Mot de passe oublié`,
    subTitle: `infos compte`,
    email: {
      label: `Email`,
      placeholder: `Saisir votre email...`,
    },
    validationButton: `Envoyer`,
    catchPhrase: {
      top: `Affrontez vous sans relâche`,
      bottom: `rafflez les mises`,
    },
    error: {
      expired: `Votre demande a expiré`,
    },
    success: {
      send: "Un lien de réinitialisation vous a été envoyé à l'adresse<br><span>{email}</span>",
      reset: `Votre nouveau mot de passe a bien été enregistré !`,
    },
    confirmation: {
      title: `Mot de passe oublié`,
      subTitle: `Réinitialisation de votre mot de passe`,
      password: {
        label: `Mot de passe`,
        placeholder: `Saisir un mot de passe...`,
      },
      confirmPassword: {
        label: `Confirmation mot de passe`,
        placeholder: `Saisir un mot de passe...`,
      },
      validationButton: `Confirmer`,
      backButton: "Retour à l'accueil",
      catchPhrase: {
        top: `Affrontez vous sans relâche`,
        bottom: `rafflez les mises`,
      },
    },
  },
  inviteFriend: {
    title: `Invitez vos amis, ou ennemis 😈`,
    emailPlaceholder: `Saisir un email...`,
  },
  home: {
    title: {
      firstLine: `Devenez le meilleur et rafflez les gains !`,
      secondLine: `Une mise, un défi, la victoire`,
    },
    button: {
      soon: `Bientôt disponible`,
      play: `Jouer`,
    },
    about: {
      title: `Bienvenue sur Noobizes,`,
      text:
        'La plateforme Esports pensée par des joueurs pour des joueurs.<br>Le principe est simple : défiez des joueurs sur vos jeux favoris pour gagner des prix.<br>Maintenant que vous êtes inscrit, il ne vous reste plus qu’à inviter vos amis pour vous affronter sur les jeux de votre choix.<br><span class="lastWords">Bonnes Games,</span><span class="signature">La Noobzteam.</span>',
    },
  },
  games: {
    button: `Jouer`,
  },
  mode: `0 mode | 1 mode | {count} modes`,
  connector: {
    label: {
      platform: `Plateforme`,
      username: `Pseudo`,
    },
    button: {
      associate: `Associer`,
      dissociate: `Dissocier`,
      newAccount: `Associer un nouveau compte`,
    },
    error: {
      link: "Votre compte n'a pas pu être associé.<br>Contactez nous <b>contact@noobizes.com</b>",
      notFound: "Ce joueur n'existe pas. Vérifiez l'orthographe exacte du pseudo et la plateforme associée.",
      challengesAccepted: `Vous avez des matches en cours.<br>Veuillez terminer vos matches avant de déconnecter votre compte.`,
      challengesCreated: `Veuillez supprimer les défis que vous avez créés<br>avant de déconnecter votre compte.`,
      matchMakingCreated: `Veuillez annuler votre match making en cours<br>avant de déconnecter votre compte.`,
    },
    loader: {
      associate: `Association en cours...`,
      info: `Veuillez patienter, cette opération peut prendre plusieurs minutes.`,
    },
    modal: {
      title: `Vous avec des match en cours.`,
      text:
        "En continuant la dissociation, tous les matchs en cours seront automatiquement clos.<br>Si vous n'avez pas joué ces matchs, votre mise sera perdue.<br><br>Souhaitez-vous continuer la dissociation de votre compte ?",
      button: {
        confirm: `Continuer la dissociation`,
        cancel: `Voir les matchs en cours`,
      },
    },
  },
  challenge: {
    error: {
      selectMode: `Choisissez un mode de jeu`,
      selectBet: `Choisissez une mise`,
      delete: {
        alreadyAccepted: "Un joueur vient d'accepter votre défis",
      },
      tooManyChallenges: `Le nombre maximum de défis a été atteint.`,
      notAvailable: "Le défi n'est plus disponible",
    },
    user: {
      countUserChallenges: ` | 1 défi en attente | {count} défis en attente`,
      opponent: {
        accept: `a accepté votre défi`,
        start: `a commencé sa partie`,
        end: `a terminé sa partie`,
      },
      waiting: `En attente...`,
      play: `Jouer maintenant`,
      delete: `Supprimer`,
    },
    challenges: {
      refresh: `Recherche...`,
      countChallenges: `Aucun défi trouvé | 1 défi trouvé | {count} défis trouvés`,
      waiting: `En attente de nouveaux défis...`,
    },
    accept: `Défier`,
    form: {
      title: `Créer un défi`,
      button: `Créer`,
      lowCredit: `Crédit insuffisant`,
      mode: `Mode de jeu`,
    },
    searchBar: {
      mode: `Mode de jeu`,
      bet: `Mise`,
      username: `Pseudo...`,
      searchButton: `Rechercher`,
    },
    table: {
      pseudo: `Pseudo`,
      ration: `Ratio`,
      mode: `Mode`,
      bet: `Mise`,
    },
  },
  matchMaking: {
    running: `MatchMaking en cours`,
    stopped: "Aucun adversaire n'a été trouvé",
    restartOrChallenge: `Relancez votre matchmaking<br>ou créez votre défi personnalisé`,
    searchOpponent: `Nous recherchons<br>un adversaire à votre taille...`,
    cancel: `Annuler`,
    createChallenge: `Créer un défi`,
    restart: `Relancer`,
  },
  matchMakingTag: {
    title: `MatchMaking :`,
    search: `Recherche en cours...`,
    hasOpponent: `Adversaire trouvé`,
  },
  match: {
    actions: {
      confirmStart: {
        text: `Confirmation du lancement de votre partie`,
        button: `Je lance ma partie`,
      },
      confirmEnd: {
        text: `Confirmez la fin de votre partie`,
        button: `Confirmer`,
      },
      error: {
        selectPlatform: `Sélectionnez une platforme`,
      },
    },
    player: {
      state: {
        notStarted: `En attente du lancement<br>de la partie...`,
        ended: `Partie terminée`,
        playing: `Partie en cours...`,
        timeout: `La partie a expirée`,
        waitingForOpponent: "<br>En attente<br>d'un adversaire...",
      },
    },
    dotations: {
      title: `Mises`,
    },
    matchMaking: {
      found: `Adversaire trouvé`,
      ended: `MatchMaking terminé`,
    },
    modesAvailable: `Modes disponibles`,
    infos: {
      game: `Jeu`,
      mode: `Mode`,
      platform: `Plateforme`,
      goal: {
        title: `Objectif`,
        text: `Classement`,
      },
      averageTime: `Durée moyenne`,
    },
  },
  form: {
    select: {
      platform: `Plateforme`,
    },
  },
  pools: {
    title: `Rejoingnez une pool`,
    text: `Choisissez votre mise maximale et trouvez un adversaire à votre taille !<br>Le choix du mode est aléatoire.`,
    form: {
      title: `Choisissez votre plateforme`,
      select: `Plateforme`,
      confirm: `Confirmer`,
    },
    pool: {
      infos: `Dotations entre {min} - {max} Nbz`,
    },
  },
  matches: {
    title: `Mes matchs`,
    countMatchesActive: `Aucun match en cours | 1 match en cours | {count} matchs en cours`,
    countMatchesEnded: `Aucun match terminé | 1 match terminé | {count} matchs terminés`,
    matchesEnded: `matchs terminés`,
    activedMatches: {
      opponent: `Adversaire`,
      mode: `Mode`,
      bet: `Bet`,
      state: `Statut`,
      time: `Lancement`,
      waitingOpponent: "En attente d'un adversaire...",
    },
    endedMatches: {
      opponent: `Adversaire`,
      mode: `Mode`,
      bet: `Gain`,
      state: `Statut`,
      time: `Lancement`,
    },
    buttons: {
      createChallenge: `Créer un nouveau défi`,
      matchMaking: `MatchMaking`,
    },
    state: {
      win: `Vous avez gagné`,
      lose: `Vous avez perdu`,
      timeout: `La partie a expirée`,
      draw: `Égalité`,
      started: `Votre partie est en cours`,
      notStarted: "Vous n'avez pas encore lancé votre partie",
      opponentStarted: `{opponentName} est en cours de partie`,
      opponentNotStarted: "{opponentName} n'a pas encore lancé sa partie",
      noPlayer: "En attente d'un joueur",
      processing: `En attente des résultats`,
    },
  },
  matchResume: {
    title: `Résultats des matchs`,
    processing: `En attente des résultats...`,
    winner: `Vainqueur`,
    loser: `Perdant`,
    draw: `Égalité`,
    labels: {
      versus: `VS`,
      status: `Statut`,
      gain: `Gain`,
      rank: `Rang`,
      rankRatio: `Rang ratio`,
      points: `Points`,
      kills: `Kills`,
      dbnos: `DBNOs`,
    },
    infos: {
      title: `Information :`,
      text:
        'Le classements des joueurs ne prend en compte que les joueurs réels, et exclu les robots.<br>Votre classement final peut ainsi différer du classement affiché sur les sites officiels.',
    },
  },
  sideMenu: {
    gameList: `Liste des jeux`,
    leaderboard: `Leaderboard`,
    store: `Boutique`,
    userGamesTitle: `Mes jeux`,
    game: {
      challenges: `Défis`,
      matchMaking: `Match Making`,
      matches: `Mes Matchs`,
    },
    soon: `À venir`,
    buttonCoins: `Ajouter du crédit`,
    settings: `Paramètres`,
    logout: `Déconnexion`,
  },
  settingsHeader: {
    settings: `Paramètres`,
    buttonNbz: `Ajouter du crédit`,
  },
  settings: {
    menu: {
      account: `Compte`,
      games: `Jeux`,
    },
    games: {
      topText:
        "Afin d’affronter d’autres joueurs Noobizes sur le jeu de votre choix, il est indispensable d’associer vos comptes repectifs sur les jeux et plateformes concernés.<br>Veuillez à respecter l'orthographe exacte de votre pseudo.",
    },
    account: {
      labels: {
        pseudo: 'Pseudo',
        email: 'Email',
        profilpic: 'Image de profil',
        password: 'Mot de passe',
      },
      upload: `Charger une image`,
      info: `(max 10M, JPG, PNG, GIF, SVG)`,
      resetPassword: `Réinitialiser mon mot de passe`,
      error: {
        entityTooLarge: "L'image ne doit pas dépasser 10M.",
      },
    },
  },
  loadingText: `Chargement...`,
  rules: {
    menu: {
      howItWorks: `Comment ça marche`,
      nbzRules: `Règlement`,
    },
    separator: 'ou',
    nbz: {
      1: {
        title: `1 / Définition du gagnant`,
        text: `Le gagnant du défi est le joueur obtient le plus de points.<br />
        Les points sont calculés par rapport au classement final du joueur, au ratio du nombre total de joueurs sur la partie.<br />
        Des points supplémentaires s'ajoutent pour chaque KILL ou DBNOs effectué.`,
      },
      2: {
        title: `2 / En cas d'égalité`,
        text: `Si les joueurs obtiennent le même nombre de points, les mises sont réparties équitablement entre les joueurs, minus 10% de commission.`,
      },
      3: {
        title: `3 / Information relative à la triche`,
        text: `Toutes formes d’assistance, ayant pour conséquence d’augmenter le niveau réel du joueur, est considéré comme de la triche.`,
      },
      4: {
        title: `4 / Respect des joueurs`,
        text: `Pour rappel, il interdit de tenir des propos discriminatoires liés à l’ethnie, la nationalité, la race, le genre, la religion, les préférences sexuelles
        ou les croyances. Le joueur s’expose à des poursuites et à une fermeture de son compte.`,
      },
    },
    challenge: {
      text: {
        1: {
          title: `1 / Crééz votre défi personnalisé`,
          text: `Dans la rubrique 'Créer un défi', choisissez le mode et la mise que vous souhaitez jouer, puis cliquez sur 'Créer'.<br>
          Votre défi est maintenant visible par les autres joueurs.<br>
          Vous pouvez jouer votre défi dès maintenant en cliquant sur le bouton "Jouer maintenant".<br>
          Une fois le défi commencé ou accepté par un joueur, vous ne pouvez plus le supprimer.<br>
          À partir du moment où un adversaire a rejoind votre défi, vous avez un délai de 24h pour jouer votre partie, auquel cas vous serez considéré comme forfait.<br>`,
        },
        2: {
          title: `2 / Lancez votre partie <span class="accent">indépendamment</span> de votre adversaire`,
          text: `Vous ne jouez <b>PAS</b> sur le même serveur que votre adversaire. Chaque joueur joue sa partie indépendamment de son adversaire, en respectant le mode
          de jeu sélectionné.<br />
          Dès que vous êtes près, lancez votre partie sur la plateforme choisie, en respectant le mode qui a été choisi pour le défi ou le match making.<br />
          Une fois votre partie lancée, cliquez sur le bouton "Je lance ma partie" afin que nous puissions par la suite récupérer votre partie.`,
        },
        3: {
          title: `3 / Annoncez votre fin de partie sur Noobizes`,
          text: `Une fois votre partie terminée, cliquez sur le bouton “Terminer”.<br />
          A partir de ce moment, vous allons récupérer vos résultats et les comparer à ceux de votre adversaire.`,
        },
        4: {
          1: {
            title: `4 / Vous avez gagné`,
            text: `Félicitations !<br />
            Vous collectez immédiatement la totalité du pot qui a été mis en jeu, minus 10% de commission.`,
          },
          2: {
            title: `4 / Vous avez perdu`,
            text: `Vous avez perdu la bataille mais pas la guerre ! Retentez votre chance !<br />
            Vous pouvez à tout moment demander une revanche en proposant un nouveau défis à votre adversaire.`,
          },
        },
        last: `<i>La récupération des données peut prendre plusieurs minutes. Vous recevrez une notification dès que vos résultats seront publiés.</i><br />
        <i><b>A savoir : </b>Le classements des joueurs ne prend en compte que les joueurs réels, et exclu les robots.<br />Votre classement final peut ainsi
          différer du classement affiché sur les sites officiels.</i>`,
      },
    },
    matchmaking: {
      1: {
        title: `1 / Recherche d'adversaire`,
        text: `Lors du match making, la mise maximale est défini lors de l'accès aux pools.<br>
        Le mode de jeu est choisi de manière aléatoire.<br>
        La recherche d'adversaire se fait en fonction de votre niveau sur la platforme Noobizes<br>
        Une fois qu'un adversaire trouvé, vous pouvez lancé votre partie.<br>
        Si aucun adversaire n'a été trouvé dans un délai de 5 minutes, vous pouvez relancer un match making ou créer un défi.`,
      },
      2: {
        title: `2 / Lancez votre partie <span class="accent">indépendamment</span> de votre adversaire`,
        text: `Vous ne jouez <b>PAS</b> sur le même serveur que votre adversaire. Chaque joueur joue sa partie indépendamment de son adversaire, en respectant le mode de jeu sélectionné.<br>
        Dès que vous êtes près, lancez votre partie sur la plateforme choisie, en respectant le mode qui a été choisi pour le défi ou le match making.<br>
        Une fois votre partie lancée, cliquez sur le bouton "Je lance ma partie" afin que nous puissions par la suite récupérer votre partie.`,
      },
      3: {
        title: `3 / Annoncez votre fin de partie sur Noobizes`,
        text: `Une fois votre partie terminée, cliquez sur le bouton “Terminer”.<br>
        A partir de ce moment, vous allons récupérer vos résultats et les comparer à ceux de votre adversaire.`,
      },
      4: {
        1: {
          title: `4 / Vous avez gagné`,
          text: `Félicitations !<br>
          Vous collectez immédiatement la totalité du pot qui a été mis en jeu, minus 10% de commission.`,
        },
        2: {
          title: `4 / Vous avez perdu`,
          text: `Vous avez perdu la bataille mais pas la guerre ! Retentez votre chance !<br>
          Vous pouvez à tout moment demander une revanche en proposant un nouveau défis à votre adversaire.`,
        },
      },
      last: `<i>La récupération des données peut prendre plusieurs minutes. Vous recevrez une notification dès que vos résultats seront publiés.</i>
      <br>
      <i><b>A savoir : </b>Le classements des joueurs ne prend en compte que les joueurs réels, et exclu les robots.<br>Votre classement final peut ainsi différer du classement affiché sur les sites officiels.</i>`,
    },
    matchs: {
      1: {
        title: `1 / Lancez votre partie <span class="accent">indépendamment</span> de votre adversaire`,
        text: `Vous ne jouez <b>PAS</b> sur le même serveur que votre adversaire.<br>Chaque joueur joue sa partie indépendamment de son adversaire, en respectant le mode de jeu sélectionné.<br>
        Dès que vous êtes près, lancez votre partie sur la plateforme choisie, en respectant le mode qui a été choisi pour le défi ou le match making.<br>
        Une fois votre partie lancée, cliquez sur le bouton "Je lance ma partie" afin que nous puissions par la suite récupérer votre partie.`,
      },
      2: {
        title: `2 / Annoncez votre fin de partie sur Noobizes`,
        text: `Une fois votre partie terminée, cliquez sur le bouton “Terminer”.<br>
        A partir de ce moment, vous allons récupérer vos résultats et les comparer à ceux de votre adversaire.`,
      },
      3: {
        1: {
          title: `3 / Vous avez gagné`,
          text: `Félicitations !<br>
          Vous collectez immédiatement la totalité du pot qui a été mis en jeu, minus 10% de commission.`,
        },
        2: {
          title: `3 / Vous avez perdu`,
          text: `Vous avez perdu la bataille mais pas la guerre ! Retentez votre chance !<br>
          Vous pouvez à tout moment demander une revanche en proposant un nouveau défis à votre adversaire.`,
        },
      },
      last: `<i>La récupération des données peut prendre plusieurs minutes. Vous recevrez une notification dès que vos résultats seront publiés.</i>
      <br>
      <i><b>A savoir : </b>Le classements des joueurs ne prend en compte que les joueurs réels, et exclu les robots.<br>Votre classement final peut ainsi différer du classement affiché sur les sites officiels.</i>`,
    },
  },
  notification: {
    link: {
      success: {
        title: `Liaison de service ✅`,
        message:
          'Félicitations, la liaison de votre compte {platformName} s’est effectuée avec succès ! Accédez désormais aux jeux et aux évènements liés à ce service.',
      },
      error: {
        title: `Liaison de service ❌`,
        message:
          'Mince... Une erreur est survenue lors de la liaison de votre compte {platformName}. Vérifiez les données liées à votre service, ainsi que leur saisie.',
      },
    },
    menu: {
      title: `Notifications`,
      empty: `Aucune nouvelle notification`,
      markAsRead: "J'ai tout vu, merci.",
    },
    challenge: {
      accepted: {
        title: `Votre défi a été accepté`,
        message: `{opponentName} a accepté votre défi {modeLabel} à {bet} Nbz`,
      },
      win: {
        title: `Vous avez gagné  🙌`,
        // message: "Bravo",
      },
      lose: {
        title: `Vous avez perdu`,
        timeout: {
          title: `Vous avez perdu`,
          message: "Vous n'avez pas joué vos défi",
        },
      },
    },
    matchMaking: {
      accepted: {
        title: `Match making : Adversaire trouvé`,
        message: `Défis contre {opponentName}, {mode} à {bet} Nbz`,
      },
    },
    levelup: {
      title: `Niveau supérieur atteint`,
      message: "Vous avez atteint le niveau <b class='level'>{level}</b>",
    },
    matchLink: ` > Voir le match`,
    payment: {
      success: {
        title: `Merci pour votre achat !`,
        message: `Votre compte a été crédité de {amountNbz} Nbz, à vous de jouer maintetant !`,
      },
      error: {
        title: `Une erreur est survenue lors de votre achat`,
        message: "La transaction n'a pas pu aboutir, vous ne serez pas débité.",
      },
      refund: {
        title: `Votre achat a été remboursé.`,
        message: `Le remboursement de la somme de {price}€ apparaitra sur votre compte dans un délai de 5 à 10 jours.`,
      },
    },
    transaction: {
      refund: {
        title: `Votre compte a été crédité.`,
        message: `Vous venez de recevoir {amount} Nbz.`,
      },
    },
    pubgNotFound: {
      title: `Erreur lors de la récupération de vos matchs ❌`,
      message: "Veuillez ontacter le support à l'adresse <b>contact@noobizes.com</b>",
    },
  },
  leaderboard: {
    title: `Leaderboard`,
    table: {
      player: `Joueur`,
      matches: `matchs`,
      point: `score`,
    },
  },
  footer: {
    tradeMark: {
      full: `© 2021 Noobizes. Tous drois réservés.`,
      short: `© 2021 Noobizes.`,
    },
    cgu: "Conditions Générales d'Utilisation",
    cgv: `Conditions Générales de Vente`,
    privacy: `Politique de confidentialité`,
    contactTitle: `Contacts`,
    contactEmail: `contact@noobizes.com`,
  },
  landingPage: {
    form: {
      title: `Demandez et recevez votre <span class="orange">clé Alpha</span>.`,
      text:
        'Inscrivez-vous pour participer en exclusivité à la première version de <strong>Noobizes</strong>.<br />Nous souhaitons recueillir vos impressions et avis pour mieux répondre à vos besoins.',
      placeholder: `Saisir un email...`,
    },
    steps: {
      1: `Créez et configurez votre compte`,
      2: `Recherchez/publiez des <strong>défis</strong>`,
      3: `Trouvez des joueurs à challenger`,
      4: {
        1: `Remportez vos parties et `,
        2: `promis`,
      },
    },
    button: {
      connect: `se connecter`,
      redirectApp: `Accéder au site`,
    },
    about: {
      title: `Votre <strong>plateforme Esport</strong>`,
      text:
        '<p><strong>Noobizes</strong>, la <strong>plateforme Esport</strong> pensée par des <strong>joueurs</strong> pour des joueurs. Le principe est simple, inscrivez-vous pour affronter d’autres joueurs, sur les <strong>jeux</strong> de votre choix, dans le cadre d’une partie dotée ou non dotée. Demandez votre clef alpha et venez défier vos amis pour remporter des <strong>prix</strong>.</p>',
      bottomTitle: `Des <strong>gains</strong> assurés en cas de victoire`,
    },
    aboutAlpha: {
      title: "À propos de l'alpha",
      text:
        '<p>L’objectif pour nous est de pouvoir vous présenter notre plateforme tout en continuant de tester notre solution en récoltant vos remarques.</p><p>Pour cette nouvelle étape, Noobizes sera ouvert à 1000 personnes en accès gratuit. Nous créditerons 1000 crédits NBZ sur chaque compte pour que vous puissiez créer et accepter les défis. Les crédits attribués à la création de votre compte n’auront pas de valeur monétaire. Il s’agira de jetons gratuit. Pour l’instant, seul le jeu PUBG est disponible. On travaille pour en ajouter de nouveaux.</p><p>Si vous avez des questions sur le fonctionnement, des suggestions d’améliorations ou des retours de bugs, un serveur <a href="https://discord.gg/2HrRHaZFdN">discord</a> a été créée pour faciliter vos remontées et simplifier leur traitement. Vous pouvez aussi nous faire vos retours à l’adresse <a href="mailto:contact@noobizes.com">contact@noobizes.com</a>.</p>',
      bottomTitle: `A bientôt sur Noobizes !`,
    },
  },
  deposit: {
    title: `Ajouter du crédit`,
    subtitle: `Choisissez une offre`,
    text:
      'Choisissez le montant qui vous convient puis complétez les informations de paiement.<br>Une fois le paiement validé, les Noobizes seront instantanément créditer sur votre compte.',
    orderButton: `Commander`,
    modal: {
      success: {
        title: `Merci pour votre achat`,
        text: `Votre compte a été crédité avec succès.<br>A vous de jouer maintenant !`,
        button: {
          createNewChallenge: `Créer un nouveau défi`,
          matchMaking: `Matchmaking`,
        },
      },
      error: {
        title: "Votre compte n'a pas pu être crédité",
        text: `Un problème est survenu lors du paiement, vous ne serez pas débité.`,
      },
    },
  },
};
