import axios, { AxiosRequestConfig } from 'axios';
import {auth} from '../auth';
import {config} from '../env/Configuration';
interface IConfig {
    url?: string;
    withCredentials?: boolean;
}
let globalConfig: IConfig = {

};
let waitPromise: Promise<any>|null;
class ApiRequest implements PromiseLike<any> {
    private _route!: string;
    private _params: any;
    private _method: 'get' | 'GET' | 'delete' | 'DELETE' | 'head' | 'HEAD' | 'options' | 'OPTIONS' | 'post' | 'POST' | 'put' | 'PUT' | 'patch' | 'PATCH' | 'link' | 'LINK' | 'unlink' | 'UNLINK' | undefined;
    private _user: any;
    private promise: any;
    private _files: Array<{name: string, file: File}>;
    constructor() {
        this._params = {};
        this._files = [];
        this._method = 'GET';
        this._user = null;
    }
    public route(path: string): this {
        this._route = path;
        return this;
    }
    public params(parameters: any): this {
        this._params = Object.assign(this._params, parameters);
        return this;
    }
    public file(name: string, file: File): this {
        this._files.push({name, file});
        return this;
    }
    public method(method: 'get' | 'GET' | 'delete' | 'DELETE' | 'head' | 'HEAD' | 'options' | 'OPTIONS' | 'post' | 'POST' | 'put' | 'PUT' | 'patch' | 'PATCH' | 'link' | 'LINK' | 'unlink' | 'UNLINK' | undefined): this {
        this._method = method;
        return this;
    }
    public async then(resolve?: ((value: any) => any | PromiseLike<any>) | null | undefined, reject?: ((reason: any) => any | PromiseLike<any>) | null | undefined): Promise<any> {
        if (this.promise) {
            return this.promise;
        }
        if (waitPromise) {
            try {
                await waitPromise;
            } catch (error) {
                // nothing to do
            }
        }
        return this.result().then(resolve, reject);
    }
    public get(): this {
       return  this.method('GET');
    }
    public post(): this {
        return this.method('POST');
    }
    public patch(): this {
        return this.method('PATCH');
    }
    public put(): this {
        return this.method('PUT');
    }
    public delete(): this {
        return this.method('DELETE');
    }
    public result() {
       let method = this._method;

       const options: AxiosRequestConfig = {
            baseURL: globalConfig.url,
            method,
            url: this._route,
            withCredentials: globalConfig.withCredentials,
        };
        // maybe Object.assign is useless
       let params = this._params; // Object.assign({}, this._params)
        // use GET when possible on debug -> allow to open in new tab easily
       const hasFiles = !!this._files.length;
       if (config('app.debug') && !hasFiles) {
            const json = JSON.stringify(params);
            if (json.length < 10000) {
                method = 'GET';
                if (this._method != 'GET') {
                    params._method = this._method;
                }
            }
            if (!params.api_token ) {
                params.api_token = auth.token();
            }
        } else {
            options.headers = {
                Authorization: `Bearer ${auth.token()}`,
            };
        }
       if (hasFiles) {
            if (method == 'GET') {
                method = 'POST';
                params._method = 'GET';
            }
            const formData = new FormData();
            for (const key in params) {
                formData.append(key, params[key]);
            }
            for (const file of this._files) {
                formData.append(file.name, file.file);
            }
            params = formData;
        }
       if (method == 'GET') {
            options.params = params;
        } else {
            options.data = params;
        }
       const request = axios.request(options);
       const promise = request.then((response) => {
            const result = response.data;
            if (result.error) {
                debugger; // not like that anymore
                return Promise.reject(result.error);
            }
            return result.data;

        }, (error) => {
            console.log({error});
            if (error?.response?.data?.error?.message == 'Unauthenticated.') {
                auth.logout();
                window.location.reload();
                return;
            }
            if (error?.response?.data?.error?.message == 'game_not_connected') {
                window.location.reload();
                return;
            }
            const status = error.response.status;
            // normal error
            if (status >= 400 && status < 500 && error.response.data && error.response.data.error) {
                return Promise.reject(error.response.data.error);
            }
            if (config('app.debug')) {
                return Promise.reject(error);
            }
            return Promise.reject({
                message: 'error.server',
            });

        }).finally(() => {
            if (this._method != 'GET') {
                waitPromise = null;
            }
        });

       this.promise = promise;

       if (this._method != 'GET') {
            waitPromise = promise;
        }
       return promise;
    }
    public config(configuration: IConfig): any {
        globalConfig = Object.assign(globalConfig, configuration);
    }
}

export function api(): ApiRequest {
    return new ApiRequest();
}

(globalThis as any).api = api;
