











import Vue from 'vue';
import { mapGetters } from 'vuex';
import GameCard from '@/components/Games/GameCard.vue';
import Loader from '@/components/General/Loader.vue';

export default Vue.extend({
  name: 'games',
  components: {
    GameCard,
    Loader,
  },
  data() {
    return {
      gamesList: [],
    };
  },
  created() {
    if (this.games?.length) {
      this.gamesList = this.games;
      if (this.rankByGame) {
        this.computeGamesList();
      }
    }
    this.getRanking();
    this.refreshGamesList();
  },
  watch: {
    games() {
      this.computeGamesList();
    },
  },
  computed: {
    ...mapGetters('games', ['games']),
    ...mapGetters('games', ['rankByGame']),
    ...mapGetters('games', ['gamesInitialized']),
  },
  methods: {
    async getRanking(): Promise<void> {
      try {
        await this.$store.dispatch('games/getRankByGame', { game_id: null, limit: 5 });
        if (this.games?.length) {
          this.computeGamesList();
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    computeGamesList() {
      this.gamesList = this.games.map((g) => {
        return {
          ...g,
          topPlayers: this.rankByGame ? this.rankByGame[g.id] : [],
        };
      });
    },
    /**
     * Refresh for having the new values of activePlayers and registeredPlayers
     */
    refreshGamesList(): void {
      this.$store.dispatch('games/list');
    },
    redirectGame(gameSlug) {
      if (!gameSlug) { return; }
      this.$router.push({ name: 'challenges', params: { gameSlug }});
    },
  },
});
