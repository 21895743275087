import LandingPage from '@/views/LandingPage.vue';
import Register from '@/views/Register.vue';
import SignIn from '@/views/SignIn.vue';
import ResetPassword from '@/views/ResetPassword/ResetPassword.vue';
import ResetPasswordConf from '@/views/ResetPassword/ResetPasswordConf.vue';
import Terms from '@/views/Terms.vue';
import { auth } from '@/auth';

export default [
  {
    path: '/',
    name: 'landingPage',
    component: LandingPage,
    meta: {
      hideHeader: true,
      hideSideBar: true,
      largeFooter: true,
      // SEO
      title: 'router.landing.title',
      metaTags: [
        {
          name: 'description',
          content: 'Noobizes, la plateforme Esports pensée par des gamers pour des gamers. Venez défier vos amis sur vos jeux favoris pour gagner des prix.',
        },
      ],
    },
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      lightMenu: true,
      hideSideBar: true,
      largeFooter: true,
      // SEO
      title: 'router.register.title',
      metaTags: [{ name: 'description', content: "Création d'un compte sur le le site Noobizes." }],
    },
    props: (route: any) => ({ email: route.query.email, accessKey: route.query.key }),
  },
  {
    path: '/login',
    name: 'login',
    component: SignIn,
    meta: {
      lightMenu: true,
      hideSideBar: true,
      largeFooter: true,
      // SEO
      title: 'router.login.title',
      metaTags: [{ name: 'description', content: 'Connectez-vous et affrontez vos amis sur vos jeux favoris.' }],
    },
    props: (route: any) => ({ email: route.query.email }),
    beforeEnter: (to: any, from: any, next: any) => {
      if (auth.check()) {
        next({ name: 'home' });
      } else {
        next();
      }
    },
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: ResetPassword,
    meta: {
      lightMenu: true,
      hideSideBar: true,
      largeFooter: true,
      // SEO
      title: 'router.forgotpassword.title',
      metaTags: [
        { name: 'description', content: 'Demande de réinitialisation du mot de passe.' },
        { name: 'robots', content: 'noindex' },
      ],
    },
  },
  {
    path: '/resetPassword',
    name: 'resetPasswordConf',
    component: ResetPasswordConf,
    props: (route: any) => ({ token: route.query.token }),
    meta: {
      lightMenu: true,
      hideSideBar: true,
      largeFooter: true,
      // SEO
      title: 'router.resetpassword.title',
      metaTags: [
        { name: 'description', content: 'Réinitialisation du mot de passe.' },
        { name: 'robots', content: 'noindex' },
      ],
    },
  },
  {
    path: '/terms/cgu',
    name: 'cgu',
    component: Terms,
    meta: {
      lightMenu: true,
      hideSideBar: true,
      largeFooter: true,
      terms: 'cgu',
      slug: 'terms',
      // SEO
      title: 'router.cgu.title',
      metaTags: [{ name: 'description', content: "Conditions générales d'utilisation du site Noobizes" }],
    },
  },
  {
    path: '/terms/cgv',
    name: 'cgv',
    component: Terms,
    meta: {
      lightMenu: true,
      hideSideBar: true,
      largeFooter: true,
      terms: 'cgv',
      slug: 'terms',
      // SEO
      title: 'router.cgv.title',
      metaTags: [{ name: 'description', content: 'Conditions générales de ventes du site Noobizes.' }],
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacy',
    component: Terms,
    meta: {
      lightMenu: true,
      hideSideBar: true,
      largeFooter: true,
      terms: 'privacy',
      slug: 'terms',
      // SEO
      title: 'router.privacy.title',
      metaTags: [{ name: 'description', content: 'Politique de confidentialité du site Noobizes.' }],
    },
  },
];
