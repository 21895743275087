
































































import Vue from 'vue';
import { config } from '@/env/Configuration';
import { LocaleMessage } from 'vue-i18n';
import Loader from '../General/Loader.vue';

export default Vue.extend({
  components: { Loader },
  name: 'match-player-resume',
  props: {
    participant: {
      type: [Object, null],
      required: false,
    },
    apiParticipant: {
      type: Object,
      required: false,
    },
    challenge: {
      type: Object,
      required: true,
    },
    align: {
      type: String,
      required: false,
      default: 'left',
    },
  },
  computed : {
    isTimeout(): Boolean {
      if (this.participant) { return !!this.participant.timeout; }
      return false;
    },
    /**
     * Is loading apiParticipant Results
     */
    isLoading(): Boolean {
      return !!(this.participant && this.participant.state && !this.apiParticipant && !this.isTimeout);
    },
  },
  methods: {
    ratio(participant): any {
      return Math.round(((participant.win_place / participant.match.total_players) + Number.EPSILON) * 100) / 100;
    },
    state(participant): LocaleMessage|null {
      const state = participant.state;
      if (state) {
        if (state == 'win') {
          return this.$t('matchResume.winner');
        }
        if (state == 'lose') {
          return this.$t('matchResume.loser');
        }
        if (state == 'draw') {
          return this.$t('matchResume.draw');
        }
      }
      return null;
    },
    gain(participant): number|string {
      if (!participant.state) { return '-'; }
      if (participant.state == 'win') {
        const totalBet = this.challenge.bet * this.challenge.total_participants;
        return (totalBet - (totalBet * config('app.nbz.commission'))) + ' Nbz';
      }
      if (participant.state == 'lose') {
        return 0 + ' Nbz';
      }
      if (participant.state == 'draw') {
        const totalBet = this.challenge.bet * this.challenge.total_participants;
        return ((totalBet - (totalBet * config('app.nbz.commission'))) / this.challenge.total_participants) + 'Nbz';
      }
    },
  },
});
