












































































import Vue from 'vue';
import SettingsHeader from '@/components/Account/SettingsHeader.vue';
import ChallengeForm from '../components/Forms/ChallengeForm.vue';
import { mapGetters, mapMutations } from 'vuex';
import loadGame from '@/components/Mixins/loadGame.vue';
import UserChallengesList from '@/components/Challenges/UserChallengesList.vue';
import Search from '@/components/Challenges/Search.vue';
import ChallengeRules from '@/components/Rules/ChallengeRules.vue';
import Pagination from '@/components/General/Pagination.vue';
import Background from '@/services/backgroundService';
import Loader from '@/components/General/Loader.vue';

export default Vue.extend({
  name: 'challenges',
  components: {
    SettingsHeader,
    ChallengeForm,
    UserChallengesList,
    Search,
    ChallengeRules,
    Pagination,
    Loader,
  },
  mixins: [loadGame],
  props: {
    gameSlug: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      game: null,
      sort: null,
      search: {
        mode: '',
        bet: { min: null, max: null},
        name: null,
      },
      page: 1,
      totalActiveChallenges: null,
      limit: 9,
      acceptLoading: null,
      refresh: false,
      newSearch: false,
    };
  },
  watch: {
    game(value) {
      if (value) {
        this.getChallengesList();
        this.getUserChallenges();
      }
    },
    page() {
      this.getChallengesList();
    },
    userChallenges: {
      handler(newValue, oldValue) {
        if (newValue.length) {
          if (!Background.userChallenges.interval) {
            const gameId = this.userChallenges[0].game_id;
            Background.watchUserChallenges(true, 10000, gameId);
          }
        } else {
          if (Background.userChallenges.interval) {
            Background.watchUserChallenges(false);
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('games', ['games']),
    ...mapGetters('challenges', ['challenges']),
    ...mapGetters('user', { userChallenges: 'challenges' }),
    ...mapGetters('auth', ['user']),
    ...mapGetters('gameAccount', {gamesAccounts: 'games'}),
    cover(): any {
      if (!this.game?.cover_url) { return; }
      const index = this.game.cover_url.lastIndexOf('.');
      const base = this.game.cover_url.substring(0, index);
      const extension = this.game.cover_url.substring(index);
      const coverLarge = base + '-lg' + extension;
      return {background: 'url(' + coverLarge + ') center / cover no-repeat'};
    },
    icon(): any {
      if (!this.game?.icon_url) { return; }
      return {background: 'url(' + this.game.icon_url + ') center / contain no-repeat'};
    },
    countUserChallenges(): string {
      return this.$tc('challenge.user.countUserChallenges', this.userChallenges.length, { count: this.userChallenges.length });
    },
    countActiveChallenges(): string {
      if (this.totalActiveChallenges == null) { return null; }
      return this.$tc('challenge.challenges.countChallenges', this.totalActiveChallenges, { count: this.totalActiveChallenges });
    },
    platform(): any {
      if (!this.user || !this.gamesAccounts?.length) { return false; }
      const gameAccount = this.gamesAccounts.find((el) => el.game.id == this.game.id);
      if (gameAccount?.platform) { return gameAccount.platform; }
      return false;
    },
    modesAvailabled(): any {
      if (!this.platform) { return []; }
      return this.game.platforms.find((p) => p.id == this.platform.id).modes;
    },
  },
  methods: {
    ...mapMutations('auth', ['updateCredit']),
    async getChallengesList(): Promise<void> {
      if (this.game) {
        const params = {
          game_id: this.game.id,
          name: this.search.name,
          mode_id: this.search.mode ? this.search.mode : null,
          min_bet: this.search.bet.min,
          max_bet: this.search.bet.max,
          order_by: this.sort ? this.sort.field : null,
          direction: this.sort ? this.sort.direction : null,
          page: this.page - 1,
          limit: this.limit,
        };
        const res = await this.$store.dispatch('challenges/getChallenges', { params });
        this.totalActiveChallenges = res.count;
        this.newSearch = false;
      }
    },
    getUserChallenges() {
      if (this.game) {
        try {
          this.$store.dispatch('user/getChallengesCreated', { gameId: this.game.id });
        } catch (e) {
          console.log(e);
        }
      }
    },
    async acceptChallenge(challenge: any): Promise<void> {
      this.acceptLoading = challenge;
      if (this.user.credit < challenge.bet ) {
        this.acceptLoading = null;
        Vue.$toast.open({
          message: (this.$t('error.lowCredit') as string),
          type: 'error',
        });
        return;
      }
      try {
        (this as any).updateCredit({ amount: -challenge.bet });
        const data = await this.$store.dispatch('user/acceptChallenge', { gameId: challenge.game_id, challengeId: challenge.id });
        this.$router.push({ name: 'match', params: { challengeId: data.uuid, currentChallenge: data, gameSlug: this.game.slug }});

      } catch (error) {
        this.acceptLoading = null;
        const err = this.$t(error.message);
        Vue.$toast.open({
          message: (err as string),
          type: 'error',
        });
      }
    },

    setSearch(search) {
      this.newSearch = true;
      this.sort = null;
      this.search = search;
      if (this.page == 1) {
        this.getChallengesList();
      } else {
        this.page = 1;
      }
    },

    async refreshSearch() {
      if (this.refresh) { return; }
      this.refresh = true;
      await this.getChallengesList();
      this.refresh = false;
    },
  },
});
