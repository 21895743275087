



















import { Vue, Component, Prop } from 'vue-property-decorator';

export interface Result {
  pseudo: string;
  win: number;
  lose: number;
}

@Component({
  name: 'n-GameStats',
})
export default class GameStats extends Vue {
  /**
   * Tableau de résultat formant le TOP 5 d'un jeu
   */
  @Prop({
    type: Array,
    required: true,
  })
  public results!: Result[];
}
