
import SocketIOClient from 'socket.io-client';
import {auth} from '@/auth/index';
import { make } from '@/app/make';

interface IConfig {
    url: string;
    path?: string;
    query?: string;
}
interface IResult {
    data?: any;
    error?: any;
}
function objectToRoutes(object: any, prefix?: string): any {
    let routes: any = {

    };
    for (const p in object) {
        const key =  prefix ? `${prefix}/${p}` : p;
        if (typeof object[p] == 'function') {
            routes[key] = object[p];
        } else {
            routes = Object.assign(routes, objectToRoutes(object[p], key));
        }
    }
    return routes;
}
export class Socket {
    private localConfig!: IConfig;
    private routes: any;
    private socket!: SocketIOClient.Socket;
    public config(config: IConfig) {
        this.localConfig = Object.assign({path: '/socket.io'}, config);

        this.routes = {
            'route/list': this.getRoutes.bind(this),
        };
    }
    public addRoutes(routes: any) {
        routes = objectToRoutes(routes);
        this.routes = Object.assign(this.routes, routes);
    }
    public boot() {
        let socket: SocketIOClient.Socket;
        const options =  {
            path: this.localConfig.path,
            query: {
                token: auth.token(),
            },
          };
        socket = (SocketIOClient as any)(this.localConfig.url, options);
        socket.on('connect', () => {
           this.onConnect();
        });
        socket.on('disconnect', () => {
           this.onDisconnect();
        });
        (socket as any).onAny((event: string, ...parameters: any[]) => {
           this.onData(event, parameters);
        });
        this.socket = socket;
    }
    public route(path: string): any {
        return this.routes[path];
    }
    public onConnect() {
        make('store').commit('socket/setState', true);
        console.log('connected');
    }
    public onDisconnect() {
        make('store').commit('socket/setState', false);
        console.log('disconnected');
    }
    public onData(event: string, parameters: any[]) {
        console.log(event);
        const route = this.route(event);
        let callback = parameters[parameters.length - 1];
        if (typeof callback != 'function') {
            callback = null;
        }
        if (!route) {
            if (callback) {
                callback({error: `${event} not found`});
            }
            console.log(`${event} not found`);
            return;
        }
        if (callback) {
            let promise;
            try {
                promise = route(...parameters);
                if (!promise || typeof promise != 'object' || typeof promise.then != 'function') {
                    promise = Promise.resolve(promise);
                }
            } catch (error) {
                promise = Promise.reject(error);
            }
            promise.then(function(data: any) {
                callback({data});
            }, function(error: any) {
                callback({error});
            },
            );
        } else {
            try {
                route(...parameters);
            } catch (error) {
                console.log(`${event} error`, error);
            }
        }
    }
    public get(event: string, ...parameters: any[]) {
        return new Promise((resolve, reject) => {
            this.emit(event, ...parameters, function(result: IResult) {
                if (result.error) {
                    reject(result.error);
                } else {
                    resolve(result.data);
                }
            });
        });
    }
    public emit(event: string, ...parameters: any[]): void {
        this.socket.emit(event, ...parameters);
    }
    public getRoutes(callback: Function): any {
       return Object.keys(this.routes);
    }
}
export const socket = new Socket();
