
import chalk from 'chalk' ;
import debug from 'debug' ;
import { local } from '@/storage/local';

if (!local.getItem('debug')) {
  local.setItem('debug', '*, -sockjs-client:*');
}

let time = -1;
function logger(name: string): debug.Debugger {
  const instance = debug(name);
  const wrapper: any = function(...params: any[]) {
    if (time == -1) {
      time = Date.now();
    }
    params = params.map((item) => {
      return item;
    });
    params.push(chalk.red('(' + (Date.now() - time) + 'ms)'));
    time = Date.now();
    return (instance as any)(...params);
  };
  wrapper.extend = function(subname: string): debug.Debugger {
    return logger(`${name}:${subname}`);
  };
  Object.defineProperty(wrapper, 'log', {
    get() {
      return instance.log;
    },
    set(value) {
      instance.log = value;
    },
  });
  Object.defineProperty(wrapper, 'enabled', {
    get() {
      return instance.enabled;
    },
  });
  wrapper.label = name;
  return wrapper;
}
export { logger };
