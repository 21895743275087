





































import Vue from 'vue';
import { mapGetters } from 'vuex';
import NotificationsList from '@/components/Notifications/list.vue';
import MatchMakingRules from '@/components/Rules/MatchMakingRules.vue';
import { auth } from '@/auth';

export default Vue.extend({
  name: 'Header',
  components: {
    NotificationsList,
    MatchMakingRules,
  },
  props: {
    logoOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('user', ['matchMaking']),
    logoLink() {
      if (auth.check()) {
        return 'home';
      }
      return 'landingPage';
    },
  },
  methods: {
    redirect() {
      if (this.matchMaking.challenge) {
        this.redirectMatch(this.matchMaking.challenge);
      } else {
        this.redirectMatchMaking();
      }
    },
    redirectMatchMaking(): void {
      if (this.$route.name == 'matchMaking' && (this.$route.params && this.$route.params.gameSlug == 'pubg')) { return; }
      this.$router.push({ name: 'matchMaking', params: { gameSlug: 'pubg' }});
    },
    redirectMatch(challenge) {
      if (this.$route.name == 'match' && (this.$route.params && this.$route.params.gameSlug == 'pubg' && this.$route.params.challengeId == challenge.uuid)) { return; }
      this.$router.push({ name: 'match', params: { gameSlug: 'pubg', challengeId: challenge.uuid, currentChallenge: challenge }});
    },
  },
});
