var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"updatePassword"},[_c('h1',[_vm._v(_vm._s(_vm.$t('updatePassword.title')))]),(!_vm.success)?_c('ValidationObserver',{staticClass:"form-wrapper",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{ref:"resetPassword",on:{"submit":function($event){$event.preventDefault();_vm.success || _vm.loading ? null : handleSubmit(_vm.sendForm)}}},[_c('n-Card',[(_vm.error)?_c('n-ErrorLabel',[[_vm._v(_vm._s(_vm.error))]],2):_vm._e(),_c('ValidationProvider',{staticClass:"validationProvider",attrs:{"name":"mot de passe actuel","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('n-InputPassword',{ref:"newPassword",attrs:{"label":_vm.$t('updatePassword.oldPassword.label'),"placeholder":_vm.$t('updatePassword.oldPassword.placeholder'),"name":"old_password","error":errors[0],"isNew":false,"required":""},on:{"input":reset,"focus":reset},model:{value:(_vm.form.old_password),callback:function ($$v) {_vm.$set(_vm.form, "old_password", $$v)},expression:"form.old_password"}})]}}],null,true)}),_c('br'),_c('ValidationProvider',{staticClass:"validationProvider",attrs:{"name":"password","rules":"required|password","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('n-InputPassword',{ref:"password",attrs:{"label":_vm.$t('updatePassword.newPassword.label'),"placeholder":_vm.$t('updatePassword.newPassword.placeholder'),"name":"password","error":errors[0],"isNew":true,"required":""},on:{"input":reset,"focus":reset},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"validationProvider",attrs:{"name":"confirmation","rules":"required|confirmPassword:@password","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('n-InputPassword',{ref:"passwordConf",attrs:{"label":_vm.$t('updatePassword.confirmPassword.label'),"placeholder":_vm.$t('updatePassword.confirmPassword.placeholder'),"name":"confirmation","error":errors[0],"isNew":true,"required":""},on:{"input":reset,"focus":reset},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}})]}}],null,true)})],1)],1),(!_vm.success)?_c('n-Button',{staticClass:"submit",attrs:{"loading":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.sendForm)}}},[_vm._v(" "+_vm._s(_vm.$t('updatePassword.validationButton'))+" ")]):_vm._e()]}}],null,false,3297485382)}):_c('div',{staticClass:"success"},[(_vm.success)?_c('p',[_vm._v(_vm._s(_vm.success))]):_vm._e(),_c('div',{staticClass:"bg-logo"},[_c('img',{attrs:{"src":require("@/assets/img/logo/noobizes-manette.png"),"alt":""}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }