export function isClass(func: any): boolean {
    const str = Function.prototype.toString.call(func);
      // es6 or webpack or rollup
    return typeof func === 'function'
      && (/^class\s/.test(str) ||  /_createClass_/.test(str) ||  /classCallCheck/.test(str)
  || /Object\(_rollupPluginBabelHelpers_/.test(str)
      );
  }

export function getName(cls: any): string {
      // throw new Error('can\'t use class getName due to minification process');
      if (cls && cls.constructor && cls.constructor.name) {
        if (cls.constructor.name.indexOf('_')) {
          const name = cls.constructor.name.split('_');
          return name[name.length - 1];
        }
        return cls.constructor.name;
      }

      // es6
      const clsText = cls + '';
      let funcNameRegex: RegExp = /function ([^\(]{1,})\(/;
      let results: any = funcNameRegex.exec(clsText);
      if (results && results.length > 1) {
        return results[1];
      }
      funcNameRegex = /class ([^\({]{1,}){/;
      results = funcNameRegex.exec(clsText);
      if (results != null && (results && results.length > 1)) {
        return results[1].trim();
      }
      if (cls && cls.constructor && cls.constructor.name) {
        return cls.constructor.name;
      }
      return '';
    }


export function methods(toCheck: any): any[] {
      let props: any[] = [];
      let obj = toCheck;
      do {
          props = props.concat(Object.getOwnPropertyNames(obj));
          obj = Object.getPrototypeOf(obj);
      } while (obj);

      return props.sort().filter(function(e, i, arr) {
         if (e != arr[i + 1] && typeof toCheck[e] == 'function') { return true; }
      });
  }
