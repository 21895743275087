
























































import Vue from 'vue';
import { api } from '@/io/api';

export default Vue.extend({
  name: 'resetPassword',
  data() {
    return {
      email: null,
      error: null,
      loading: false,
      success: null,
    };
  },
  methods: {
    async sendForm() {
      this.loading = true;
      try {
        await api()
          .route('user/forgotPassword')
          .params({ email: this.email, locale: this.$i18n.locale })
          .post();
        this.error = null;
        this.success = this.$t('resetPassword.success.send', { email: this.email });
      } catch (error) {
        let err = error.message ? error.message : 'error.server';
        if (!error.fatal) {
          err = 'error.server';
        }
        this.error = this.$t(err);
      }
      this.loading = false;
    },
  },
});
