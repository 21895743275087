








































import Vue from 'vue';
import Debug from '@/components/Mixins/Debug.vue';
import styles from '@nbzify/scss/_exports.scss';

import { auth } from '@/auth/index';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'n-ChallengeLine',
  mixins: [ Debug ],
  props: {
    challenge: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    background: {
      type: String,
      required: true,
      default: 'light',
    },
  },
  data() {
    return {
    };
  },
  computed : {
    ...mapGetters('games', ['games']),
    game(): any {
      return this.games.find((g) => g.id == this.challenge.game_id);
    },
    customStyle() {
      let bgColor: string = '';
      if (this.background === 'light') {
        bgColor = styles.colorGreyLight;
      }
      if (this.background === 'dark') {
        bgColor = styles.colorGreyMedium;
      }
      return {
        '--bg-color': bgColor,
      };
    },
    opponent(): any|null {
      return this.challenge.participants.find((p) => p.user.id != auth.id());
    },
    mode(): any {
      if (!this.game || !this.challenge || !this.challenge.mode_id) { return; }
      return this.game.modes.find((m) => m.id == this.challenge.mode_id);
    },
  },
});
