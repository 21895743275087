






















import * as vClickOutside from 'v-click-outside';
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import Checkbox from '../../InputElements/Checkbox/Checkbox.vue';
// import PerfectScrollbar from 'vue2-perfect-scrollbar';
// import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

Vue.use(vClickOutside);

@Component({
  name: 'n-NotificationMenu',
  components: {
    Checkbox,
  },
})
export default class NotificationMenu extends Vue {

  public vcoConfig = {
    handler: this.clickOutsideHandler,
    middleware: this.clickOutsideMiddleware,
    events: ['dblclick', 'click'],
    isActive: true,
  };
  /**
   * Prop to fill title of notifications header
   */
  @Prop({
    type: String,
    required: false,
    default: 'Notifications',
  })
  private readonly title!: string;

  /**
   * Prop to fill checkboxTitle
   */
  @Prop({
    type: String,
    required: false,
    default: 'J\'ai tout vu, merci.',
  })
  private readonly checkboxTitle: string;

  /**
   * Prop to count not read notification
   */
  @Prop({
    type: Number,
    required: false,
    default: 0,
  })
  private readonly count: number;

  private show: boolean = false;

  private allRead: boolean = false;

  /**
   *
   * Event to make all notifications as readed
   *
   * @event readAll
   */
  @Emit('readAll')
  public emitReadAll(event) {
    this.$nextTick(() => {
      setTimeout(() => {
        this.allRead = false;
      }, 800);
    });
    return event;
  }

  public clickOutsideHandler(): void {
    if (this.show) {
      this.show = false;
    }
  }

  public clickOutsideMiddleware(event: Event): boolean {
    return (
      (event.target as HTMLTextAreaElement).parentElement.className !== 'notification-menu__content-header' &&
      (event.target as HTMLTextAreaElement).parentElement.className !== 'notification-menu__content-wrapper'
    );
  }
}
