import { make } from '@/app/make';
import { api } from '@/io/api';
import { local } from '../storage/local';
import { socket } from '@/io/socket';

interface IState {
    error: any;
    user: any;
    initialized: any;
}


export const state: IState = {
    error: null,
    user: local.getItem('user'),
    initialized: false,
};
export const getters = {
    error(state: IState) {
        return state.error;
    },
    user(state: IState) {
        return state.user;
    },
    isAdmin(state: IState) {
        return state.user && state.user.role.includes('admin');
    },
};
export const actions = {
    async login({  commit   }: any, {login_id, password}: {login_id: string, password: string}) {
        commit('clearError');
        try {
            const user = await api().route('user/login').params({login_id, password}).post();
            commit('setUser', user);
            socket.boot(); // init user socket
            return;
        } catch (error) {
            commit('clearUser');
            commit('setError', error.message);
            return Promise.reject(error);
        }
    },
    async logout({commit}) {
        await api().route('user/logout').post();
        local.removeItem('user');
        return;
    },
    async initialize({state, commit, dispatch}) {
        if (state.initialized) {
            return; // already initialized
        }
        if (!state.user) {
            commit('initialized');
            return; // nothing to do
        }
        try {
            const user = await api().route('user/me').get();
            user.api_token = state.user.api_token;
            commit('setUser', user);
        } catch (error) {
            commit('clearUser');
            dispatch('logout').finally(() => {
                window.location.reload();
            });
        }
        commit('initialized');
    },
    async update({ commit }: any, { form }: { form: any }) {
        commit('clearError');
        let query = api().route('user/update');
        if (form.avatar) {
            query = query.file('avatar', form.avatar);
            delete form.avatar;
        }
        try {
            const user = await query.params(form).post();
            commit('setUser', user);
            return;
        } catch (error) {
            commit('setError', error.message);
            return Promise.reject(error);
        }

    },
    async credit({ commit }: any) {
        try {
            const credit = await api().route('user/credit').get();
            commit('setCredit', { credit, setLocalStorage: true });
        } catch (error) {
            commit('setError', error.message);
        }
    },
};
export const mutations = {
    initialized(state) {
        state.initialized = true;
    },
    setError(state, message) {
        state.error = message;
    },
    clearError(state) {
        state.error = null;
    },
    setUser(state, user) {
        let usr;
        user.credit = parseInt(user.credit, 10);
        if (state.user && state.user.id == user.id) {
            usr = Object.assign({}, state.user, user);
        } else {
            usr = user;
        }
        state.user = usr;
        local.setItem('user', usr);
    },
    updateCredit(state, {amount, setLocalStorage = false}) {
        state.user.credit += amount;
        if (setLocalStorage) {
            local.setItem('user', state.user);
        }
    },
    setCredit(state, {credit, setLocalStorage = false}) {
        state.user.credit = parseInt(credit, 10);
        if (setLocalStorage) {
            local.setItem('user', state.user);
        }
    },
    clearUser(state) {
        state.user = null;
        local.removeItem('user');
    },
};
export const namespaced = true;
