

















import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({
  name: 'n-Notification',
})
export default class Notification extends Vue {
  /**
   * Boolean to show notification
   */
  @Prop({
    type: Boolean,
    required: true,
    default: false,
  })
  private readonly show!: boolean;

  /**
   * Date time to pass at format that moment can parse
   */
  @Prop({
    type: String,
    required: false,
  })
  private readonly time!: string;

  /**
   * Labels for trad {day: 'jour', days: 'jours' } by default
   */
  @Prop({
    type: Object,
    required: false,
    default: () => ({ day: 'jour', days: 'jours', month: 'mois', months: 'mois', year: 'an', years: 'ans' }),
  })
  private readonly labels: any;

  /**
   * Boolean to change design if notification was read
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  private readonly read!: boolean;
}
