import { local } from '../storage/local';
import {api} from '@/io/api';

interface IState {
    error: string|null;
    connected: boolean;
}
export const state: IState = {
    error: null,
    connected: false,
};
export const getters = {
    connected(state: IState) {
        return state.connected;
    },
};
export const actions = {
};
export const mutations = {
    setState(state: IState, connected: boolean) {
        state.connected = connected;
    },
    setError(state: IState, message: string) {
        state.error = message;
    },
    clearError(state: IState) {
        state.error = null;
    },
};
export const namespaced = true;
