import { make } from '@/app/make';

export function link({gameAccount}) {
    make('store').dispatch('gameAccount/setGame', { game: gameAccount });
  }
export async function linkError({error}) {
  try {
    await make('store').dispatch('gameAccount/handleError', { error });
  } catch (e) {
    console.log(e);
  }
}
