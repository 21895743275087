










import Vue from 'vue';


export default Vue.extend({
  name: 'gameCard',
  props: {
    game: {
      required: true,
      type: Object,
    },
  },
});
