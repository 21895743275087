import { extend } from 'vee-validate';

extend('confirmPassword', {
  params: ['target'],
  validate(value: string, {target}: any) {
    return value === target;
  },
  message: `Les mots de passe ne correspondent pas`,
});

extend('password', {
  validate(value: string) {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?=.{8,})/.test(value);
  },
  message: `Votre mot de passe doit être composé de 8 caractères minimum / une minuscule, une majuscule, un chiffre, un caractère spécial.`,
});
