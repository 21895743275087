












import Vue from 'vue';

export default Vue.extend({
  name: 'product',
  props: {
    product: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  computed: {
    iconsNumber(): number {
      return this.index ? this.index + 1 : 1;
    },
  },
});
