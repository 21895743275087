import InputSelect from './InputSelect.vue';
import InputSelectOption from './InputSelectOption.vue';

const inputSelect = {
  InputSelect,
  InputSelectOption,
};

// @ts-ignore
for (const elem in inputSelect) {
  if (inputSelect.hasOwnProperty(elem)) {
    const element = (inputSelect as any)[elem];
    // @ts-ignore
    element.install = (Vue) => {
      Vue.component((element as any).options.name, element);
    };
  }
}

export default inputSelect;
