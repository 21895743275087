import { api } from '@/io/api';

interface IState {
    games: any[];
    idGameConnectTimeout: number | null;
    isGameConnectProcessing: boolean;
    gamesAccountsInitialized: boolean;
    gamesInitPromise: any;
    gameConnectPromise: {
        promise: Promise<any> | null,
        resolve: Function | null,
        reject: Function | null,
    };
    error: any | null;
    linkError: boolean;
}
export const state: IState = {
    games: [],
    idGameConnectTimeout: null,
    isGameConnectProcessing: false,
    gamesAccountsInitialized: false,
    gamesInitPromise: null,
    gameConnectPromise: {
        promise: null,
        resolve: null,
        reject: null,
    },
    error: null,
    linkError: false,
};
export const getters = {
    games(state: IState) {
        return state.games;
    },
    gamesAccountsInitialized(state: IState) {
        return state.gamesAccountsInitialized;
    },
    isGameConnectProcessing(state: IState) {
        return state.isGameConnectProcessing;
    },
    gameConnectPromise(state: IState) {
        return state.gameConnectPromise;
    },
    gamesInitPromise(state: IState) {
        return state.gamesInitPromise;
    },
    error(state: IState) {
        return state.error;
    },
    linkError(state: IState) {
        return state.linkError;
    },
};
export const actions = {
    /**
     * Get user's connected games
     */
    async games({ commit, getters }: any) {
        commit('clearErrors');
        if (getters.gamesInitPromise) { return getters.gamesInitPromise; }

        try {
            const promise =  api().route('/user/games').get();
            commit('setGamesInitPromise', promise);

            const games = await promise;
            commit('setGames', games);
            commit('setGamesAccountsInitialized', true);
            return;
        } catch (error) {
            // commit('clearGames');
            commit('setError', error.message);
            commit('setGamesAccountsInitialized', true);
            return;
        }
    },
    /**
     * Create new job for connecting game
     */
    async createJobConnect({ commit, dispatch }: any, { slug, name, platformId, gameId }: { slug: string, name: string, platformId: number, gameId: number }) {
        commit('clearErrors');
        try {
            const data = await api().route('/game/' + slug + '/jobConnect').params({ name, platform_id: platformId, game_id: gameId }).post();
            return dispatch('getGameConnect', {gameId, platformId, jobId: data.job_id});

        } catch (error) {
            commit('setError', error.message);
            return Promise.reject(error);
        }
    },
    async getGameConnect({ commit, state, dispatch }: any, { gameId, platformId, jobId }: { gameId: number, platformId: number, jobId: number }) {
        commit('clearErrors');
        if (!state.gameConnectPromise.promise) {
            commit('setIsGameConnectProcessing', true);
            state.gameConnectPromise.promise = new Promise((resolve, reject) => {
                state.gameConnectPromise.resolve = (p) => {
                    state.gameConnectPromise.promise = null;
                    resolve(p);
                };
                state.gameConnectPromise.reject = (p) => {
                    state.gameConnectPromise.promise = null;
                    reject(p);
                };
            });
        }
        try {
            if (state.games.length) { return; }
            const game = await api().route('/gameAccount').params({ game_id: gameId, platform_id: platformId }).get();
            if (game) {
                return dispatch('setGame', { game });
            }

            // Check for errors
            dispatch('getConnectError', {jobId, gameId, platformId});
            return state.gameConnectPromise.promise;

        } catch (error) {
            console.log('error', error);
            commit('setError', error.message);
            return Promise.reject(error);
        }
    },
    setGame({ commit }: any, { game }: { game: any }) {
        // Game is now connected
        if (!state.gameConnectPromise.promise) {
            return; // return via socket
        }
        commit('addGame', game);
        clearTimeout(state.idGameConnectTimeout);
        commit('setIdGameConnectTimeout', null);
        commit('setError', null);
        commit('setIsGameConnectProcessing', false);

        state.gameConnectPromise.resolve(game);
        return state.gameConnectPromise.promise;
    },
    async disconnectGame({ commit, getters, dispatch }: any, { gameAccountId, forceEndParticipants }: { gameAccountId: number, forceEndParticipants: boolean }) {
        commit('clearErrors');
        try {
            await api().route('/gameAccount/disconnect').params({ game_account_id: gameAccountId, force_end_participants: forceEndParticipants }).post();
            const gameAccount = getters.games.find((g) => g.id == gameAccountId);
            commit('removeGame', gameAccount);
            commit('user/clearChallenges', null, { root: true });
            commit('user/clearMatchMaking', null, { root: true });
            if (state.isGameConnectProcessing) { // Something game connect is processing, cant find why
                commit('setIsGameConnectProcessing', false);
            }
            if (forceEndParticipants) {
                dispatch('auth/credit', null, { root: true });
            }
        } catch (error) {
            commit('setError', error.message);
            return Promise.reject(error);
        }
    },
    async getGameConnectJob({ commit, dispatch }: any) {
        commit('clearErrors');
        try {
            const job = await api().route('/job').params({ type: 'connect', state: ['new', 'running'] }).get();
            if (job && job.game_id) {
                return dispatch('getGameConnect', {gameId: job.game_id, platformId: job.platform_id, jobId: job.id});
            }
        } catch (error) {
            commit('setError', error.message);
            return Promise.reject(error);
        }
      },
      async getConnectError({ commit, state, dispatch }: any, { jobId, gameId, platformId }: { jobId: number, gameId: number, platformId: number }) {
        commit('clearErrors');
        try {
            const error = await api().route('getError').params({ type: 'connect', job_id: jobId }).get();
            if (!error) {
                const idTimeout = setTimeout(() => {
                    dispatch('getGameConnect', {gameId, platformId, jobId});
                }, 3000);
                commit('setIdGameConnectTimeout', idTimeout);
                return;
            }

            dispatch('handleError', { error });

        } catch (error) {
            commit('setError', error.message);
            commit('setIsGameConnectProcessing', false);
            clearTimeout(state.idGameConnectTimeout);
            commit('setIdGameConnectTimeout', null);
            return Promise.reject(error); // remove ?
        }
      },
      handleError({ commit , state }: any, { error }: { error: any }) {
        if (!state.gameConnectPromise.promise) {
            return; // return via socket
        }
        commit('setIsGameConnectProcessing', false);
        clearTimeout(state.idGameConnectTimeout);
        commit('setIdGameConnectTimeout', null);

        if (error.payload) {
            error.payload = JSON.parse(error.payload);
        }
        if (error.message == 'connector.error.link') {
            commit('setLinkError', true); // User is already taken by an other user
            commit('setError', error);
        } else if (error.message == 'connector.error.notFound') {
            commit('setError', error); // User not found
        }

        const promise = state.gameConnectPromise.promise;
        state.gameConnectPromise.reject(error);
        return promise;
      },
};
export const mutations = {
    setGames(state: IState, games: any) {
        state.games = games;
    },
    addGame(state: IState, game: any) {
        state.games.push(game);
    },
    removeGame(state: IState, game: any) {
        const index = state.games.findIndex((g: any) => g.id == game.id);
        if (index > -1) {
            state.games.splice(index, 1);
        }
    },
    setIdGameConnectTimeout(state, value) {
        state.idGameConnectTimeout = value;
    },
    setIsGameConnectProcessing(state, value) {
        state.isGameConnectProcessing = value;
    },
    setGamesAccountsInitialized(state, value) {
        state.gamesAccountsInitialized = value;
    },
    setGamesInitPromise(state, value) {
        state.gamesInitPromise = value;
    },
    clearGames(state) {
        state.games = null;
    },
    setError(state, message) {
        state.error = message;
    },
    setLinkError(state, value) {
        state.linkError = value;
    },
    clearErrors(state) {
        state.error = null;
        state.linkError = false;
    },
};
export const namespaced = true;
