
















import Vue from 'vue';
import connector from '@/components/Games/Connector.vue';
import loader from '@/components/General/Loader.vue';

import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'games',
  components: {
    connector,
    loader,
  },
  computed: {
    ...mapGetters('games', ['games']),

    hasGames(): boolean {
      if (!this.games) { return false; }
      return !!this.games.length;
    },
  },
});
