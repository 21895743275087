





import { Vue, Component, Prop } from 'vue-property-decorator';
import styles from '@nbzify/scss/_exports.scss';

@Component({
  name: 'n-Avatar',
})
export default class Avatar extends Vue {
  /**
   * Image pour l'avatar au format string
   */
  @Prop({
    type: String,
    required: false,
  })
  public avatar!: string;

  /**
   * Taille de l'avatar en bigger 112x112 + 4px border;
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public bigger!: boolean;

  /**
   * Taille de l'avatar en medium 44x44
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public medium!: boolean;

  /**
   * Taille de l'avatar small 36x36
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public small!: boolean;

  /**
   * Permet d'enlever les bordures
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public noBorder!: boolean;

  /**
   * Permet d'enlever les bordures
   */
  @Prop({
    type: Number,
    required: false,
    // default: 2,
  })
  public borderWidth!: boolean;

  /**
   * Avatar for unknown opponent
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public unknown!: boolean;

  /**
   * Permet de changer la couleur de la bordure
   * La couleur est défini par son nom dans le guide Colors du design system
   */
  @Prop({
    type: String,
    required: false,
    default: 'Primary',
  })
  public borderColor!: string;

  get isNoBorder(): string {
    return this.noBorder ? 'noBorder' : '';
  }

  get isSmall(): string {
    return this.small ? 'small' : '';
  }

  get isMedium(): string {
    return this.medium ? 'medium' : '';
  }

  get isBigger(): string {
    return this.bigger ? 'bigger' : '';
  }

  get customStyle() {
    return {
      'border-color': styles[`color${this.borderColor}`],
      'borderWidth': this.borderWidth + 'px',
    };
  }

  get image() {
    if (this.unknown) { return { background: 'url(' + require('./assets/unknown-avatar.svg') + ') center / cover no-repeat #282b31' }; }
    if (this.avatar) { return { background: 'url(' + this.avatar + ') center / cover no-repeat #282b31' }; }
    return { background: 'url(' + require('./assets/default-avatar.png') + ') center / 80% auto no-repeat #282b31'};
  }
}
