
































































import Vue from 'vue';
import { ResetPasswordForm } from '@/services/userSpace/interfaces/ResetPasswordForm.interface';
import { api } from '@/io/api';
import { LocaleMessages } from 'vue-i18n';

export default Vue.extend({
  name: 'resetPasswordConf',
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        password: null,
        password_confirmation: null,
        token: null,
        locale: this.$i18n.locale,
      } as ResetPasswordForm,
      error: null,
      loading: false,
      success: null,
    };
  },

  mounted() {
    this.form.token = this.token;
  },
  methods: {
    async sendForm() {
      this.loading = true;
      try {
        await api()
          .route('user/resetPassword')
          .params(this.form)
          .patch();
        this.error = null;
        this.success = this.$t('resetPassword.success.reset');
      } catch (error) {
        let err = error.message ? error.message : 'error.server';
        if (!error.fatal) {
          err = 'error.server';
        }
        this.error = this.$t(err);
      }
      this.loading = false;
    },

    redirect() {
      this.$router.push('/');
    },
  },
});
