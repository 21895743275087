






















































import Vue from 'vue';
import Networks from '@/components/Footer/Networks.vue';

export default Vue.extend({
  name: 'Footer',
  components: {
    Networks,
  },
  props: {
    isLarge: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
