




















import Vue from 'vue';
import { mapGetters } from 'vuex';
import IconSettings from '@/components/Icons/Settings.vue';
import { config } from '@/env/Configuration';
import { auth } from '@/auth/index';

export default Vue.extend({
  components: { IconSettings },
  computed: {
    ...mapGetters('auth', ['user']),
    isAdmin(): boolean {
      return auth.isAdmin();
    },
    activePayment(): boolean {
      return config('app.nbz.activePayment') || this.isAdmin;
    },
    avatar() {
      return this.user.avatar_url ? this.user.avatar_url : null;
    },
  },
  methods: {
    redirectDeposit() {
      if (this.$route.name != 'deposit') {
        this.$router.push({ name: 'deposit' });
      }
    },
  },

});
