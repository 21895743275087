























































import Vue from 'vue';
import loadGame from '@/components/Mixins/loadGame.vue';
import loader from '@/components/General/Loader.vue';

import { mapGetters, mapMutations } from 'vuex';

export default Vue.extend({
  name: 'pools',
  mixins: [loadGame],
  components: { loader },
  props: {
    gameSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      platformId: null,
      bet: null,
      pools: [
        {
          min: 1,
          max: 20,
        },
        {
          min: 21,
          max: 50,
        },
        {
          min: 51,
          max: 100,
        },
      ],
      loading: true,
      searching: false,
      game: null, // on mixin gameLoaded
    };
  },
  async created() {
    if (this.gamesAccounts && !this.multiPlatform) {
      this.platformId = this.gamesAccounts[0].platform.id;
    }
    if (this.matchMaking) {
      this.redirect(this.matchMaking);
      return;
    }
    let matchMaking;
    if (!this.matchMakingInitialized) {
      try {
        matchMaking = await this.$store.dispatch('user/getMatchMaking', { gameId: this.game.id });
      } catch (e) {
        console.log(e);
        matchMaking = null;
      }
    }
    if (matchMaking) {
      this.redirect(matchMaking);
      return;
    }
    this.loading = false;
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('gameAccount', {gamesAccounts: 'games'}),
    ...mapGetters('user', ['matchMaking']),
    ...mapGetters('user', ['matchMakingInitialized']),
    multiPlatform() {
      if (this.gamesAccounts?.length > 1) { return true; }
      return false;
    },
    needPlatform(): Boolean {
      if (this.bet && this.multiPlatform) {
        return true;
      }
      return false;
    },
    hasCredit() {
      if (!this.user?.credit || !this.bet) {
        return false;
      }
      if (this.user.credit < this.bet) {
        return false;
      }
      return true;
    },

  },
  watch: {
    gamesAccounts(games) {
      if (!games) { return; }
      if (!this.multiPlatform) {
        this.platformId = games[0].platform_id;
      }
    },
    bet(newValue, oldValue) {
      if (!oldValue || !newValue) { return; }
      if (newValue != oldValue) {
        this.platformId = null;
      }
    },
  },
  methods: {
    ...mapMutations('auth', ['updateCredit']),
    addBet(bet: number): void {
      this.bet = bet;
      if (!this.multiPlatform) {
        this.start();
      }
    },
    async start(): Promise<void> {
      if (this.searching) {
        return;
      }
      this.searching = true;
      if (!this.hasCredit) {
        Vue.$toast.open({
          message: (this.$t('error.lowCredit') as string),
          type: 'error',
        });
        this.clearForm();
        this.searching = false;
        return;
      }
      try {
        (this as any).updateCredit({ amount: -this.bet });
        const matchMaking = await this.$store.dispatch('user/startMatchMaking', {
          gameId: this.game.id,
          bet: this.bet,
          platformId: this.platformId,
        });

        if (matchMaking) {
          this.redirect(matchMaking);
        }

      } catch (error) {
        this.clearForm();
        let err = this.$t(error.message);
        if (error.message != 'error.lowCredit') {
          err = this.$t(error.server);
        }
        Vue.$toast.open({
          message: (err as string),
          type: 'error',
        });
      }
      this.searching = false;
    },
    redirect(matchMaking) {
      if (matchMaking.challenge) {
        this.redirectMatch(matchMaking.challenge);
      } else {
        this.redirectMatchMaking();
      }
    },
    redirectMatchMaking(): void {
      console.log('Pools: redirect matchMaking', this.matchMaking);
      this.$router.push({ name: 'matchMaking', params: { gameSlug: this.gameSlug }});
    },
    redirectMatch(challenge) {
      console.log('Pools: redirect match');
      this.$router.push({ name: 'match', params: { gameSlug: this.gameSlug, challengeId: challenge.uuid, currentChallenge: challenge }});
    },
    isActive(pool) {
      return !!(this.bet && this.multiPlatform && (this.bet == pool.max));
    },
    clearChoice(e) {
      if (!this.multiPlatform || !this.bet) { return; }
      let target: any = e.target;
      while (target) {
        if ((this.$refs.pool as any).indexOf(target) != -1) {
          return;
        }
        target = target.parentElement;
      }
      this.clearForm();
    },
    clearForm() {
      this.bet = null;
      if (!this.multiPlatform) {
        this.platformId = this.gamesAccounts[0].platform_id;
      } else {
        this.platformId = null;
      }
    },
  },

});
