

























































import Vue from 'vue';
import { mapGetters } from 'vuex';
import IconSettings from '@/components/Icons/Settings.vue';
import IconLogout from '@/components/Icons/Logout.vue';
import { auth } from '@/auth/index';
import { config } from '@/env/Configuration';

export default Vue.extend({
  name: 'side-menu',
  components: { IconSettings, IconLogout },
  data() {
    return {
      currentGame: null,
      isOpen: true, // Side menu pubg open by default
    };
  },
  created() {
    if (this.$route.params.gameSlug) {
      this.currentGame = this.$route.params.gameSlug,
      this.isOpen = true;
    }
  },
  computed: {
    ...mapGetters('gameAccount', {gameAccounts : 'games'}),
    hasGame(): boolean {
      if (!this.gameAccounts) { return false; }
      return !!this.gameAccounts.length;
    },
    gamesConnected(): any {
      let gamesConnected = {};
      if (this.hasGame) {
        gamesConnected = this.gameAccounts.reduce((prev, el) => {
          prev[el.game.slug] = el.game;
          return prev;
        }, {});
      }
      return Object.values(gamesConnected);
    },
    isAdmin(): boolean {
      return auth.isAdmin();
    },
    activePayment(): boolean {
      return config('app.nbz.activePayment') || this.isAdmin;
    },
  },
  methods: {
    selectGame(gameSlug: string): void {
      this.currentGame = gameSlug;
    },
    async logout(): Promise<void> {
      await auth.logout();
      window.location.reload();
    },
  },
});
