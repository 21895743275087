
import Compressor from 'compressorjs';

/**
 * Compress file and reduce image size
 * @param {Any} file Original file
 * @param {Number} maxSize Optional - Max image size (width or height)
 */
export function compressFile(file, maxSize = null) {
  return new Promise((resolve, reject) => {

    const reader = new FileReader();
    // Read the contents of Image File.
    reader.readAsDataURL(file);
    reader.onload = function(e) {

      // Initiate the JavaScript Image object.
      const image: any = new Image();

      // Set the Base64 string return from FileReader as source.
      image.src = e.target.result;

      // Validate the File Height and Width.
      image.onload = function() {
        let sizeOption = {};
        const maxDpi = 3;

        if (maxSize) {
          if (this.width <= this.height) {
            sizeOption = { height: maxSize * maxDpi };
          } else {
            sizeOption = { width: maxSize * maxDpi };
          }
        }

        return new Compressor(file, {
          ...sizeOption,
          quality: 0.6,

          // The compression process is asynchronous,
          // which means you have to access the `result` in the `success` hook function.
          success(result) {
            return resolve(result);
          },
          error(err) {
            console.log('err', err);
            return reject(err);
          },
        });
      };
    };
  });
}
