
























import Vue from 'vue';


export default Vue.extend({
  name: 'dotations',
  props: {
    bet: {
      type: Number,
      required: true,
    },
    totalPlayers: {
      type: Number,
      required: false,
      default: 2,
    },
  },
  computed: {
    totalBet(): number {
      return this.bet * this.totalPlayers;
    },
  },
});
