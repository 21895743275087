export const local = {
    getItem(name: string): any {
        const item = localStorage.getItem(name);
        if (item) {
            try {
                return JSON.parse(item);
            } catch (error) {
                return null;
            }
        }
        return item;
    },
    setItem(name: string, value: any): void {
        return localStorage.setItem(name, JSON.stringify(value));
    },
    removeItem(name: string): void {
        return localStorage.removeItem(name);
    },
    hasItem(name: string): boolean {
        return !!localStorage.getItem(name);
    },
    clear(): void {
       return localStorage.clear();
    },
};
