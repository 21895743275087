import { clone, merge } from '../utils/Objects';

export class Configuration {

    private data: any;
    constructor() {
        this.data = {};
    }
    public keys() {
        return this.buildKeys(this.data);
    }
    public buildKeys(data: any, prefix = '') {
        let keys = Object.keys(data);
        keys = keys.reduce((previous, key) => {
          const item = data[key];
          if (item && typeof item == 'object') {
            previous = previous.concat(this.buildKeys(item, prefix + key + '.'));
          }
          return previous;
        }, keys);
        return keys.map((item) => prefix + item);
    }
    public env(): string {
        return this.get('app.environment');
    }
    public isDebug(): boolean {
        const isDebug = this.get('app.debug');
        if (isDebug != undefined) {
            return isDebug;
        }
        return (
          !this.isProduction()
        );
    }
    public isLocal(): boolean {
        return !!~this.env().indexOf('local');
      }
    public isProduction(): boolean {
        return !!~this.env().indexOf('production');
      }
       public merge(data: any): any;
       public merge(key: string|any, data?: any): any {
        if (typeof key == 'string') {
          if (this.data[key]) {
            this.data[key] = merge(this.data[key], data);
          } else {
            this.data[key] = data;
          }
        } else {
          data = key;
          this.data = merge(this.data, data);
        }
        return this.get();
      }
       public has(key: string): boolean {
        if (!key) {
          return false;
        }
        const keys: string[] = key.split('.');
        // not last
        key = (keys.pop() as string);
        let current = this.data;
        for (const k of keys) {
          if (current[k] == null) {
            current[k] = {};
          }
          if (typeof current[k] != 'object') {
            current[k] = {};
          }
          current = current[k];
        }
        return current[key] != undefined;
      }
       public get(key?: string, defaultValue: any = null): any {
        if (!key) {
          return clone(this.data);
        }
        const keys = key.split('.');
        // not last
        key = (keys.pop() as string);
        let current = this.data;
        for (const k of keys) {
          if (current[k] == null) {
            current[k] = {};
          }
          if (typeof current[k] != 'object') {
            current[k] = {};
          }
          current = current[k];
        }
        return current[key] != undefined ? current[key] : defaultValue;
      }
       public set(key: string, value: any): void {
        const keys: string[] = key.split('.');
        // not last
        key = (keys.pop() as string);
        let current = this.data;
        for (const k of keys) {
          if (current[k] == null) {
            current[k] = {};
          }
          if (typeof current[k] != 'object') {
            console.warn('Configuration nested key not object', current, keys, k);
            current[k] = {};
          }
          current = current[k];
        }
        if (current[key] === value) {
          return;
        }
        current[key] = value;
      }
      public remove(key: string): void {
        const keys: string[]  = key.split('.');
        // not last
        key = (keys.pop() as string);
        let current = this.data;
        for (const k of keys) {
          if (current[k] == null) {
            current[k] = {};
          }
          if (typeof current[k] != 'object') {
            console.warn('Configuration nested key not object', current, keys, k);
            current[k] = {};
          }
          current = current[k];
        }
        delete current[key];
      }
      public writeExternal(): any {
        return clone(this.data);
      }
      public readExternal(data: any): void {
        this.data = data;
      }
}
export const configuration: Configuration = new Configuration();
export const config: Configuration & ((key?: string, value?: any) => any) = function(key?: string, value?: any): any {
    if (value != undefined) {
        return configuration.set(key as string, value);
    }
    return configuration.get(key);
} as any;
// config.instance = configuration
// add Configuration methods to config
for (const key of Object.getOwnPropertyNames(Configuration.prototype)) {
    if (key == 'constructor') {
        continue;
    }
    if (typeof (configuration as any)[key] == 'function') {
        (config as any)[key] = (configuration as any)[key].bind(configuration);
    }
}
// debug purpose
(globalThis as any).config = config;
