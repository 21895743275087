
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { make } from '@/app/make';

export default Vue.extend({
  name: 'mixin-loadGame',

  props: {
    gameSlug: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      game: null,
    };
  },
  async beforeRouteEnter(to, from, next) {
    const store = make('store');
    const gamesAccountsInitialized = store.getters['gameAccount/gamesAccountsInitialized'];

    if (!gamesAccountsInitialized) {
      await store.dispatch('gameAccount/games');
    }
    next();
  },
  created() {
    this.loadGame();
  },
  computed: {
    ...mapGetters('games', ['games']),
    ...mapGetters('gameAccount', {gamesAccounts: 'games'}),
    isGameConnected(): boolean {
      if (!this.games?.length || !this.gameSlug) { return false; }
      return !!this.gamesAccounts.find((el) => el.game.slug == this.gameSlug);
    },
    gameRequest(): any {
      if (!this.games?.length || !this.gameSlug) { return false; }
      const game = this.games.find((el) => el.slug == this.gameSlug);
      return game;
    },
  },
  watch: {
    gameSlug(value: string) {
      if (value) { this.loadGame(); }
    },
    games(value: any[] ) {
      if (value) { this.loadGame(); }
    },
  },
  methods: {

    /**
     * Check if game exists and user is connected to this game
     * Otherwise redirect to /home
     */
    loadGame() {
      if (!this.games?.length || !this.gameSlug) { return; }
      if (!this.gameRequest) {
        this.$router.push({ name: 'home' }); // Game requested doesn't exists
        return;
      }
      if (!this.gamesAccounts.length) {
        this.$router.push({ name: 'gamesSettings' }); // Not connected to this game
        return;
      }
      this.game = this.gameRequest;
    },
  },

});
