
























import { Vue, Component, Prop } from 'vue-property-decorator';
import Avatar from '../../ProfileElements/Avatar/Avatar.vue';
import ChipLevel from '../../ProfileElements/ChipLevel/ChipLevel.vue';

@Component({
  name: 'n-ChallengeNotification',
})
export default class ChallengeNotification extends Vue {
  /**
   * Pseudo du joueur proposant le défi
   */
  @Prop({
    type: String,
    required: true,
  })
  public pseudo!: string;

  /**
   * Avatar du joueur proposant le défi
   */
  @Prop({
    type: String,
    required: true,
  })
  public avatar!: string;

  /**
   * Nombre de win du joueur proposant le défi
   */
  @Prop({
    type: Number,
    required: true,
  })
  public win!: number;

  /**
   * Nombre de lose du joueur proposant le défi
   */
  @Prop({
    type: Number,
    required: true,
  })
  public lose!: number;

  /**
   * Niveau du joueur proposant le défi
   */
  @Prop({
    type: Number,
    required: true,
  })
  public level!: number;
}
