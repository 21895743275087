




























import Vue from 'vue';
import ModesList from '@/components/General/ModesList.vue';
import NbzRules from '@/components/Rules/NbzRules.vue';


export default Vue.extend({
  components: {
    ModesList,
    NbzRules,
  },
  name: 'rules',
  props: {
    modes: {
      required: false,
      type: Array,
    },
  },
  data() {
    return {
      selected: 'howItWorks',
    };
  },
});
