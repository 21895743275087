
















































import Loader from '@/components/General/Loader.vue';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: { Loader },
  name: 'home',
  data() {
    return {
      selected: false,
      counter: 0,
      direction: 'right',
      init: false,
      gamesList: [
        {
          // pubg from dataBase
        },
        {
          name: 'Apex Legends',
          label: 'Apex Legends',
          slug: 'apex-legends',
          icon_url: require('@/assets/img/games/apexlegends-logo.svg'),
          cover_url: require('@/assets/img/games/apexlegends-cover.jpg'),
          available: false,
        },
        {
          name: 'Call of Duty Warzone',
          label: 'Call of Duty Warzone',
          slug: 'codwarzone',
          icon_url: require('@/assets/img/games/codwarzone-logo.png'),
          cover_url: require('@/assets/img/games/codwarzone-cover.jpg'),
          available: false,
        },
        {
          name: 'Valorant',
          label: 'Valorant',
          slug: 'valorant',
          icon_url: require('@/assets/img/games/valorant-logo.png'),
          cover_url: require('@/assets/img/games/valorant-cover.jpg'),
          available: false,
        },
        {
          name: 'League Of Legends',
          label: 'League Of Legends',
          slug: 'leagueoflegends',
          icon_url: require('@/assets/img/games/leagueoflegends-logo.png'),
          cover_url: require('@/assets/img/games/leagueoflegends-cover.jpg'),
          available: false,
        },
      ],
    };
  },
  created() {
    if (this.gamesInitialized) {
      // refresh games stats
      this.$store.dispatch('games/list');

      if (this.nbzGames?.length) {
        this.init = true;
        this.formatGameObject();
      }
    }
  },
  watch: {
    nbzGames() {
      if (this.init) {
        return;
      }
      this.formatGameObject();
    },
  },
  computed: {
    ...mapGetters('games', { nbzGames: 'games' }),
    ...mapGetters('games', ['gamesInitialized']),
    games(): any {
      if (!this.nbzGames?.length) {
        return;
      }

      const games = this.gamesList;
      if (this.counter) {
        let frontGame;
        if (this.direction === 'left') {
          frontGame = games[games.length - 1];
          games.pop();
          games.unshift(frontGame);
        } else if (this.direction === 'right') {
          frontGame = games[0];
          games.splice(0, 1);
          games.push(frontGame);
        }
        return games;
      } else {
        const frontGame = games[0];
        // let frontGame = games[Math.floor(Math.random() * games.length)]
        const index = games.indexOf(frontGame);
        if (index > -1) {
          games.splice(index, 1);
          games.unshift(frontGame);
        }
        return games;
      }
    },
  },
  methods: {
    slideLeft() {
      this.counter++;
      this.direction = 'left';
    },
    slideRight() {
      this.counter++;
      this.direction = 'right';
    },
    isPc(game): boolean {
      if (!game) {
        return false;
      }
      return !!game.platforms.find(platform => platform.type == 'pc');
    },
    isPs(game): boolean {
      if (!game || !game.platforms) {
        return false;
      }
      return !!game.platforms.find(platform => platform.type == 'ps');
    },
    isXbox(game): boolean {
      if (!game) {
        return false;
      }
      return !!game.platforms.find(platform => platform.type == 'xbox');
    },
    formatGameObject() {
      if (!this.nbzGames?.length) {
        return;
      }
      this.nbzGames.forEach(g => {
        const game: any = {
          ...g,
          available: true,
        };
        this.gamesList.splice(0, 1, game);
      });
    },
    redirectGame(game) {
      if (!game || !game.slug || !game.available) {
        return;
      }
      this.$router.push({ name: 'challenges', params: { gameSlug: game.slug } });
    },
  },
});
