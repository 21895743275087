import { render, staticRenderFns } from "./Challenges.vue?vue&type=template&id=af1e948a&scoped=true&"
import script from "./Challenges.vue?vue&type=script&lang=ts&"
export * from "./Challenges.vue?vue&type=script&lang=ts&"
import style0 from "./Challenges.vue?vue&type=style&index=0&id=af1e948a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af1e948a",
  null
  
)

export default component.exports