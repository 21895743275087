






import Vue from 'vue';

export default Vue.extend({
  name: 'facebook-icon',
  props: {
    color: {
      type: String,
      required: false,
      default: '#FFFFFF',
    },
  },
});
