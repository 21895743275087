import {api} from '@/io/api';

interface IState {
  challenges: any[]; // Challenges created by other players and available to play
  error: any|null;
}
export const state: IState = {
  challenges: [],
  error: null,
};
export const getters = {
  challenges(state: IState) {
    return state.challenges;
  },
};
export const actions = {
  async getChallenges({  commit  }: any, { params }: { params: any[] }) {
    commit('clearError');
    try {
      const res = await api().route('challenges').params(params).get();

      commit('setChallenges', res.challenges);
      return res;
      return;
    } catch (error) {
      commit('setError', error.message);
      return Promise.reject(error);
    }
  },
};
export const mutations = {
    setChallenges(state: IState, challenges: any) {
        state.challenges = challenges;
    },
    removeChallenge(state: IState, challenge: any) {
      let challengeId;
      if (challenge && typeof(challenge) == 'object') {
        challengeId = challenge.id;
      } else {
        challengeId = challenge;
      }
      const index = state.challenges.findIndex((c: any) => c.id == challengeId);
      if (index > -1) {
          state.challenges.splice(index, 1);
      }
    },
    setError(state, message) {
      state.error = message;
    },
    clearError(state) {
        state.error = null;
    },
};
export const namespaced = true;
