







import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'n-InputLabel',
})
export default class InputLabel extends Vue {
  /**
   * Label de l'input placé haut dessus
   */
  @Prop({
    type: String,
    required: true,
    default: 'label',
  })
  public label!: string;

  /**
   * Nom de l'input permettant de lié le label à l'input
   */
  @Prop({
    type: String,
    required: true,
    default: 'name',
  })
  public name!: string;

  /**
   * Permet d'afficher Requis'
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public required!: boolean;
}
