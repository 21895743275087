





































































import Vue from 'vue';
import { UpdatePasswordForm } from '@/services/userSpace/interfaces/UpdatePasswordForm.interface';
import { api } from '@/io/api';

export default Vue.extend({
  name: 'updatePassword',
  data() {
    return {
      form: {
        old_password: null,
        password: null,
        password_confirmation: null,
        locale: this.$i18n.locale,
      } as UpdatePasswordForm,
      error: null,
      loading: false,
      success: null,
    };
  },

  methods: {
    async sendForm() {
      this.loading = true;
      try {
        await api()
          .route('user/updatePassword')
          .params(this.form)
          .patch();
        this.error = null;
        this.success = this.$t('resetPassword.success.reset');
      } catch (error) {
        this.error = this.$t(error.message);
      }
      this.loading = false;
    },
  },
});
