










import Vue from 'vue';
import NotificationsLayout from '@/components/Layouts/Notifications.vue';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { fr } from 'date-fns/esm/locale';


export default Vue.extend({
  name: 'info',
  components: {
    NotificationsLayout,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    data() {
      const data = Object.keys(this.notification.payload).filter((e) => e != 'title' && e != 'message');
      return data.reduce((reduce, e) => {
        reduce[e] = (this as any).notification.payload[e];
        return reduce;
      }, {});
    },
    delay() {
      return formatDistanceToNow(new Date(this.notification.created_at), { locale: fr });
    },
  },

});
