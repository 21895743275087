







import Vue from 'vue';
import UserChallenge from '@/components/Challenges/UserChallenge.vue';

export default Vue.extend({
  name: 'user-challenges-list',
  components: { UserChallenge },
  props: {
    challenges: {
      required: true,
      type: Array,
    },
    game: {
      required: true,
      type: Object,
    },
  },
});
