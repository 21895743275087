import Home from '@/views/Home.vue';
import Games from '@/views/Games.vue';
import Ranking from '@/views/Ranking.vue';
import Store from '@/views/Store.vue';
import Challenges from '@/views/Challenges.vue';
import Matches from '@/views/Matches.vue';
import Match from '@/views/Match.vue';
import MatchMaking from '@/views/MatchMaking/MatchMaking.vue';
import Pools from '@/views/MatchMaking/Pools.vue';
import Settings from '@/views/Settings.vue';
import AccountConfiguration from '@/components/Account/Settings/AccountConfiguration.vue';
import GamesSettings from '@/components/Account/Settings/Games.vue';
import AccountSettings from '@/components/Account/Settings/Account.vue';
import UpdatePassword from '@/views/ResetPassword/UpdatePassword.vue';
import Deposit from '@/views/Deposit.vue';

import { auth } from '@/auth/index';
import { config } from '@/env/Configuration';

export default [
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      title: 'router.home.title',
      requiresAuth: true,
    },
  },
  {
    path: '/games',
    name: 'games',
    component: Games,
    meta: {
      title: 'router.games.title',
      requiresAuth: true,
    },
  },
  {
    path: '/ranking',
    name: 'ranking',
    component: Ranking,
    meta: {
      title: 'router.ranking.title',
      requiresAuth: true,
    },
  },
  {
    path: '/store',
    name: 'store',
    component: Store,
    meta: {
      title: 'router.store.title',
      requiresAuth: true,
    },
  },
  {
    path: '/:gameSlug/challenges',
    name: 'challenges',
    component: Challenges,
    meta: {
      title: 'router.challenges.title',
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: '/:gameSlug/match',
    name: 'matches',
    component: Matches,
    meta: {
      title: 'router.matches.title',
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: '/:gameSlug/match/:challengeId',
    name: 'match',
    component: Match,
    meta: {
      title: 'router.match.title',
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: '/:gameSlug/matchmaking',
    name: 'matchMaking',
    component: MatchMaking,
    meta: {
      title: 'router.matchmaking.title',
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: '/:gameSlug/matchmaking/pools',
    name: 'pools',
    component: Pools,
    meta: {
      title: 'router.pools.title',
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: '/settings',
    component: Settings,
    meta: {
      title: 'router.settings.title',
      requiresAuth: true,
    },
    redirect: { name: 'accountSettings' },
    children: [
      {
        path: 'configuration',
        name: 'accountConfiguration',
        component: AccountConfiguration,
        meta: { requiresAuth: true },
        redirect: { name: 'accountSettings' },
        children: [
          {
            path: 'account',
            name: 'accountSettings',
            component: AccountSettings,
            meta: { requiresAuth: true },
          },
          {
            path: 'updatePassword',
            name: 'updatePassword',
            component: UpdatePassword,
            meta: {
              title: 'router.updatepassword.title',
              requiresAuth: true,
            },
          },
          {
            path: 'games',
            name: 'gamesSettings',
            component: GamesSettings,
            meta: {
              title: 'router.gamesettings.title',
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/deposit',
    name: 'deposit',
    component: Deposit,
    meta: {
      title: 'router.deposit.title',
      requiresAuth: true,
    },
    beforeEnter: (to: any, from: any, next: any) => {
      if (config('app.nbz.activePayment') || auth.isAdmin()) {
        next();
      } else {
        next({ name: 'home' });
      }
    },
  },
];
