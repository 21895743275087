
import Button from '@nbzify/components/ButtonElements/Button';
import ToggleButton from '@nbzify/components/ButtonElements/ToggleButton';
import Links from '@nbzify/components/ButtonElements/Links';
import ChallengeLine from '@nbzify/components/ChallengeElements/ChallengeLine';
import Card from '@nbzify/components/CardElements/Card';
import ChallengeTable from '@nbzify/components/ChallengeElements/ChallengeTable';
import GameInfo from '@nbzify/components/GameElements/GameInfo';
import GameStats from '@nbzify/components/GameElements/GameStats';
import GameThumb from '@nbzify/components/GameElements/GameThumb';
import InputLabel from '@nbzify/components/LabelElements/InputLabel';
import ErrorLabel from '@nbzify/components/LabelElements/ErrorLabel';
import inputSelect from '@nbzify/components/InputElements/InputSelect';
import InputText from '@nbzify/components/InputElements/InputText';
import NavBar from '@nbzify/components/NavigationElements/NavBar';
import ChallengeNotification from '@nbzify/components/NotificationElements/ChallengeNotification';
import Notification from '@nbzify/components/NotificationElements/Notification';
import ApiConnector from '@nbzify/components/ProfileElements/ApiConnector';
import Avatar from '@nbzify/components/ProfileElements/Avatar';
import ChipLevel from '@nbzify/components/ProfileElements/ChipLevel';
import Ratio from '@nbzify/components/ProfileElements/Ratio';
import NavProfile from '@nbzify/components/NavigationElements/NavProfile';
import dropdownMenu from '@nbzify/components/NavigationElements/DropdownMenu';
import InputStatus from '@nbzify/components/InputElements/InputStatus';
import Checkbox from '@nbzify/components/InputElements/Checkbox';
import InputPassword from '@nbzify/components/InputElements/InputPassword';
import navProfileMenu from '@nbzify/components/ProfileElements/NavProfileMenu';
import NotificationMenu from '@nbzify/components/NavigationElements/NotificationMenu';
import GamesPage from '@nbzify/components/ProfileElements/GamesPage';
import profilePage from '@nbzify/components/ProfileElements/ProfilePage';


export const Components = {
  Button,
  ToggleButton,
  Links,
  ChallengeLine,
  Card,
  ChallengeTable,
  GameInfo,
  GameStats,
  GameThumb,
  InputLabel,
  ErrorLabel,
  InputPassword,
  ...inputSelect,
  InputText,
  NavBar,
  ChallengeNotification,
  Notification,
  ApiConnector,
  Avatar,
  ChipLevel,
  Ratio,
  NotificationMenu,
  NavProfile,
  ...dropdownMenu,
  InputStatus,
  Checkbox,
  ...navProfileMenu,
  ...profilePage,
  GamesPage,
};
